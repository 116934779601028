<div class="card p-4" style="border-radius: 10px;">
    <div class="card-body">
      <h2 class="permissoes-detalhe-title">Permissões do Cliente</h2>

      <div *ngIf="permissoes && permissoes.length === 0" class="no-benefits">
        <!-- Mensagem de orientação -->
        <p class="text-muted text-center">
          Adicione um novo item clicando no botão "Adicionar"
        </p>
      </div>

      <div
      *ngFor="let permission of permissoes; let i = index"
      [ngClass]="{
        'custom-card': true,
        'single-card': permissoes.length === 1,
        'mt-0': i !== 0, 
        'first-card': i === 0,
        'last-card': i === permissoes.length - 1
      }">
      <div class="card-body">
        <!-- Título e Pill -->
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="title-permissao-item">
            {{ permission.modulo }}
          </h5>
          <span
            class="badge-pill"
            [ngClass]="permission.ativo ? 'pill-success' : 'pill-danger'"
          >
            {{ permission.ativo ? "ATIVO" : "INATIVO" }}
          </span>
        </div>
        <div class="row">
          <!-- Informações do Benefício -->
          <div class="col-8 card-text mt-3 descricao-item">
            <p>Permissões:
            </p>
            <p>Válido Até:
              {{ getExpirationDate(permission.data_validade) }}
            </p>           
          </div>

          <!-- Botões de Ação -->
          <div
            class="col-4 actions d-flex justify-content-end align-items-center"
          >
            <button
              class="btn btn-outline-danger"
              (click)="deletePermissao(permission.id)"
            >
              <i class="fa fa-trash"></i>
            </button>
            <button
              class="btn btn-outline-success"
              (click)="
              editPermission(permission, novapermissao)
              "
            >
              <i class="fa fa-edit"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    
      <div class="d-flex justify-content-between mt-4">
        <button class="btn btn-outline-primary" (click)="goBack()">Voltar</button>
        <button class="btn btn-primary" [disabled]="temTodasAsPermissoes()" (click)="abrirModal(novapermissao) ">Adicionar Permissão</button>
      </div>
    </div>
  </div>

  <ng-template #novapermissao >
    <div class="modal-header">
      <h5 class="title-modal">Nova Permissão</h5>
      <button type="button" class="btn btn-close" aria-label="Close" (click)="modal.close('Close click')"></button>
    </div>
    <div class="modal-body">
      <form #exForm="ngForm" class="editForm" novalidate>
         <!-- Validade Indeterminada + Data -->
          <div class="row">

      <div class="col-12 col-md-4 mb-3 d-flex align-items-center justify-content-center">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="validadeIndeterminada"
            (change)="toggleValidadeIndeterminada()"
            name="validadeIndeterminada"
          />
          Validade Indeterminada
        </label>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <label for="validade">Validade:</label>
          <input
            type="date"
            id="data_validade"
            class="form-control"
            [(ngModel)]="permission.data_validade"
            [disabled]="validadeIndeterminada"
            name="data_validade"
            #inputValidade="ngModel"
          />
        </div>

      <!-- Módulo -->
      <div class="col-12 col-md-5 mb-3">
        <label for="modulo">Módulo:</label>
        <ng-select
          [appendTo]="'body'"
          [items]="modulos"
          bindLabel="nome"
          bindValue="id"
          placeholder="Módulo"
          id="id_modulo"
          [(ngModel)]="permission.id_modulo"
          name="id_modulo"
          #inputModulo="ngModel"
        ></ng-select>
      </div>
    </div>

    <div class="row" *ngIf="permission.id">
      <div class="col-12">
        <div class="col-12 col-md-5 mb-3">
          <label for="modulo">Ativo:</label>
          <ng-select
            [appendTo]="'body'"
            [items]="status"
            bindLabel="nome"
            bindValue="id"
            placeholder="Ativo"
            id="ativo"
            [(ngModel)]="permission.ativo"
            name="ativo"
            #inputModulo="ngModel"
          ></ng-select>
        </div>
      </div>
      </div>
        <!-- Permissões -->
        <div class="mb-3 d-none">
          <label>
            <input
              type="checkbox"
              [(ngModel)]="selecionarTodas"
              (change)="selecionarTodasPermissoes($event)"
              name="selecionarTodas"
            />
            Todas
          </label>
          <div class="form-check" *ngFor="let permissao of permissoes; let i = index">
            <input
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="permissao.selecionada"
              [name]="'permissao_' + i"
            />
            <label class="form-check-label">{{ permissao.nome }}</label>
          </div>
        </div>
      </form>
      
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button type="button" class="btn btn-outline-secondary" style="padding: 6px 18px;" (click)="closeModal()">Voltar</button>
      <button type="button" style="padding: 6px 18px;" class="btn btn-primary" [disabled]="!permission.id_modulo || !idCidade" (click)="savePermission()">{{ permission.id ? 'Editar' : 'Criar' }}</button>
    </div>
  </ng-template>
  <ngx-spinner></ngx-spinner>
  