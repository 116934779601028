<div class="card p-4" style="border-radius: 10px;">
    <div class="card-body">
      <h1 class="title-permissao">Licenças e Permissões</h1>
      
      <p>
        Visualize e gerencie o acesso dos clientes aos módulos do sistema. Utilize a busca e os filtros para encontrar e atualizar informações de forma eficiente.
      </p>
  
      <form #servForm="ngForm" novalidate>
        <div class="form-group">
          <label for="nome" class="w-100 font-weight-bold">CLIENTE</label>
          <div class="input-group">
            <input type="text" id="nome" class="form-control" [(ngModel)]="nomeCliente" name="nome" placeholder="Procure por nome" required>
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </form>
  
      <div class="form-group">
        <label class="font-weight-bold">ACESSO AO MÓDULO</label>
        <div class="d-flex align-items-center">
          <input type="checkbox" [checked]="allSelected" (change)="toggleAll($event)" class="mr-1"> Todos
          <input type="checkbox" [(ngModel)]="options.simulador" (change)="updateAllSelected()" class="ml-3 mr-1"> Simulador
          <input type="checkbox" [(ngModel)]="options.sapiem" (change)="updateAllSelected()" class="ml-3 mr-1"> Sapiem
        </div>
      </div>
  
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary mr-2" (click)="clearFilters()">Limpar filtros</button>
        <button type="button" class="btn btn-primary" (click)="applyFilters()">
          <i class="fa fa-search mr-1"></i> Pesquisar
        </button>
      </div>
    </div>
  </div>
  
  <div class="card" style="border-radius: 10px;">
  
    <table class="table text-center m-0">
      <thead>
        <tr>
          <th (click)="sortTable('municipio')">
            <div class="d-flex align-items-center justify-content-center">
              Município
              <div class="sort-icons ml-1">
                <i *ngIf="sortColumn !== 'nome'" class="fa fa-caret-up"></i>
                <i *ngIf="sortColumn !== 'nome'" class="fa fa-caret-down"></i>
                <i
                  *ngIf="sortColumn === 'nome'"
                  class="fa"
                  [ngClass]="{
                    'fa-caret-up': sortDirection === 'asc',
                    'fa-caret-down': sortDirection === 'desc'
                  }"
                ></i>
              </div>
            </div>
          </th>
          <th (click)="sortTable('simulador')">
            <div class="d-flex align-items-center justify-content-center">
              Simulador
              <div class="sort-icons ml-1">
                <i *ngIf="sortColumn !== 'simulador'" class="fa fa-caret-up"></i>
                <i *ngIf="sortColumn !== 'simulador'" class="fa fa-caret-down"></i>
                <i
                  *ngIf="sortColumn === 'simulador'"
                  class="fa"
                  [ngClass]="{
                    'fa-caret-up': sortDirection === 'asc',
                    'fa-caret-down': sortDirection === 'desc'
                  }"
                ></i>
              </div>
            </div>
          </th>
          <th (click)="sortTable('sapiem')">
            <div class="d-flex align-items-center justify-content-center">
              Sapiem
              <div class="sort-icons ml-1">
                <i *ngIf="sortColumn !== 'sapiem'" class="fa fa-caret-up"></i>
                <i *ngIf="sortColumn !== 'sapiem'" class="fa fa-caret-down"></i>
                <i
                  *ngIf="sortColumn === 'sapiem'"
                  class="fa"
                  [ngClass]="{
                    'fa-caret-up': sortDirection === 'asc',
                    'fa-caret-down': sortDirection === 'desc'
                  }"
                ></i>
              </div>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cidade of cidadesFiltered | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize">
          <td>{{ cidade.municipio }}</td>
          <td>{{ cidade.sapiem ? 'Ativo' : 'Inativo' }}</td>
          <td>{{ cidade.simulador ? 'Ativo' : 'Inativo' }}</td>
          <td style="padding: 10px;">
            <div class="btn-group-custom">
              <button type="button" class="btn button--visualizar" (click)="gotoDetails(cidade)">
                <i class="fa fa-eye"></i> Detalhes
              </button>
           
            </div>           
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-row mt-2" style="margin: 24px">
      <div class="d-flex justify-content-end w-100">
        <ngb-pagination
          style="margin-top: 10px"
          *ngIf="cidadesFiltered.length > 0"
          [(page)]="page"
          [pageSize]="pageSize"
          [maxSize]="2"
          [rotate]="true"
          [boundaryLinks]="true"
          size="sm"
          [collectionSize]="cidadesFiltered.length"
        ></ngb-pagination>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  