import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as moment from 'moment'
@Component({
  selector: 'app-tempos',
  templateUrl: './tempos.component.html',
  styleUrls: ['./tempos.component.scss']
})
export class TemposComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1
  pageSize = 100
  pageC = 1
  pageSizeC = 100
  idServidor
  tempos = [];
  certidoes = [];
  certidaoEmEdicao = {
    id: null,
    numero_certidao: null,
    data_certidao: null,
  }
  tempo = {
    id: null,
    id_servidor: null,
    cnpj_cpf_empregador: null,
    nome_empregrador: null,
    numero_certidao: null,
    data_certidao: null,
    rpps_rgps: null,
    averbado: null,
    data_entrada: null,
    tempo_total: null,
    data_fim: null,
    tempo_bruto: null
  }
  sortColumn: string = '';
  sortDirection: string = 'asc'; 
  RPPSRGPS = [

    {
      id: 0,
      descricao: 'RPPS',
    },{
      id: 1,
      descricao: 'RGPS',
    }]
    averbado = [
      {
        id: 1,
        name: 'Sim',
      },{
        id: 2,
        name: 'Não',
      }
    ]
  
  tempoTotalCadastrado= 0
  tempoTotalAverbado= 0
  tempoTotalRPPS= 0
  tempoTotalRPPSAverbado= 0
  tempoTotalINSS= 0
  tempoTotalINSSAverbado= 0
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }
  async ngOnInit() {
    const self = this;
    self.resetaTempo()
    self.tempoTotalCadastrado = 0
    self.tempoTotalAverbado = 0
    self.tempoTotalINSS = 0
    self.tempoTotalINSSAverbado = 0
    self.tempoTotalRPPS = 0
    self.tempoTotalRPPSAverbado = 0
    self.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        self.idServidor = parametros['id'];
      }
    });
    self.tempos = [];

    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const certidoes = await self.apiCall.get('servidor/temposervico/certidao/recupera?id_servidor='+self.idServidor).pipe(take(1)).toPromise()
    self.certidoes = JSON.parse(JSON.stringify(certidoes));
    const tempos = await self.apiCall.get('servidor/temposervico/todos?id_servidor='+self.idServidor).pipe(take(1)).toPromise()
    for(let item of JSON.parse(JSON.stringify(tempos))){
      self.tempoTotalCadastrado = self.tempoTotalCadastrado + +item.tempo_bruto
      self.tempos.push(item)
      if(item.averbado == 1){
        self.tempoTotalAverbado = self.tempoTotalAverbado + +item.tempo_bruto
      }
      if(item.rpps_rgps == 0){
        if(item.averbado == 1){
          self.tempoTotalRPPSAverbado = self.tempoTotalRPPSAverbado + +item.tempo_bruto
        }
        self.tempoTotalRPPS = self.tempoTotalRPPS + +item.tempo_bruto
      }else if(item.rpps_rgps == 1){
        if(item.averbado == 1){
          self.tempoTotalINSSAverbado = self.tempoTotalINSSAverbado + +item.tempo_bruto
        }
          self.tempoTotalINSS = self.tempoTotalINSS + +item.tempo_bruto
      }
    }
    await self.spinner.hide()
  }

  getBeautifulDate(date){
    return moment(date).format('DD/MM/YYYY')
  }
  isTempoCerto(){
    if(this.tempo.data_entrada && this.tempo.data_fim){
      if(moment(this.tempo.data_entrada, 'DDMMYYYY').isBefore(moment(this.tempo.data_fim, 'DDMMYYYY'))){
        return true
      }else{
        return false
      }
    }else{
      return true
    }
  }

  resetaCertidao(){
    this.certidaoEmEdicao = {
      id: null,
      numero_certidao: null,
      data_certidao: null,
    }
  }
  async atualizarCertidao(modal){
    const self = this;
    if(!self.certidaoEmEdicao.id || !self.certidaoEmEdicao.numero_certidao || !self.certidaoEmEdicao.data_certidao){

    }
    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.certidaoEmEdicao.data_certidao = this.preparaData(this.certidaoEmEdicao.data_certidao)

    await self.apiCall.get(`servidor/temposervico/certidao/editar?id=${self.certidaoEmEdicao.id}&data_certidao=${self.certidaoEmEdicao.data_certidao}&numero_certidao=${self.certidaoEmEdicao.numero_certidao}`).pipe(take(1)).toPromise()
    await self.spinner.hide()
    await this.app.alert('Tudo certo!', 'Certidão atualizada com sucesso!', 'success')
    self.resetaCertidao()
    modal.close('Close click')
    await self.ngOnInit()
  }
  
  async deletarTempo(id){
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if (await self.app.confirm(`Atenção`, `Você deseja mesmo deletar este tempo de serviço?`, `Deletar`)) {
      const deleteDependente = await self.apiCall.get(`servidor/temposervico/deletar?id_tempo=${id}`).pipe(take(1)).toPromise()
      console.log(deleteDependente)
      await self.app.alert(`Sucesso`, `Tempo de serviço deletado!`, `success`)
      await self.spinner.hide()
      await self.ngOnInit()
    }
  }
  resetaTempo(){
    this.tempo = {
      id: null,
      id_servidor: null,
      cnpj_cpf_empregador: null,
      numero_certidao: null,
      data_certidao: null,
      nome_empregrador: null,
      data_entrada: null,
      rpps_rgps: null,
      averbado: null,
      tempo_total: null,
      data_fim: null,
      tempo_bruto: null
    }
  }
  async atualizarOuCadastrar(modal){
    const self = this
    if(!self.tempo || !self.idServidor){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.tempo.data_entrada = this.preparaData(this.tempo.data_entrada)
    this.tempo.data_fim = this.preparaData(this.tempo.data_fim)
    this.tempo.data_certidao = this.preparaData(this.tempo.data_certidao)
    if(self.tempo.id){
      await self.apiCall.get(`servidor/temposervico/editar?id_servidor=${self.idServidor}&id_tempo=${self.tempo.id}&numero_certidao=${self.tempo.numero_certidao}&data_certidao=${self.tempo.data_certidao}&cnpj_cpf_empregador=${self.tempo.cnpj_cpf_empregador}&nome_empregrador=${self.tempo.nome_empregrador}&data_entrada=${self.tempo.data_entrada}&data_fim=${self.tempo.data_fim}&tempo_bruto=${self.tempo.tempo_bruto}&rpps_rgps=${self.tempo.rpps_rgps}&averbado=${self.tempo.averbado}`).pipe(take(1)).toPromise()

    }else{
      await self.apiCall.get(`servidor/temposervico/novo?id_servidor=${self.idServidor}&numero_certidao=${self.tempo.numero_certidao}&data_certidao=${self.tempo.data_certidao}&cnpj_cpf_empregador=${self.tempo.cnpj_cpf_empregador}&nome_empregrador=${self.tempo.nome_empregrador}&data_entrada=${self.tempo.data_entrada}&data_fim=${self.tempo.data_fim}&tempo_bruto=${self.tempo.tempo_bruto}&rpps_rgps=${self.tempo.rpps_rgps}&averbado=${self.tempo.averbado}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.tempo.id ? 'Tempo de Serviço atualizado com sucesso!' : 'Tempo de Serviço cadastrado com sucesso!', 'success')
    modal.close('Close click')
    await self.ngOnInit()
  }
  openCertidao(content, certidao){
    this.resetaCertidao()
    if(certidao){
      this.certidaoEmEdicao = {
        id: certidao.id,
        numero_certidao: certidao.numero,
        data_certidao: moment(certidao.data).format('DDMMYYYY'),
      }
    }
    const modal = this.modalService.open(content, { centered: true, size: "xl" })
  }
  openModalXl(content, tempo, tipo){
    this.resetaTempo()
    if(tempo){
      this.tempo = {...tempo}
      this.tempo.data_entrada = moment(this.tempo.data_entrada).format('DDMMYYYY')
      this.tempo.data_fim = moment(this.tempo.data_fim).format('DDMMYYYY')
    }
    if(tipo!=null){
      this.tempo.rpps_rgps = tipo
    }
    console.log(this.tempo)
    const modal = this.modalService.open(content, { centered: true, size: "lg" })
  }

  cancelTempo(content){
    this.resetaTempo()   
    content.close('Close click')
  }

  calculaTempoBruto(){
    if(this.tempo.data_entrada && this.tempo.data_fim){
      const entrada = moment(this.tempo.data_entrada, 'DDMMYYYY')
      const fim = moment(this.tempo.data_fim, 'DDMMYYYY')
      const diferenca = fim.diff(entrada, 'days')
      this.tempo.tempo_bruto = diferenca.toString()
    }
  }
  preparaData(data){
    if(data){
      return moment(data, 'DDMMYYYY').format('YYYY-MM-DD')
    }else{
      data = ''
    }
    return data
  }

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
  
    this.tempos.sort((a, b) => {
      let valueA, valueB;
  
      if (column === 'matricula') {
        valueA = a.status == 2 || a.status == 3 ? a.matricula_beneficiario : a.matricula;
        valueB = b.status == 2 || b.status == 3 ? b.matricula_beneficiario : b.matricula;
      } else if (column === 'status') {
        valueA = a;
        valueB = b;
      } else {
        valueA = a[column]?.toString().toLowerCase() || '';
        valueB = b[column]?.toString().toLowerCase() || '';
      }
  
      if (this.sortDirection === 'asc') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });
  }
}
