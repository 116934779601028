<div class="container">
  <!-- Botão Voltar -->
  <div class="d-flex align-items-center mb-3">
    <a class="btn-back" routerLink="/page/servidores">
      <i class="fa fa-arrow-left"></i> Voltar
    </a>
    <span class="ml-2">Servidores do Município > Servidor</span>

  </div>

  <div class="row gx-3">
    <!-- Card Principal do Servidor -->
    <div class="col-lg-9 col-md-8">
      <div class="server-data">
        <div class="card p-3 shadow-sm">
          <div class="row align-items-center">
            <!-- Coluna da imagem -->
            <div class="col-auto position-relative">
              <img
                [src]="
                  servidor && servidor.imagem
                    ? servidor.imagem
                    : 'assets/img/portrait/small/no-avatar.svg'
                "
                alt="Foto do servidor"
                class="rounded-circle"
                style="width: 120px; height: 120px; object-fit: cover"
              />
              <!-- Ícone de câmera como imagem -->
              <img src="../../assets/img/edit_profile_image.png" (click)="openModalSm(modalFoto)" alt="Ícone de câmera" class="camera-icon" style="width: 40px;height: 40px;" />
            </div>
            <!-- Coluna das informações -->
            <div class="col">
              <h3 class="mb-1">
                {{ servidor && servidor.nome ? servidor.nome : "-----" }}
              </h3>
              <div class="d-flex align-items-center">
                <p class="mb-0">
                  <b style="font-weight: 600">CPF:</b>
                  {{
                    servidor && servidor.cpf
                      ? (servidor?.cpf | mask : "000.000.000-00")
                      : "-----"
                  }}
                </p>
                <p class="mb-0 ms-3 mr-2 ml-2">|</p>
                <span class="badge bg-success text-white ms-3 pr-1">{{servidor && servidor.status ? getStatus(servidor.status) : '---'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      <!-- Informações Básicas do Benefício -->
      <div class="benefit-info">
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <h4>Dados Principais</h4>
            <button class="btn-no-border" (click)="togglePrincipalData()">
              {{ isPrincipalDataVisible ? "Ocultar" : "Mostrar" }}
            </button>
          </div>

          <div class="card-body" *ngIf="isPrincipalDataVisible">
            <ngb-tabset (tabChange)="onTabChange($event)" type="tabs" class="custom-tabs">
              <ngb-tab title="Informações Básicas">
                <ng-template ngbTabContent>
                  <form *ngIf="servidor" #servForm="ngForm" novalidate>
                    <h5 class="text-left mt-2">Informações Básicas</h5>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome"
                              >Nome <b style="color: red">*</b></label
                            >
                            <input
                              type="text"
                              id="nome"
                              class="form-control"
                              [(ngModel)]="servidor.nome"
                              name="nome"
                              placeholder="Nome Completo"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="sexo"
                              >Sexo <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="sexos"
                              name="sexo"
                              bindLabel="name"
                              placeholder="Sexo"
                              bindValue="id"
                              id="sexo"
                              [(ngModel)]="servidor.sexo"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_nascimento"
                              >Nascimento <b style="color: red">*</b></label
                            >
                            <input
                              type="text"
                              id="data_nascimento"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="servidor.data_nascimento"
                              name="data_nascimento"
                              placeholder="Data Nascimento"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="estado_civil"
                              >Estado Civil <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="estadosCivis"
                              name="estado_civil"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Estado Civil"
                              id="estado_civil"
                              (change)="filter()"
                              [(ngModel)]="servidor.estado_civil"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="mae"
                              >Nome Mãe <b style="color: red">*</b></label
                            >
                            <input
                              type="text"
                              id="mae"
                              class="form-control"
                              [(ngModel)]="servidor.nome_mae"
                              name="mae"
                              placeholder="Nome Mãe"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="pai"
                              >Nome Pai <b style="color: red">*</b></label
                            >
                            <input
                              type="text"
                              id="pai"
                              class="form-control"
                              [(ngModel)]="servidor.nome_pai"
                              name="pai"
                              placeholder="Nome Pai"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h5 class="text-left mt-2">Localização e Nacionalidade</h5>

                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nacionalidade"
                              >Nacionalidade <b style="color: red">*</b></label
                            >
                            <input
                              type="text"
                              id="nacionalidade"
                              class="form-control"
                              [(ngModel)]="servidor.nacionalidade"
                              name="nacionalidade"
                              placeholder="Nacionalidade"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="naturalizado"
                              >Naturalizado <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="naturalizados"
                              name="naturalizado"
                              bindLabel="name"
                              placeholder="Naturalizado"
                              bindValue="id"
                              id="naturalizado"
                              [(ngModel)]="servidor.naturalizado"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome"
                              >Estado <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="estados"
                              name="estado"
                              (change)="toggleEstado()"
                              bindLabel="estado"
                              bindValue="estado"
                              placeholder="Estado"
                              id="estado"
                              [(ngModel)]="servidor.estado_naturalidade"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome"
                              >Cidade <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="cidades"
                              [disabled]="!cidades || cidades.length == 0"
                              name="cidade"
                              bindLabel="nome"
                              bindValue="id"
                              placeholder="Cidade"
                              id="cidade"
                              [(ngModel)]="servidor.cidade_naturalidade"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h5 class="text-left mt-2">Dados Complementares</h5>

                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="escolaridade"
                              >Escolaridade <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="escolaridades"
                              name="escolaridade"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Escolaridade"
                              id="escolaridade"
                              [(ngModel)]="servidor.escolaridade"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="deficiente"
                              >Pessoa com deficiência - PCD
                              <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="naturalizados"
                              name="deficiente"
                              bindLabel="name"
                              placeholder="Deficiente"
                              bindValue="id"
                              id="deficiente"
                              [(ngModel)]="servidor.deficiente"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="orgao"
                              >Orgão <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="orgaos"
                              name="orgao"
                              bindLabel="descricao"
                              placeholder="Orgão"
                              bindValue="id"
                              id="orgao"
                              [(ngModel)]="servidor.orgao"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h5 class="text-left mt-2">Informações de Status</h5>

                    <div class="form-row">
                      <div
                        class="col-12"
                        [ngClass]="{
                          'col-md-6': [1, 4].includes(servidor.status),
                          'col-md-3': [2, 3, 5].includes(servidor.status)
                        }"
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="escolaridade"
                              >Status <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="statusServidor"
                              name="status"
                              bindLabel="name"
                              bindValue="id"
                              placeholder="Status"
                              id="status"
                              [(ngModel)]="servidor.status"
                              [disabled]="!isEditingBasicInfos"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-3"
                        *ngIf="
                          servidor && servidor.status && servidor.status == 2
                        "
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="matricula_beneficiario"
                              >Matrícula</label
                            >
                            <input
                              type="text"
                              id="matricula_beneficiario"
                              class="form-control"
                              [(ngModel)]="servidor.matricula_beneficiario"
                              name="matricula_beneficiario"
                              placeholder="Matrícula"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-6"
                        *ngIf="
                          servidor && servidor.status && servidor.status == 2
                        "
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_ingresso"
                              >Ingresso no Serviço Público</label
                            >
                            <input
                              type="text"
                              id="data_ingresso"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="
                                servidor.data_ingresso_servico_publico
                              "
                              name="data_ingresso"
                              placeholder="Data de Ingresso no Serviço Público"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-md-3"
                        *ngIf="
                          servidor && servidor.status && servidor.status == 3
                        "
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="matricula_beneficiario"
                              >Matrícula do Beneficiário</label
                            >
                            <input
                              type="text"
                              id="matricula_beneficiario"
                              class="form-control"
                              [(ngModel)]="servidor.matricula_beneficiario"
                              name="matricula_beneficiario"
                              placeholder="Matrícula do Beneficiário"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-md-6"
                        *ngIf="
                          servidor && servidor.status && servidor.status == 3
                        "
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="tipo_fundo"
                              >Tipo de Fundo <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="tipoFundo"
                              name="tipo_fundo"
                              bindLabel="name"
                              placeholder="Tipo de Fundo"
                              id="tipo_fundo"
                              [(ngModel)]="servidor.tipo_fundo"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-md-6"
                        *ngIf="
                          servidor && servidor.status && servidor.status == 4
                        "
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="motivo_exoneracao"
                              >Motivo Exoneração</label
                            >
                            <input
                              type="text"
                              id="motivo_exoneracao"
                              class="form-control"
                              [(ngModel)]="servidor.motivo_exoneracao"
                              name="motivo_exoneracao"
                              placeholder="Motivo Exoneração"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-md-3"
                        *ngIf="
                          servidor && servidor.status && servidor.status == 5
                        "
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="tipo_fundo">Data Falecimento</label>
                            <input
                              type="text"
                              id="data_falecimento"
                              class="form-control"
                              mask="00/00/0000"
                              [disabled]="!isEditingBasicInfos"
                              [(ngModel)]="servidor.data_falecimento"
                              name="data_falecimento"
                              placeholder="Data do Falecimento"
                              required
                              aria-invalid="false"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 col-md-6"
                        *ngIf="
                          servidor && servidor.status && servidor.status == 5
                        "
                      >
                        <div class="form-group">
                          <div class="controls">
                            <label for="docingresso"
                              >Documento de Ingresso</label
                            >
                            <input
                              type="text"
                              id="docingresso"
                              class="form-control"
                              [disabled]="!isEditingBasicInfos"
                              [(ngModel)]="
                                servidor.documento_ingresso_servico_publico
                              "
                              name="docingresso"
                              placeholder="Documento de Ingresso"
                              required
                              aria-invalid="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      *ngIf="
                        servidor && servidor.status && servidor.status == 2
                      "
                    >
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_concessao"
                              >Data de Concessão do Benefício</label
                            >
                            <input
                              type="text"
                              id="data_concessao"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="servidor.concessao_aposentadoria"
                              name="data_concessao"
                              placeholder="Data de Concessão do Benefício"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tiposaposentadoria"
                              >Tipo de Aposentadoria</label
                            >
                            <ng-select
                              [items]="tiposaposentadoria"
                              name="tiposaposentadoria"
                              bindLabel="name"
                              groupBy="group"
                              placeholder="Tipo de Aposentadoria"
                              bindValue="value"
                              id="tiposaposentadoria"
                              [(ngModel)]="servidor.tipo_aposentadoria"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      *ngIf="
                        servidor && servidor.status && servidor.status == 2
                      "
                    >
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tipo_fundo"
                              >Tipo de Fundo <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="tipoFundo"
                              name="tipo_fundo"
                              bindLabel="name"
                              placeholder="Tipo de Fundo"
                              id="tipo_fundo"
                              [(ngModel)]="servidor.tipo_fundo"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="valoraposentadoria"
                              >Cargo Aposentado</label
                            >
                            <input
                              type="text"
                              id="cargo"
                              class="form-control"
                              [(ngModel)]="servidor.cargo_aposentadoria"
                              name="cargo"
                              placeholder="Cargo em que aposentou"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="valoraposentadoria"
                              >Valor Atual Apos.</label
                            >
                            <input
                              type="text"
                              id="valoraposentadoria"
                              class="form-control"
                              prefix="R$"
                              mask="separator.2"
                              thousandSeparator="."
                              decimalMarker=","
                              [dropSpecialCharacters]="false"
                              [(ngModel)]="servidor.valor_aposentadoria"
                              name="valoraposentadoria"
                              placeholder="Valor Atual da Aposentadoria"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      *ngIf="
                        servidor && servidor.status && servidor.status == 2
                      "
                    >
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="pis_pasep">PIS do Aposentado</label>
                            <input
                              type="text"
                              id="pis_pasep"
                              class="form-control"
                              [(ngModel)]="servidor.pis_aposentado"
                              mask="0*"
                              name="pis_pasep"
                              placeholder="PIS do Aposentado"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="paridade">Paridade</label>
                            <ng-select
                              [items]="naturalizados"
                              name="paridade"
                              bindLabel="name"
                              placeholder="Paridade"
                              bindValue="id"
                              id="paridade"
                              [(ngModel)]="servidor.paridade_aposentadoria"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      *ngIf="
                        servidor && servidor.status && servidor.status == 3
                      "
                    >
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_concessao"
                              >Data de Concessão do Benefício</label
                            >
                            <input
                              type="text"
                              id="data_concessao"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="servidor.concessao_aposentadoria"
                              name="data_concessao"
                              placeholder="Data de Concessão do Benefício"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="valor_beneficio"
                              >Valor do Benefício</label
                            >
                            <input
                              type="text"
                              id="valor_beneficio"
                              class="form-control"
                              prefix="R$"
                              mask="separator.2"
                              thousandSeparator="."
                              decimalMarker=","
                              [dropSpecialCharacters]="false"
                              [(ngModel)]="servidor.valor_beneficio"
                              name="valor_beneficio"
                              placeholder="Valor do Benefício"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tipo_pensao">Tipo de Pensão</label>
                            <ng-select
                              [items]="tipospensao"
                              name="tipo_pensao"
                              bindLabel="name"
                              placeholder="Tipo de Pensão"
                              bindValue="value"
                              id="tipo_pensao"
                              [(ngModel)]="servidor.tipo_pensao"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      *ngIf="
                        servidor && servidor.status && servidor.status == 3
                      "
                    >
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="idade">Idade</label>
                            <input
                              type="text"
                              id="idade"
                              class="form-control"
                              [(ngModel)]="servidor.idade"
                              disabled
                              name="idade"
                              placeholder="Idade"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tipo_fundo"
                              >Tipo de Vínculo
                              <b style="color: red">*</b></label
                            >
                            <ng-select
                              [items]="tipoVinculo"
                              name="tipo_vinculo"
                              bindLabel="name"
                              placeholder="Tipo de Vínculo"
                              id="tipo_vinculo"
                              [(ngModel)]="servidor.tipo_vinculo"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome_pensao"
                              >Nome do Instituidor
                              <b style="color: red">*</b></label
                            >
                            <input
                              type="text"
                              id="nome_pensao"
                              class="form-control"
                              [(ngModel)]="servidor.nome_pensao"
                              name="nome_pensao"
                              placeholder="Nome do Instituidor"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      *ngIf="
                        servidor && servidor.status && servidor.status == 3
                      "
                    >
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="relacao_titular_pensao"
                              >Relação com o Titular</label
                            >
                            <ng-select
                              [items]="relacaoTitularPensao"
                              name="relacao_titular"
                              bindLabel="name"
                              placeholder="Relação com o Titular"
                              bindValue="id"
                              id="relacao_titular_pensao"
                              [(ngModel)]="servidor.relacao_titular_pensao"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_nascimento_pensao"
                              >Nasc. Instituidor</label
                            >
                            <input
                              type="text"
                              id="data_nascimento_pensao"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="servidor.data_nascimento_pensao"
                              name="data_nascimento_pensao"
                              placeholder="Data de Nascimento do Instituidor da Pensão"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_falecimento_pensao"
                              >Facel. Instituidor</label
                            >
                            <input
                              type="text"
                              id="data_falecimento_pensao"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="servidor.data_falecimento_pensao"
                              name="data_falecimento_pensao"
                              placeholder="Data de Falecimento do Instituidor da Pensão"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      *ngIf="
                        servidor && servidor.status && servidor.status == 3
                      "
                    >
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="matricula_pensao"
                              >Matrícula do Instituidor</label
                            >
                            <input
                              type="text"
                              id="matricula_pensao"
                              class="form-control"
                              [(ngModel)]="servidor.matricula_pensao"
                              name="matricula_pensao"
                              placeholder="Matrícula do Instituidor"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="pis_pasep">PIS do Instituidor</label>
                            <input
                              type="text"
                              id="pis_pasep"
                              class="form-control"
                              [(ngModel)]="servidor.pis_pensao"
                              mask="0*"
                              name="pis_pasep"
                              placeholder="PIS do Instituidor"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cpf_pensao"
                              >CPF do Instituidor
                              <b style="color: red">*</b></label
                            >
                            <input
                              type="text"
                              id="cpf_pensao"
                              class="form-control"
                              [(ngModel)]="servidor.cpf_pensao"
                              name="cpf_pensao"
                              placeholder="CPF do Instituidor"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingBasicInfos"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="paridade">Paridade</label>
                            <ng-select
                              [items]="naturalizados"
                              name="paridade"
                              bindLabel="name"
                              placeholder="Paridade"
                              bindValue="id"
                              id="paridade"
                              [(ngModel)]="servidor.paridade_aposentadoria"
                              [disabled]="!isEditingBasicInfos"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <!-- Botão "Editar" quando isEditingBasicInfos for false -->
                      <div class="col" *ngIf="!isEditingBasicInfos">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          (click)="toggleEditBasicInfos()"
                        >
                          Editar
                        </button>
                      </div>

                      <!-- Botões "Salvar" e "Cancelar" quando isEditingBasicInfos for true -->
                      <div class="col" *ngIf="isEditingBasicInfos">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          [disabled]="disableServidor()"
                          (click)="editarServidor()"
                        >
                          Salvar
                        </button>

                        <button
                          type="button"
                          class="btn btn-outline-primary mb-2 mb-md-0 mr-2 float-right"
                          (click)="toggleEditBasicInfos(true)"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </ngb-tab>

              <ngb-tab title="Dados Pessoais">
                <ng-template ngbTabContent>
                  <h5 class="text-left mt-2">Endereço</h5>

                  <form #servForm="ngForm" novalidate>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cep">CEP**</label>
                            <input
                              type="text"
                              id="cep"
                              class="form-control"
                              [(ngModel)]="endereco.cep"
                              mask="00.000-000"
                              name="cep"
                              placeholder="CEP"
                              (change)="consultaCep('servidor')"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tiposlogradouro"
                              >Tipo Logradouro*</label
                            >
                            <ng-select
                              [items]="tiposLogradouro"
                              name="tiposlogradouro"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Tipo Logradouro"
                              id="tiposlogradouro"
                              [(ngModel)]="endereco.tipo_logradouro"
                              [disabled]="!isEditingPersonalData"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="estado">Estado*</label>
                            <ng-select
                              [items]="estados"
                              name="estado"
                              (change)="toggleEstadoCep('servidor')"
                              bindLabel="estado"
                              bindValue="estado"
                              placeholder="Estado"
                              id="estado"
                              [(ngModel)]="endereco.estado"
                              [disabled]="!isEditingPersonalData"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cidade">Cidade*</label>
                            <ng-select
                              [items]="cidades"
                              [disabled]="!cidades || cidades.length == 0"
                              name="cidade"
                              bindLabel="nome"
                              bindValue="id"
                              placeholder="Cidade"
                              id="cidade"
                              [(ngModel)]="endereco.cidade"
                              [disabled]="!isEditingPersonalData"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="logradouro">Logradouro*</label>
                            <input
                              type="text"
                              id="logradouro"
                              class="form-control"
                              [(ngModel)]="endereco.logradouro"
                              name="logradouro"
                              placeholder="Logradouro"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="numero">Número*</label>
                            <input
                              type="text"
                              id="numero"
                              class="form-control"
                              [(ngModel)]="endereco.numero"
                              name="numero"
                              placeholder="Número"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="bairro">Bairro*</label>
                            <input
                              type="text"
                              id="bairro"
                              class="form-control"
                              [(ngModel)]="endereco.bairro"
                              name="bairro"
                              placeholder="Bairro"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="complemento">Complemento*</label>
                            <input
                              type="text"
                              id="complemento"
                              class="form-control"
                              [(ngModel)]="endereco.complemento"
                              name="complemento"
                              placeholder="Complemento"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h5 class="text-left mt-2">Documentos</h5>

                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cpf"
                              >CPF*
                              <b
                                *ngIf="
                                  servidor &&
                                  servidor.status &&
                                  servidor.status == 1
                                "
                                style="color: red"
                                >*</b
                              ></label
                            >
                            <input
                              type="text"
                              id="cpf"
                              class="form-control"
                              [(ngModel)]="documentos.cpf"
                              mask="000.000.000-00"
                              name="cpf"
                              placeholder="CPF"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="rg"
                              >RG*
                              <b
                                *ngIf="
                                  servidor &&
                                  servidor.status &&
                                  servidor.status == 1
                                "
                                style="color: red"
                                >*</b
                              ></label
                            >
                            <input
                              type="text"
                              id="rg"
                              class="form-control"
                              [(ngModel)]="documentos.rg"
                              name="rg"
                              placeholder="RG"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_emissao_rg">Emissão RG</label>
                            <input
                              type="text"
                              id="data_emissao_rg"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="documentos.data_emissao_rg"
                              name="data_emissao_rg"
                              placeholder="Data Emissão RG"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="orgaosExpedidores"
                              >Orgão Expedidor
                              <b
                                *ngIf="
                                  servidor &&
                                  servidor.status &&
                                  servidor.status == 1
                                "
                                style="color: red"
                                >*</b
                              ></label
                            >
                            <ng-select
                              [items]="orgaosExpedidores"
                              name="orgaosExpedidores"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Orgão Expedidor RG"
                              id="orgaosExpedidores"
                              [(ngModel)]="documentos.orgao_expedidor_rg"
                              [disabled]="!isEditingPersonalData"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="uf_rg"
                              >UF RG
                              <b
                                *ngIf="
                                  servidor &&
                                  servidor.status &&
                                  servidor.status == 1
                                "
                                style="color: red"
                                >*</b
                              ></label
                            >
                            <ng-select
                              [items]="estados"
                              name="uf_rg"
                              bindLabel="estado"
                              bindValue="estado"
                              placeholder="UF RG"
                              id="uf_rg"
                              [(ngModel)]="documentos.uf_rg"
                              [disabled]="!isEditingPersonalData"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nit"
                              >PIS*
                              <b
                                *ngIf="
                                  servidor &&
                                  servidor.status &&
                                  servidor.status == 1
                                "
                                style="color: red"
                                >*</b
                              ></label
                            >
                            <input
                              type="text"
                              id="nit"
                              class="form-control"
                              [(ngModel)]="documentos.nit"
                              mask="000.00000.00-0"
                              name="nit"
                              placeholder="PIS"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h5 class="text-left mt-2">Contato</h5>

                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="telefone">Telefone*</label>
                            <input
                              type="text"
                              id="telefone"
                              class="form-control"
                              [(ngModel)]="contato.telefone"
                              mask="(00) 0000-0000"
                              name="telefone"
                              placeholder="Telefone"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="celular">Celular*</label>
                            <input
                              type="text"
                              id="celular"
                              class="form-control"
                              [(ngModel)]="contato.celular"
                              mask="(00) 00000-0000"
                              name="celular"
                              placeholder="Celular"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="email">Email*</label>
                            <input
                              type="text"
                              id="email"
                              class="form-control"
                              [(ngModel)]="contato.email"
                              name="email"
                              placeholder="Email"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingPersonalData"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col" *ngIf="!isEditingPersonalData">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          (click)="toggleEditPersonalData()"
                        >
                          Editar
                        </button>
                      </div>

                      <div class="col" *ngIf="isEditingPersonalData">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrarContato()"
                          [disabled]="
                            !endereco.cep ||
                            !endereco.tipo_logradouro ||
                            !endereco.logradouro ||
                            !endereco.bairro ||
                            !endereco.complemento ||
                            !endereco.cidade ||
                            !endereco.estado ||
                            !endereco.numero ||
                            disableDocumentos() ||
                            (!contato.celular &&
                              !contato.email &&
                              !contato.telefone)
                          "
                        >
                          Salvar
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-primary float-right mr-2"
                          (click)="toggleEditPersonalData(true)"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </ngb-tab>

              <ngb-tab title="Pendências">
                <ng-template ngbTabContent>
                  <h5 class="text-left mt-2">Pendências e Observações</h5>
                  <div class="form-row">
                    <div class="col-12">
                      <div class="form-group">
                        <div class="controls">
                          <label for="pendencias">Pendências</label>
                          <textarea
                            maxlength="1000"
                            id="pendencias"
                            class="form-control"
                            [disabled]="!isEditingObservations"
                            [(ngModel)]="servidor.pendencias"
                            name="pendencias"
                            placeholder="Escreva a pendência ou observação do Servidor cadastrado. Documento faltante e/ou busca a ser feita ou confirmada para êxito no cadastro."
                            required
                            aria-invalid="false"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <div class="controls">
                          <label for="observacoes">observações</label>
                          <textarea
                            maxlength="1000"
                            id="observacoes"
                            class="form-control"
                            [disabled]="!isEditingObservations"
                            [(ngModel)]="servidor.observacoes"
                            name="observacoes"
                            placeholder="Observações"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <!-- Botão "Editar" quando isEditingBasicInfos for false -->
                    <div class="col" *ngIf="!isEditingObservations">
                      <button
                        type="button"
                        class="btn btn-primary mb-2 mb-md-0 float-right"
                        (click)="toggleEditObservations()"
                      >
                        Editar
                      </button>
                    </div>

                    <!-- Botões "Salvar" e "Cancelar" quando isEditingBasicInfos for true -->
                    <div class="col" *ngIf="isEditingObservations">
                      <button
                        type="button"
                        class="btn btn-primary mb-2 mb-md-0 float-right"
                        [disabled]="disableServidor()"
                        (click)="editarServidor()"
                      >
                        Salvar
                      </button>

                      <button
                        type="button"
                        class="btn btn-outline-primary mb-2 mb-md-0 mr-2 float-right"
                        (click)="toggleEditObservations(true)"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>

              <ngb-tab title="Óbito">
                <ng-template ngbTabContent>
                  <form #servForm="ngForm" novalidate>
                    <h5 class="text-left mt-2">Dados do Cartório</h5>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome_cartorio">Nome Cartório</label>
                            <input
                              type="text"
                              id="nome_cartorio"
                              class="form-control"
                              [(ngModel)]="obito.nome_cartorio"
                              name="nome_cartorio"
                              placeholder="Nome Cartório"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cnpj_cartorio">CNPJ</label>
                            <input
                              type="text"
                              id="cnpj_cartorio"
                              class="form-control"
                              [(ngModel)]="obito.cnpj_cartorio"
                              name="cnpj_cartorio"
                              placeholder="CNPJ"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cep">CEP</label>
                            <input
                              type="text"
                              id="cep"
                              class="form-control"
                              [(ngModel)]="obito.cep_cartorio"
                              mask="00.000-000"
                              name="cep"
                              placeholder="CEP"
                              (change)="consultaCep('obito')"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tiposlogradouro">Tipo Logradouro</label>
                            <ng-select
                              [items]="tiposLogradouro"
                              name="tiposlogradouro"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Tipo Logradouro"
                              id="tiposlogradouro"
                              [(ngModel)]="obito.tipo_logradouro_cartorio"
                              [disabled]="!isEditingObito"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="logradouro">Logradouro</label>
                            <input
                              type="text"
                              id="logradouro"
                              class="form-control"
                              [(ngModel)]="obito.logradouro_cartorio"
                              name="logradouro"
                              placeholder="Logradouro"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="numero">Número</label>
                            <input
                              type="text"
                              id="numero"
                              class="form-control"
                              [(ngModel)]="obito.numero_cartorio"
                              name="numero"
                              placeholder="Número"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="estado">Estado</label>
                            <ng-select
                              [items]="estados"
                              name="estado"
                              (change)="toggleEstadoCep('obito')"
                              bindLabel="estado"
                              bindValue="estado"
                              placeholder="Estado"
                              id="estado"
                              [(ngModel)]="obito.estado_cartorio"
                              [disabled]="!isEditingObito"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cidade">Cidade</label>
                            <ng-select
                              [items]="cidades"
                              [disabled]="!cidades || cidades.length == 0"
                              name="cidade"
                              bindLabel="nome"
                              bindValue="id"
                              placeholder="Cidade"
                              id="cidade"
                              [(ngModel)]="obito.cidade_cartorio"
                              [disabled]="!isEditingObito"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="bairro">Bairro</label>
                            <input
                              type="text"
                              id="bairro"
                              class="form-control"
                              [(ngModel)]="obito.bairro_cartorio"
                              name="bairro"
                              placeholder="Bairro"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="complemento">Complemento</label>
                            <input
                              type="text"
                              id="complemento"
                              class="form-control"
                              [(ngModel)]="obito.complemento_cartorio"
                              name="complemento"
                              placeholder="Complemento"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5 class="text-left mt-2">Dados da Certidão</h5>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_emissao">Data de Emissão</label>
                            <input
                              type="text"
                              id="data_emissao"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="obito.data_emissao"
                              name="data_emissao"
                              placeholder="Data de Emissão"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="numero_termo">Número Termo</label>
                            <input
                              type="text"
                              id="numero_termo"
                              class="form-control"
                              [(ngModel)]="obito.numero_termo"
                              name="numero_termo"
                              placeholder="Número Termo"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="numero_livro">Número Livro</label>
                            <input
                              type="text"
                              id="numero_livro"
                              class="form-control"
                              [(ngModel)]="obito.numero_livro"
                              name="numero_livro"
                              placeholder="Número Livro"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="numero_folha">Número Folha</label>
                            <input
                              type="text"
                              id="numero_folha"
                              class="form-control"
                              [(ngModel)]="obito.numero_folha"
                              name="numero_folha"
                              placeholder="Número Folha"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingObito"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <!-- Botão "Editar" quando isEditingBasicInfos for false -->
                      <div class="col" *ngIf="!isEditingObito">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          (click)="toggleEditObito()"
                        >
                          Editar
                        </button>
                      </div>

                      <!-- Botões "Salvar" e "Cancelar" quando isEditingBasicInfos for true -->
                      <div class="col" *ngIf="isEditingObito">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrarObito()"
                          [disabled]="
                            !obito.numero_termo ||
                            !obito.numero_folha ||
                            !obito.numero_livro ||
                            !obito.data_emissao ||
                            !obito.nome_cartorio ||
                            !obito.tipo_logradouro_cartorio ||
                            !obito.logradouro_cartorio ||
                            !obito.numero_cartorio ||
                            !obito.complemento_cartorio ||
                            !obito.bairro_cartorio ||
                            !obito.cep_cartorio ||
                            !obito.estado_cartorio ||
                            !obito.cidade_cartorio
                          "
                        >
                          {{ obito.id ? "Atualizar" : "Cadastrar" }}
                        </button>

                        <button
                          type="button"
                          class="btn btn-outline-primary mb-2 mb-md-0 mr-2 float-right"
                          (click)="toggleEditObito(true)"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </ngb-tab>

              <ngb-tab title="Representante Legal">
                <ng-template ngbTabContent>
                  <form #servForm="ngForm" novalidate>
                    <h5 class="text-left mt-2">Dados do Representante</h5>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tipo_representatividade"
                              >Tipo Representatividade</label
                            >
                            <ng-select
                              [items]="tiposRepresentatividade"
                              name="tipo_representatividade"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Tipo Representatividade"
                              id="tipo_representatividade"
                              [(ngModel)]="
                                representante.tipo_representatividade
                              "
                              [disabled]="!isEditingRepresentante"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_inicio">Início</label>
                            <input
                              type="text"
                              id="data_inicio"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="representante.data_inicio"
                              name="data_inicio"
                              placeholder="Data Início"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_fim">Fim</label>
                            <input
                              type="text"
                              id="data_fim"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="representante.data_fim"
                              name="data_fim"
                              placeholder="Data Fim"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome">Nome</label>
                            <input
                              type="text"
                              id="nome"
                              class="form-control"
                              [(ngModel)]="representante.nome_representante"
                              name="nome"
                              placeholder="Nome Completo"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="sexo">Sexo</label>
                            <ng-select
                              [items]="sexos"
                              name="sexo"
                              bindLabel="name"
                              placeholder="Sexo"
                              bindValue="id"
                              id="sexo"
                              [(ngModel)]="representante.sexo_representante"
                              [disabled]="!isEditingRepresentante"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_nascimento">Nascimento</label>
                            <input
                              type="text"
                              id="data_nascimento"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="
                                representante.data_nascimento_representante
                              "
                              name="data_nascimento"
                              placeholder="Data Nascimento"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <h5 class="text-left mt-2">Documentos</h5>

                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cpf">CPF</label>
                            <input
                              type="text"
                              id="cpf"
                              class="form-control"
                              [(ngModel)]="representante.cpf_representante"
                              mask="000.000.000-00"
                              name="cpf"
                              placeholder="CPF"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="rg">RG</label>
                            <input
                              type="text"
                              id="rg"
                              class="form-control"
                              [(ngModel)]="representante.rg_representante"
                              name="rg"
                              placeholder="RG"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="data_emissao_rg">Emissão RG</label>
                            <input
                              type="text"
                              id="data_emissao_rg"
                              class="form-control"
                              mask="00/00/0000"
                              [(ngModel)]="
                                representante.data_emissao_rg_representante
                              "
                              name="data_emissao_rg"
                              placeholder="Data Emissão RG"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="orgaosExpedidores"
                              >Orgão Expedidor RG</label
                            >
                            <ng-select
                              [items]="orgaosExpedidores"
                              name="orgaosExpedidores"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Orgão Expedidor RG"
                              id="orgaosExpedidores"
                              [(ngModel)]="
                                representante.orgao_expedidor_rg_representante
                              "
                              [disabled]="!isEditingRepresentante"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="uf_rg">Unidade Federal RG</label>
                            <ng-select
                              [items]="estados"
                              name="uf_rg"
                              bindLabel="estado"
                              bindValue="estado"
                              placeholder="UF RG"
                              id="uf_rg"
                              [(ngModel)]="representante.uf_rg_representante"
                              [disabled]="!isEditingRepresentante"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h5 class="text-left mt-2">Endereço</h5>

                    <div class="form-row">
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cep">CEP</label>
                            <input
                              type="text"
                              id="cep"
                              class="form-control"
                              [(ngModel)]="representante.cep_representante"
                              mask="00.000-000"
                              name="cep"
                              placeholder="CEP"
                              (change)="consultaCep('representante')"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="tiposlogradouro">Tipo Logradouro</label>
                            <ng-select
                              [items]="tiposLogradouro"
                              name="tiposlogradouro"
                              bindLabel="descricao"
                              bindValue="id"
                              placeholder="Tipo Logradouro"
                              id="tiposlogradouro"
                              [(ngModel)]="
                                representante.tipo_logradouro_representante
                              "
                              [disabled]="!isEditingRepresentante"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="logradouro">Logradouro</label>
                            <input
                              type="text"
                              id="logradouro"
                              class="form-control"
                              [(ngModel)]="
                                representante.logradouro_representante
                              "
                              name="logradouro"
                              placeholder="Logradouro"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="numero">Número</label>
                            <input
                              type="text"
                              id="numero"
                              class="form-control"
                              [(ngModel)]="representante.numero_representante"
                              name="numero"
                              placeholder="Número"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="estado">Estado</label>
                            <ng-select
                              [items]="estados"
                              name="estado"
                              (change)="toggleEstadoCep('representante')"
                              bindLabel="estado"
                              bindValue="estado"
                              placeholder="Estado"
                              id="estado"
                              [(ngModel)]="representante.estado_representante"
                              [disabled]="!isEditingRepresentante"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cidade">Cidade</label>
                            <ng-select
                              [items]="cidades"
                              [disabled]="!cidades || cidades.length == 0"
                              name="cidade"
                              bindLabel="nome"
                              bindValue="id"
                              placeholder="Cidade"
                              id="cidade"
                              [(ngModel)]="representante.cidade_representante"
                              [disabled]="!isEditingRepresentante"
                            ></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="bairro">Bairro</label>
                            <input
                              type="text"
                              id="bairro"
                              class="form-control"
                              [(ngModel)]="representante.bairro_representante"
                              name="bairro"
                              placeholder="Bairro"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="complemento">Complemento</label>
                            <input
                              type="text"
                              id="complemento"
                              class="form-control"
                              [(ngModel)]="
                                representante.complemento_representante
                              "
                              name="complemento"
                              placeholder="Complemento"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <h5 class="text-left mt-2">Contato</h5>

                    <div class="form-row">
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="telefone">Telefone</label>
                            <input
                              type="text"
                              id="telefone"
                              class="form-control"
                              [(ngModel)]="representante.telefone_representante"
                              mask="(00) 0000-0000"
                              name="telefone"
                              placeholder="Telefone"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <div class="controls">
                            <label for="celular">Celular</label>
                            <input
                              type="text"
                              id="celular"
                              class="form-control"
                              [(ngModel)]="representante.celular_representante"
                              mask="(00) 00000-0000"
                              name="celular"
                              placeholder="Celular"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label for="email">Email</label>
                            <input
                              type="text"
                              id="email"
                              class="form-control"
                              [(ngModel)]="representante.email_representante"
                              name="email"
                              placeholder="Email"
                              required
                              aria-invalid="false"
                              [disabled]="!isEditingRepresentante"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <!-- Botão "Editar" quando isEditingBasicInfos for false -->
                      <div class="col" *ngIf="!isEditingRepresentante">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          (click)="toggleEditRepresentante()"
                        >
                          Editar
                        </button>
                      </div>

                      <!-- Botões "Salvar" e "Cancelar" quando isEditingBasicInfos for true -->
                      <div class="col" *ngIf="isEditingRepresentante">
                        <button
                          type="button"
                          class="btn btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrarRepresentante()"
                          [disabled]="
                            !representante.tipo_representatividade ||
                            !representante.data_inicio ||
                            !representante.data_fim ||
                            !representante.nome_representante ||
                            !representante.cpf_representante ||
                            !representante.rg_representante ||
                            !representante.orgao_expedidor_rg_representante ||
                            !representante.data_emissao_rg_representante ||
                            !representante.uf_rg_representante ||
                            !representante.data_nascimento_representante ||
                            !representante.sexo_representante ||
                            !representante.tipo_logradouro_representante ||
                            !representante.logradouro_representante ||
                            !representante.numero_representante ||
                            !representante.complemento_representante ||
                            !representante.bairro_representante ||
                            !representante.cep_representante ||
                            !representante.estado_representante ||
                            !representante.cidade_representante
                          "
                        >
                          {{ representante.id ? "Atualizar" : "Cadastrar" }}
                        </button>

                        <button
                          type="button"
                          class="btn btn-outline-primary mb-2 mb-md-0 mr-2 float-right"
                          (click)="toggleEditRepresentante(true)"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </form>
                </ng-template>
              </ngb-tab>
            </ngb-tabset>
          </div>
        </div>
      </div>

      <div class="attachments-info">
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <h4>Documentos Anexados</h4>
            <button class="btn-no-border" (click)="toggleAttachmentsData()">
              {{ isAttachmentsDataVisible ? "Ocultar" : "Mostrar" }}
            </button>
          </div>

          <div class="card-body" *ngIf="isAttachmentsDataVisible">
            <div *ngIf="anexos && anexos.length === 0" class="no-benefits">
              <!-- Mensagem de orientação -->
              <p class="text-muted text-center">
                Adicione um novo item clicando no botão <i>"Adicionar"</i>
              </p>
            </div>

            <div
              *ngFor="let anexo of anexos; let i = index"
              [ngClass]="{
                'custom-card': true,
                'single-card': anexos.length === 1,
                'mt-0': i !== 0,
                'first-card': i === 0,
                'last-card': i === anexos.length - 1
              }"
            >
              <div class="card-body">
                <!-- Título -->
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title text-start">
                    {{ anexo.referencia }}
                  </h5>
                </div>

                <div class="row">
                  <!-- Informações da Remuneração -->
                  <div class="col-8 card-text mt-2">
                    <p>
                      Nome:
                      {{ anexo.nome }}
                    </p>
                    <p>
                      Importado em:
                      {{ beautifulDateSimple(anexo.data_inclusao) }}
                    </p>
                  </div>

                  <!-- Botões de Ação -->
                  <div
                    class="col-4 actions d-flex justify-content-end align-items-end"
                  >
                    <button
                      class="btn btn-outline-danger"
                      (click)="deleteAttachment(anexo.chave, anexo.id)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      class="btn btn-outline-success"
                      (click)="downloadAttachment(anexo.chave, anexo.nome)"
                    >
                      <i class="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end align-items-end mb-3 mt-3">
              <button
                class="btn btn-primary"
                (click)="openModalLg(cadastroAnexo)"
              >
                Adicionar Documento
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="dependents-info">
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <h4>Dependentes</h4>
            <button class="btn-no-border" (click)="toggleDependentsData()">
              {{ isDependentsDataVisible ? "Ocultar" : "Mostrar" }}
            </button>
          </div>

          <div class="card-body" *ngIf="isDependentsDataVisible">
            <div
              *ngIf="dependentes && dependentes.length === 0"
              class="no-benefits"
            >
              <!-- Mensagem de orientação -->
              <p class="text-muted text-center">
                Adicione um novo item clicando no botão <i>"Adicionar"</i>
              </p>
            </div>

            <div
              *ngFor="let dependente of dependentes; let i = index"
              [ngClass]="{
                'custom-card': true,
                'single-card': dependentes.length === 1,
                'mt-0': i !== 0,
                'first-card': i === 0,
                'last-card': i === dependentes.length - 1
              }"
            >
              <div class="card-body">
                <!-- Título -->
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title text-start">
                    {{ dependente.nome }}
                  </h5>
                </div>

                <div class="row">
                  <!-- Informações da Remuneração -->
                  <div class="col-8 card-text mt-2">
                    <p>
                      <b>Relação:</b>
                      {{ dependente.labelRelacao }}
                    </p>
                    <p>
                      <b>CPF:</b>
                      {{
                        dependente && dependente.cpf
                          ? (dependente?.cpf | mask : "000.000.000-00")
                          : "-----"
                      }}
                    </p>
                    <p>
                      <b>Idade:</b>
                      {{ dependente.idade }} anos
                    </p>
                  </div>

                  <!-- Botões de Ação -->
                  <div
                    class="col-4 actions d-flex justify-content-end align-items-end"
                  >
                    <button
                      class="btn btn-outline-danger"
                      (click)="deletarDependente(dependente.id)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      class="btn btn-outline-success"
                      (click)="
                        openModalDependent(cadastroDependente, dependente)
                      "
                    >
                      <i class="fa fa-eye"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end align-items-end mb-3 mt-3">
              <button
                class="btn btn-primary"
                (click)="openModalDependent(cadastroDependente, null)"
              >
                Adicionar Dependente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Card do Tempo de Atendimento e Links -->
    <div class="col-lg-3 col-md-4">
      <div class="card atendimento-card">
        <h5>Tempo de Atendimento</h5>
        <div class="row" *ngIf="servidor">
          <div class="col-7">
            <p class="time" *ngIf="servidor && servidor.atendimento!=null">{{ servidor.atendimento }}</p>
            <p class="time" style="font-size: 1em" *ngIf="servidor.inicio_atendimento!=null && servidor.fim_atendimento==null">Em Atendimento</p>
          </div>
          <div class="col-5 align-items-end">
            <div class="actions">
              <button [disabled]="servidor.inicio_atendimento==null || (servidor.inicio_atendimento!=null && servidor.fim_atendimento==null)" class="btn btn-rounded btn-outline-primary"
              (click)="openModal(comprovante)">
                <i class="fa fa-file"></i>
              </button>
              <button (click)="iniciarAtendimento()" *ngIf="servidor.inicio_atendimento==null" class="btn btn-rounded btn-outline-primary">
                <i class="fa fa-play"></i>
              </button>
              <button (click)="finalizarAtendimento()" *ngIf="servidor.inicio_atendimento!=null && servidor.fim_atendimento==null" class="btn btn-rounded btn-outline-primary">
                <i class="fa fa-stop"></i>
              </button>
              <button (click)="reiniciarAtendimento()" *ngIf="servidor.inicio_atendimento!=null && servidor.fim_atendimento!=null" class="btn btn-rounded btn-outline-primary">
                <i class="fa fa-refresh"></i>
              </button>
            </div>
          </div>
        </div>

        <label>Status de Agendamento</label>
        <ng-select [items]="statusAgenda" 
        name="statusAgenda" 
        *ngIf="servidor && servidor.agenda"
        bindLabel="descricao" 
        bindValue="id" 
        placeholder="" 
        id="status_agenda" 
        style="width: 280px;"
        [(ngModel)]="servidor.agenda"
        (change)="updateAgendaStatus($event)" >
        </ng-select>
        <button *ngIf="servidor && mostrarBotaoAtualizar" class="btn btn-primary mt-2" (click)="editarServidor()">
          Atualizar
        </button>
      </div>

      <!-- Links Laterais -->
      <div class="sidebar-links mt-3">
        <a (click)="navigateTo('page/servidor/movimento')" class="d-block" class="d-block"
          ><i class="fa fa-check-square-o"></i> Movimentos Funcionais</a
        >
        <a (click)="navigateTo('page/servidor/tempos')" class="d-block"
          ><i class="fa fa-clock-o"></i> Tempo de Serviço Passado</a
        >
      </div>
    </div>
  </div>
</div>
<ng-template #cadastroAnexo let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Novo Anexo</h5>
  </div>
  <div class="modal-body">
    <form #servForm="ngForm" novalidate>
      <div class="row align-items-end">
        <div class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label for="referencias">Referência</label>
              <ng-select
                [appendTo]="'body'"
                [items]="referencias"
                name="referencias"
                placeholder="Referência"
                id="referencias"
                [(ngModel)]="anexo.referencia"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <button
              type="button"
              class="btn btn-block btn-outline-primary"
              (click)="fileInput.click()"
            >
              <i class="fa fa-upload"></i> Carregar Documento
            </button>
            <!-- Input file oculto -->
            <input
              #fileInput
              type="file"
              (change)="selecionaArquivo($event)"
              class="d-none"
              accept=".xls,.xlsx,.docx,.pdf,.doc"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <!-- Botão Voltar -->
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="onCancelAssentamento(modal)"
        >
          Voltar
        </button>
        <!-- Botão Salvar -->
        <button
          type="button"
          class="btn btn-primary mb-2 mb-md-0 float-right"
          (click)="cadastrarAnexo(modal)"
          [disabled]="!anexo.arquivo || !anexo.referencia"
        >
          Adicionar
        </button>
      </div>
    </form>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #cadastroDependente let-modal>
  <div class="container">
    <div class="modal-header">
      <h4 class="modal-title">Novo Dependente</h4>
    </div>
    <div class="modal-body">
      <form #servForm="ngForm" novalidate>
        <h5 class="text-left">Dados do Dependente</h5>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="tiposDependencia">Tipo Dependência</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="tiposDependencia"
                  name="tiposDependencia"
                  bindLabel="descricao"
                  bindValue="id"
                  placeholder="Tipo Dependência"
                  id="tiposDependencia"
                  [(ngModel)]="dependente.tipo_dependencia"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="motivosInicio">Motivo Início Dependência</label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="motivosInicio"
                  name="motivosInicio"
                  bindLabel="descricao"
                  bindValue="id"
                  placeholder="Motivo Início Dependência"
                  id="motivosInicio"
                  [(ngModel)]="dependente.motivo_inicio"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="nome">Nome <b style="color: red">*</b></label>
                <input
                  type="text"
                  id="nome"
                  class="form-control"
                  [(ngModel)]="dependente.nome"
                  name="nome"
                  placeholder="Nome Completo"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="sexo">Sexo <b style="color: red">*</b></label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="sexos"
                  name="sexo"
                  bindLabel="name"
                  placeholder="Sexo"
                  bindValue="id"
                  id="sexo"
                  [(ngModel)]="dependente.sexo"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="mae">Nome Mãe <b style="color: red">*</b></label>
                <input
                  type="text"
                  id="mae"
                  class="form-control"
                  [(ngModel)]="dependente.nome_mae"
                  name="mae"
                  placeholder="Nome Mãe"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="pai">Nome Pai</label>
                <input
                  type="text"
                  id="pai"
                  class="form-control"
                  [(ngModel)]="dependente.nome_pai"
                  name="pai"
                  placeholder="Nome Pai"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="data_nascimento"
                  >Nascimento <b style="color: red">*</b></label
                >
                <input
                  type="text"
                  id="data_nascimento"
                  class="form-control"
                  mask="00/00/0000"
                  [(ngModel)]="dependente.data_nascimento"
                  name="data_nascimento"
                  placeholder="Data Nascimento"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="estado_civil"
                  >Estado Civil <b style="color: red">*</b></label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="estadosCivis"
                  name="estado_civil"
                  bindLabel="descricao"
                  bindValue="id"
                  placeholder="Estado Civil"
                  id="estado_civil"
                  [(ngModel)]="dependente.estado_civil"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3">
            <div class="form-group">
              <div class="controls">
                <label for="naturalizado"
                  >Naturalizado <b style="color: red">*</b></label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="naturalizados"
                  name="naturalizado"
                  bindLabel="name"
                  placeholder="Naturalizado"
                  bindValue="id"
                  id="naturalizado"
                  [(ngModel)]="dependente.naturalizado"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="controls">
                <label for="nome"
                  >UF Naturalidade <b style="color: red">*</b></label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="estados"
                  name="estado"
                  (change)="toggleEstadoDependente(true)"
                  bindLabel="estado"
                  bindValue="estado"
                  placeholder="Estado"
                  id="estado"
                  [(ngModel)]="dependente.estado_naturalidade"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="nome"
                  >Cidade Naturalidade <b style="color: red">*</b></label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="cidadesNaturalidade"
                  [disabled]="
                    !cidadesNaturalidade || cidadesNaturalidade.length == 0
                  "
                  name="cidade"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Cidade"
                  id="cidade"
                  [(ngModel)]="dependente.cidade_naturalidade"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="nacionalidade"
                  >Nacionalidade <b style="color: red">*</b></label
                >
                <input
                  type="text"
                  id="nacionalidade"
                  class="form-control"
                  [(ngModel)]="dependente.nacionalidade"
                  name="nacionalidade"
                  placeholder="Nacionalidade"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="escolaridade"
                  >Escolaridade <b style="color: red">*</b></label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="escolaridades"
                  name="escolaridade"
                  bindLabel="descricao"
                  bindValue="id"
                  placeholder="Escolaridade"
                  id="escolaridade"
                  [(ngModel)]="dependente.escolaridade"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="naturalizado"
                  >Deficiente <b style="color: red">*</b></label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="naturalizados"
                  name="deficiente"
                  bindLabel="name"
                  placeholder="Deficiente"
                  bindValue="id"
                  id="deficiente"
                  [(ngModel)]="dependente.deficiente"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h5 class="text-left">Documentos do Dependente</h5>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="cpf">CPF <b style="color: red">*</b></label>
                <input
                  type="text"
                  id="cpf"
                  class="form-control"
                  [(ngModel)]="dependente.cpf"
                  mask="000.000.000-00"
                  name="cpf"
                  placeholder="CPF"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h5 class="text-left">Endereço</h5>
        <div class="form-row">
          <div class="col">
            <div class="form-check">
              <input
                (change)="copiaEndereco($event)"
                [checked]="checkEndereco"
                type="checkbox"
                class="form-check-input"
                id="copia_end_serv"
              />
              <label class="form-check-label" for="copia_end_serv"
                ><b>Copiar dados do titular</b></label
              >
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="cep">CEP <b style="color: red">*</b></label>
                <input
                  type="text"
                  id="cep"
                  class="form-control"
                  [(ngModel)]="dependente.cep"
                  mask="00.000-000"
                  name="cep"
                  placeholder="CEP"
                  (change)="consultaCep('dependente')"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="tiposlogradouro"
                  >Tipo Logradouro <b style="color: red">*</b></label
                >
                <ng-select
                  [appendTo]="'body'"
                  [items]="tiposLogradouro"
                  name="tiposlogradouro"
                  bindLabel="descricao"
                  bindValue="id"
                  placeholder="Tipo Logradouro"
                  id="tiposlogradouro"
                  [(ngModel)]="dependente.tipo_logradouro"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="estado1">Estado <b style="color: red">*</b></label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="estados"
                  name="estado1"
                  (change)="toggleEstadoDependente(false)"
                  bindLabel="estado"
                  bindValue="estado"
                  placeholder="Estado"
                  id="estado1"
                  [(ngModel)]="dependente.estado"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="cidade1">Cidade <b style="color: red">*</b></label>
                <ng-select
                  [appendTo]="'body'"
                  [items]="cidades"
                  [disabled]="!cidades || cidades.length == 0"
                  name="cidade1"
                  bindLabel="nome"
                  bindValue="id"
                  placeholder="Cidade"
                  id="cidade1"
                  [(ngModel)]="dependente.cidade"
                ></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="logradouro"
                  >Logradouro <b style="color: red">*</b></label
                >
                <input
                  type="text"
                  id="logradouro"
                  class="form-control"
                  [(ngModel)]="dependente.logradouro"
                  name="logradouro"
                  placeholder="Logradouro"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="numero">Número <b style="color: red">*</b></label>
                <input
                  type="text"
                  id="numero"
                  class="form-control"
                  [(ngModel)]="dependente.numero"
                  name="numero"
                  placeholder="Número"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="bairro">Bairro <b style="color: red">*</b></label>
                <input
                  type="text"
                  id="bairro"
                  class="form-control"
                  [(ngModel)]="dependente.bairro"
                  name="bairro"
                  placeholder="Bairro"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="controls">
                <label for="complemento"
                  >Complemento <b style="color: red">*</b></label
                >
                <input
                  type="text"
                  id="complemento"
                  class="form-control"
                  [(ngModel)]="dependente.complemento"
                  name="complemento"
                  placeholder="Complemento"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h5 class="text-left">Contato</h5>
        <div class="form-row">
          <div class="col-12">
            <div class="form-check">
              <input
                (change)="copiaContatos($event)"
                [checked]="checkContatos"
                type="checkbox"
                class="form-check-input"
                id="copia_cont_serv"
              />
              <label class="form-check-label" for="copia_cont_serv"
                ><b>Copiar Contatos Servidor</b></label
              >
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="telefone">Telefone</label>
                <input
                  type="text"
                  id="telefone"
                  class="form-control"
                  [(ngModel)]="dependente.telefone"
                  mask="(00) 0000-0000"
                  name="telefone"
                  placeholder="Telefone"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="controls">
                <label for="celular">Celular</label>
                <input
                  type="text"
                  id="celular"
                  class="form-control"
                  [(ngModel)]="dependente.celular"
                  mask="(00) 00000-0000"
                  name="celular"
                  placeholder="Celular"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="controls">
                <label for="email">Email</label>
                <input
                  type="text"
                  id="email"
                  class="form-control"
                  [(ngModel)]="dependente.email"
                  name="email"
                  placeholder="Email"
                  required
                  aria-invalid="false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
              (click)="atualizarOuCadastrarDependente(modal)"
              [disabled]="
                !dependente.nome ||
                !dependente.data_nascimento ||
                !dependente.cpf ||
                !dependente.estado_civil ||
                !dependente.sexo ||
                !dependente.estado_naturalidade ||
                !dependente.cidade_naturalidade ||
                !dependente.nacionalidade ||
                !dependente.naturalizado ||
                !dependente.escolaridade ||
                !dependente.nome_mae ||
                !dependente.deficiente ||
                !dependente.tipo_logradouro ||
                !dependente.logradouro ||
                !dependente.numero ||
                !dependente.complemento ||
                !dependente.bairro ||
                !dependente.estado ||
                !dependente.cidade ||
                !dependente.cep
              "
            >
              {{ dependente.id ? "Atualizar" : "Cadastrar" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #comprovante let-modal>
  <div class="row mb-2" id="print-section"  >
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Comprovante de atendimento</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col">
                Inicio do atendimento: {{beautifulDate(servidor.inicio_atendimento)}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Fim do atendimento: {{beautifulDate(servidor.fim_atendimento)}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Nome: {{servidor.nome}}
              </div>
            </div>
            <div class="row" *ngIf="servidor.cpf || servidor.cpf_pensao">
              <div class="col">
                CPF: {{servidor.cpf || servidor.cpf_pensao}}
              </div>
            </div>
            <div class="row" *ngIf="servidor.matricula_pensao || servidor.matricula_beneficiario">
              <div class="col">
                Matricula: {{servidor.matricula_pensao || servidor.matricula_beneficiario}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Município: {{servidor.nomecidade}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b>Você participou do censo previdenciário {{getYear(servidor.inicio_atendimento)}} do regime próprio de previdência social do município de {{servidor.nomecidade}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <button  type="button" class="btn btn-lg btn-primary" style="margin-left: auto; margin-right: auto; display: flex;"    printTitle="Comprovante de atendimento"
      printDelay="1000"  printSectionId="print-section" ngxPrint >Salvar PDF</button>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #modalFoto let-modal>
  <div class="modal fade show d-block" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content p-3">
        <div class="modal-header border-0">
          <h4 class="modal-title">Alterar Foto</h4>
        </div>
        <div class="modal-body text-center">
          <p>Permitido apenas JPG, JPEG ou PNG.<br> Tamanho máximo de arquivo 2mb.</p>
          
          <!-- Upload de imagem -->
          <label class="btn btn-outline-primary w-100 mb-2" for="select-files">
            <i class="fa fa-upload"></i> Carregar Foto
          </label>
          <input type="file" accept="image/*" (change)="uploadImage($event)" id="select-files" hidden>

          <!-- Tirar Foto -->
          <button type="button" class="btn btn-outline-primary w-100 mb-2" (click)="openModalCamera(takepicture)">
            <i class="fa fa-camera"></i> Tirar Foto
          </button>

          <!-- Voltar -->
          <button type="button" class="btn btn-outline-primary w-100"  (click)="modal.close('Close click')">Voltar</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #takepicture let-modal>

  <div class="row mb-2" id="print-section"  >
    <div class="col">
      <div class="card">
        <div class="card-header">
          <p style="color: red;"><b>Antes de capturar certifique-se de registrar uma foto onde o rosto do servidor estejam bem visível, com boa nitidez e luminosidade. Não permita utilizar bonés, óculos e nem sorrir!</b></p>
        </div>
        <div class="card-content">

      <webcam [height]="500"  [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event, modal)"  [imageQuality]="1"
      [allowCameraSwitch]="true"
    ></webcam>
    <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 btn-block" (click)="triggerSnapshot()" >Tirar Foto</button>
        </div>
      </div>
    </div>
  </div>
  </ng-template>

<ngx-spinner></ngx-spinner>


<!--
TODO Tela Servidor:

1. Ícone de foto + método de upload
2. Lógicas de exibição tempo de atendimento
3. Método de 



-->