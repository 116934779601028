import {
  Component,
  ContentChildren,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import { take } from "rxjs/operators";
import * as moment from "moment";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
declare var require: any;
const htmlToPdfmake = require("html-to-pdfmake");
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { Subject, Observable } from "rxjs";
import { WebcamImage, WebcamUtil } from "ngx-webcam";
import { saveAs } from "file-saver";
import * as cep from "cep-promise";

@Component({
  selector: "app-servidor-novo",
  templateUrl: "./servidor-novo.component.html",
  styleUrls: ["./servidor-novo.component.scss"],
})
export class ServidorNovoComponent implements OnInit {
  @ViewChild("comprovante")
  modalComprovante: TemplateRef<any>;

  @ViewChild("modalcomprovante", { static: true }) teste: TemplateRef<any>;

  tiposaposentadoria = [
    { name: "Aposentadoria por Idade", value: 1, group: "Para Civis" },
    {
      name: "Aposentadoria por Tempo de Contribuição",
      value: 2,
      group: "Para Civis",
    },
    {
      name: "Aposentadoria por tempo de contribuição e idade",
      value: 10,
      group: "Para Civis",
    },
    { name: "Aposentadoria Compulsória", value: 3, group: "Para Civis" },
    { name: "Aposentadoria por Invalidez", value: 4, group: "Para Civis" },
    { name: "Aposentadoria como Professor", value: 5, group: "Para Civis" },
    {
      name: "Aposentadoria Especial - atividade de risco (Art. 40, § 4o, inc. II, CF)",
      value: 6,
      group: "Para Civis",
    },
    {
      name: "Aposentadoria Especial - atividade prejudiciais à saúde ou integridade física (Art. 40, § 4o, inc. III, CF)",
      value: 7,
      group: "Para Civis",
    },
    {
      name: "Militares Inativos - Reserva Remunerada",
      value: 8,
      group: "Militares Inativos",
    },
    {
      name: "Militares Inativos - Reforma",
      value: 9,
      group: "Militares Inativos",
    },
  ];
  tipospensao = [
    { name: "Vitalícia", value: 1 },
    { name: "Temporária", value: 2 },
  ];
  sexos = [
    {
      id: "M",
      name: "Masculino",
    },
    {
      id: "F",
      name: "Feminino",
    },
  ];
  naturalizados = [
    {
      id: 1,
      name: "Sim",
    },
    {
      id: 2,
      name: "Não",
    },
  ];

  statusServidor = [
    {
      id: 1,
      name: "Ativo",
    },
    {
      id: 2,
      name: "Aposentado",
    },
    {
      id: 3,
      name: "Pensionista",
    },
    {
      id: 4,
      name: "Exonerado",
    },
    {
      id: 5,
      name: "Falecido",
    },
  ];
  tipoFundo = [
    "Fundo em Capitalização",
    "Fundo em Repartição",
    "Mantidos pelo Tesouro",
  ];
  tipoVinculo = ["Civil", "Militar"];
  relacaoTitularPensao = [
    {
      id: 1,
      name: "Cônjuge",
    },
    {
      id: 2,
      name: "Filho menor de 21 anos",
    },
    {
      id: 3,
      name: "Filho inválido ou com deficiência",
    },
    {
      id: 4,
      name: "Pais",
    },
    {
      id: 5,
      name: "Irmão",
    },
    {
      id: 6,
      name: "Outros",
    },
  ];

  orgaosExpedidores = [];

  referencias = [
    "Certidão de Nascimento (no caso de dependente)",
    "Certidão de Casamento (no caso de dependente)",
    "Certidão de óbito (no caso de pensionista)",
    "Termo de Posse",
    "Portaria de nomeação",
    "Concessão de aposentadoria",
    "Comprovante de Endereço",
    "Carteira de Trabalho ",
    "PIS/PASEP",
    "Carteira de Motorista (CNH)",
    "Carteira Identidade (RG)",
    "CPF",
    "Documento Dependente",
    "NIT",
    "Extrato Tempo de Serviço Passado",
    "Outros",
  ];
  anexo = {
    referencia: null,
    arquivo: null,
  };

  apiCall = this.api.new().silent();
  estados = [];
  cidades = [];
  orgaos = [];
  estadosCivis = [];
  agenda = "";
  oldStatus;
  escolaridades = [];
  servidor;
  idServidor;
  idCidade;
  urlToShow = null;
  hasDadosBancarios = false;
  isPrincipalDataVisible = false;
  isAttachmentsDataVisible = false;
  isDependentsDataVisible = false;
  anexos = [];
  dependentes = [];
  tiposDependencia = [];
  checkEndereco = false;
  checkContatos = false;
  cidadesNaturalidade = [];
  tiposLogradouro = [];
  motivosInicio = [];

  dependente = {
    id: null,
    id_servidor: null,
    nome: null,
    data_nascimento: null,
    estado_civil: null,
    sexo: null,
    estado_naturalidade: null,
    cidade_naturalidade: null,
    nacionalidade: null,
    naturalizado: null,
    escolaridade: null,
    nome_mae: null,
    nome_pai: null,
    deficiente: null,
    cpf: null,
    rg: null,
    data_emissao_rg: null,
    orgao_expedidor_rg: null,
    uf_rg: null,
    pis_pasep: null,
    numero_certidao: null,
    livro: null,
    folha: null,
    tipo_logradouro: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    estado: null,
    cidade: null,
    cep: null,
    telefone: null,
    celular: null,
    email: null,
    tipo_dependencia: null,
    motivo_inicio: null,
    motivo_fim: null,
    inicio_dependencia: null,
    fim_dependencia: null,
    fins_previdenciarios: null,
  };
  //Dados novos

  isVisible = {
    dados: false,
    documentos: false,
    dependentes: false,
  };
  //TODO
  tempoAtendimento = "04:32:55";
  statusAgendamento = "Reunião Agendada";

  isEditingBasicInfos: boolean = false;
  isEditingPersonalData: boolean = false;
  isEditingObservations: boolean = false;
  isEditingObito: boolean = false;
  isEditingRepresentante: boolean = false;

  clonedServidor = [];

  endereco = {
    cep: null,
    tipo_logradouro: null,
    logradouro: null,
    numero: null,
    id: null,
    id_servidor: null,
    complemento: null,
    bairro: null,
    estado: null,
    cidade: null,
  };
  contato = {
    id: null,
    id_servidor: null,
    telefone: null,
    celular: null,
    email: null,
  };
  documentos = {
    cpf: null,
    cpts: null,
    data_emissao_cpts: "",
    data_emissao_rg: "",
    id: null,
    id_servidor: null,
    nit: null,
    orgao_expedidor_rg: null,
    rg: null,
    secao_titulo_eleitor: null,
    serie_cpts: null,
    titulo_eleitor: null,
    uf_rg: null,
    uf_titulo_eleitor: null,
    zona_titulo_eleitor: null,
  };
  obito = {
    id: null,
    id_servidor: null,
    numero_termo: null,
    numero_folha: null,
    numero_livro: null,
    data_emissao: null,
    nome_cartorio: null,
    cnpj_cartorio: null,
    tipo_logradouro_cartorio: null,
    logradouro_cartorio: null,
    numero_cartorio: null,
    complemento_cartorio: null,
    bairro_cartorio: null,
    cep_cartorio: null,
    estado_cartorio: null,
    cidade_cartorio: null,
  };
  representante = {
    id: null,
    id_servidor: null,
    tipo_representatividade: null,
    data_inicio: null,
    data_fim: null,
    nome_representante: null,
    cpf_representante: null,
    rg_representante: null,
    orgao_expedidor_rg_representante: null,
    data_emissao_rg_representante: null,
    uf_rg_representante: null,
    data_nascimento_representante: null,
    sexo_representante: null,
    tipo_logradouro_representante: null,
    logradouro_representante: null,
    numero_representante: null,
    complemento_representante: null,
    bairro_representante: null,
    cep_representante: null,
    estado_representante: null,
    cidade_representante: null,
    telefone_representante: null,
    celular_representante: null,
    email_representante: null,
  };
  clonedDocumentos: any;
  clonedContato: any;
  clonedEndereco: any;
  clonedObito: any;
  clonedRepresentante: any;

  tiposRepresentatividade = [];

  statusOriginal: string = ""; // Guardar o valor original
  mostrarBotaoAtualizar: boolean = false; // Controla a exibição do botão

  statusAgenda = [
    { id: 1, descricao: "Reunião Não Agendada" },
    { id: 2, descricao: "Reunião Agendada" },
    { id: 3, descricao: "Reunião Realizada" },
  ];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {}

  async ngOnInit() {
    const self = this;
    self.estados = [];
    self.cidades = [];
    self.orgaos = [];
    self.estadosCivis = [];
    self.escolaridades = [];
    self.servidor = null;
    self.idServidor = null;
    this.mostrarBotaoAtualizar = false;
    this.route.queryParams.subscribe((parametros) => {
      if (parametros["id"]) {
        this.idServidor = parametros["id"];
      }
    });
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    self.idCidade = json.user.cidade;
    const estados = await self.apiCall
      .get("enderecos/estados")
      .pipe(take(1))
      .toPromise();
    self.estados = JSON.parse(JSON.stringify(estados));
    const escolaridades = await self.apiCall
      .get("servidor/escolaridade")
      .pipe(take(1))
      .toPromise();
    self.escolaridades = JSON.parse(JSON.stringify(escolaridades));
    const estadosCivis = await self.apiCall
      .get("servidor/estadocivil")
      .pipe(take(1))
      .toPromise();
    self.estadosCivis = JSON.parse(JSON.stringify(estadosCivis));
    const servidor = await self.apiCall
      .get(`servidor?id=${self.idServidor}&id_cidade=${json.user.cidade}`)
      .pipe(take(1))
      .toPromise();
    self.servidor = JSON.parse(JSON.stringify(servidor));
    self.oldStatus = servidor.status;
    const cidades = await this.apiCall
      .get(
        "enderecos/estados/cidades?estado=" + self.servidor.estado_naturalidade
      )
      .pipe(take(1))
      .toPromise();
    self.cidades = JSON.parse(JSON.stringify(cidades));
    const bancario = await self.apiCall
      .get(`servidor/dadosbancarios?id_servidor=${self.idServidor}`)
      .pipe(take(1))
      .toPromise();
    if (bancario) self.hasDadosBancarios = true;
    const orgaos = await self.apiCall
      .get("enderecos/estados/cidades/orgaos/todos?cidade=" + json.user.cidade)
      .pipe(take(1))
      .toPromise();
    self.orgaos = JSON.parse(JSON.stringify(orgaos));
    const orgaosexpedidores = await self.apiCall
      .get("servidor/orgaosexpedidores")
      .pipe(take(1))
      .toPromise();
    self.orgaosExpedidores = JSON.parse(JSON.stringify(orgaosexpedidores));
    this.servidor.agenda = this.statusAgenda.find(
      (item) => item.descricao === servidor.agenda
    )?.id;
    self.spinner.hide();
    if (self.servidor.cidade_naturalidade)
      self.servidor.cidade_naturalidade = +self.servidor.cidade_naturalidade;
    if (
      this.servidor.valor_aposentadoria &&
      this.servidor.valor_aposentadoria.toFixed(2)
    )
      this.servidor.valor_aposentadoria = this.servidor.valor_aposentadoria
        .toFixed(2)
        .toString()
        .replace(".", ",");
    if (
      this.servidor.valor_beneficio &&
      this.servidor.valor_beneficio.toFixed(2)
    )
      this.servidor.valor_beneficio = this.servidor.valor_beneficio
        .toFixed(2)
        .toString()
        .replace(".", ",");
    if (
      this.servidor.data_nascimento &&
      moment(this.servidor.data_nascimento, "DDMMYYYY")
    )
      this.servidor.idade = moment().diff(
        moment(this.servidor.data_nascimento, "DDMMYYYY"),
        "years"
      );
    if (
      this.servidor.data_ingresso_servico_publico &&
      moment(this.servidor.data_ingresso_servico_publico)
    )
      this.servidor.data_ingresso_servico_publico = moment(
        this.servidor.data_ingresso_servico_publico
      ).format("DD/MM/YYYY");
    if (this.servidor.inicio_atendimento && this.servidor.fim_atendimento)
      this.servidor.atendimento = moment
        .utc(
          moment(this.servidor.fim_atendimento).diff(
            moment(this.servidor.inicio_atendimento)
          )
        )
        .format("HH:mm:ss");
    self.servidor.motivo_exoneracao = this.servidor.motivo_exoneracao;

    const anexos = await self.apiCall
      .get(`servidor/arquivo/todos?id_servidor=${this.idServidor}`)
      .pipe(take(1))
      .toPromise();
    if (anexos) self.anexos = JSON.parse(JSON.stringify(anexos));

    const tiposDependencia = await self.apiCall
      .get("servidor/tiposdependencia")
      .pipe(take(1))
      .toPromise();
    if (tiposDependencia)
      self.tiposDependencia = JSON.parse(JSON.stringify(tiposDependencia));

    const dependentes = await self.apiCall
      .get(`servidor/dependente/todos?id_servidor=${self.idServidor}`)
      .pipe(take(1))
      .toPromise();
    if (dependentes) {
      self.dependentes = JSON.parse(JSON.stringify(dependentes));

      for (let dependente of self.dependentes) {
        dependente.idade = moment().diff(
          moment(dependente.data_nascimento),
          "years"
        );
        dependente.labelRelacao = self.tiposDependencia.find(
          (item) => item.id === dependente.tipo_dependencia
        ) ? self.tiposDependencia.find((item) => item.id === dependente.tipo_dependencia).descricao : null;
      }
    }

    const tiposLogradouro = await self.apiCall
      .get("enderecos/tiposLogradouro")
      .pipe(take(1))
      .toPromise();
    if (tiposLogradouro) {
      self.tiposLogradouro = JSON.parse(JSON.stringify(tiposLogradouro));
    }

    const motivosInicio = await self.apiCall
      .get("servidor/motivosiniciodependencia")
      .pipe(take(1))
      .toPromise();
    if (motivosInicio) {
      self.motivosInicio = JSON.parse(JSON.stringify(motivosInicio));
    }

    const documento = await self.apiCall
      .get(`servidor/documentos?id_servidor=${this.idServidor}`)
      .pipe(take(1))
      .toPromise();
    if (documento) self.documentos = JSON.parse(JSON.stringify(documento));

    const contato = await self.apiCall
      .get(`servidor/contatos?id_servidor=${self.idServidor}`)
      .pipe(take(1))
      .toPromise();
    if (contato) self.contato = JSON.parse(JSON.stringify(contato));
    self.spinner.hide();

    const endereco = await self.apiCall
      .get(`servidor/endereco?id_servidor=${this.idServidor}`)
      .pipe(take(1))
      .toPromise();

    if (endereco) self.endereco = JSON.parse(JSON.stringify(endereco));
    if (self.endereco.cidade) self.endereco.cidade = +self.endereco.cidade;

    const obito = await self.apiCall
      .get(`servidor/obito?id_servidor=${this.idServidor}`)
      .pipe(take(1))
      .toPromise();
    if (obito) self.obito = JSON.parse(JSON.stringify(obito));
    if (self.obito.estado_cartorio) await self.toggleEstado();
    if (self.obito.cidade_cartorio)
      self.obito.cidade_cartorio = +self.obito.cidade_cartorio;

    const tiposRepresentatividade = await self.apiCall
      .get("servidor/tiposrepresentatividade")
      .pipe(take(1))
      .toPromise();
    self.tiposRepresentatividade = JSON.parse(
      JSON.stringify(tiposRepresentatividade)
    );

    const representante = await self.apiCall
      .get(`servidor/representantelegal?id_servidor=${this.idServidor}`)
      .pipe(take(1))
      .toPromise();
    if (representante)
      self.representante = JSON.parse(JSON.stringify(representante));
    if (self.representante.estado_representante) await self.toggleEstado();
    if (self.representante.cidade_representante)
      self.representante.cidade_representante =
        +self.representante.cidade_representante;

    this.statusOriginal = this.servidor.agenda;
  }

  updateAgendaStatus(value) {
    this.mostrarBotaoAtualizar = value.id != this.statusOriginal;
    console.log("this.mostrarBotaoAtualizar", this.mostrarBotaoAtualizar);
    console.log("statusOriginal", this.statusOriginal);
    console.log("value.id", value.id);
    this.servidor.agenda = this.statusAgenda.find(
      (item) => item.descricao === value.descricao
    )?.id;
  }
  async toggleEstado() {
    this.cidades = [];
    const cidades = await this.apiCall
      .get(
        "enderecos/estados/cidades?estado=" + this.servidor.estado_naturalidade
      )
      .pipe(take(1))
      .toPromise();
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }

  async toggleEstadoCep(form) {
    const self = this;

    let estadoToConsult = null;
    if (form == "servidor") {
      estadoToConsult = self.endereco.estado;
    } else if (form == "representante") {
      estadoToConsult = self.representante.estado_representante;
    } else if (form == "obito") {
      estadoToConsult = self.obito.estado_cartorio;
    }

    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.cidades = [];
    const cidades = await this.apiCall
      .get("enderecos/estados/cidades?estado=" + estadoToConsult)
      .pipe(take(1))
      .toPromise();
    this.cidades = JSON.parse(JSON.stringify(cidades));
    self.spinner.hide();
  }
  async postFile(extension, type) {
    const response = await this.apiCall
      .post(`servidor/arquivo/url`, { extension: extension, type: type })
      .pipe(take(1))
      .toPromise();
    if (response.result) {
      return response.result;
    } else {
      return null;
    }
  }

  public downloadAsPDF() {
    const modalComprovante = this.modalComprovante.elementRef.nativeElement;

    var html = htmlToPdfmake(modalComprovante.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download();
  }

  multipleNavigate(content) {
    this.modalService.open(content, { centered: true });
  }
  multipleWebcamsAvailable = false;
  openModalCamera(content) {
    const slef = this;
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        this.modalService.open(content, { centered: true });
      }
    );
  }
  openModal(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }
  openModalLg(content) {
    const modal = this.modalService.open(content, {
      centered: true,
      size: "lg",
    });
  }

  openModalSm(content) {
    const modal = this.modalService.open(content, {
      centered: true,
      size: "sm",
    });
  }
  fileNameAndExt(str) {
    var file = str.split("/").pop();
    return [
      file.substr(0, file.lastIndexOf(".")),
      file.substr(file.lastIndexOf(".") + 1, file.length),
    ];
  }
  viewMidia(urlToShow, content) {
    this.urlToShow = urlToShow;
    this.modalService.open(content, { centered: true });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }

  async uploadImage(event) {
    console.log("veiooo");
    const self = this;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (file.size > 2000000) {
        await this.app.alert(
          `Atenção`,
          `o tamanho do arquivo não pode ser maior que 2mb!`,
          `error`
        );
        return;
      }
      if (file && self.fileNameAndExt(file.name)[1]) {
        try {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          const urlToPostFile = await self.postFile(
            self.fileNameAndExt(file.name)[1],
            file.type
          );

          if (!urlToPostFile) {
            return;
          }

          var optionsFile = {
            headers: { "Content-Type": file.type, "x-amz-acl": "public-read" },
          };

          const uploadFile = await self.api.http
            .put(urlToPostFile, file, optionsFile)
            .toPromise();

          const locationFile = urlToPostFile.toString().split("?", 1)[0];

          if (locationFile) {
            self.servidor.imagem = locationFile;
            self.editarServidor();
          }
          console.log(locationFile);
          self.spinner.hide();
        } catch (e) {
          self.spinner.hide();
        }
      }
    };
    reader.onabort = () => {};
    reader.onerror = () => {};
  }

  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: WebcamImage = null;
  public triggerSnapshot(): void {
    this.trigger.next();
  }
  async handleImage(webcamImage: WebcamImage, modal) {
    const self = this;
    let file = this.dataURItoBlob(webcamImage.imageAsBase64);
    modal.close("Close click");
    this.webcamImage = webcamImage;
    try {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const urlToPostFile = await self.postFile("jpeg", "image/jpeg");

      if (!urlToPostFile) {
        return;
      }

      var optionsFile = {
        headers: { "Content-Type": "image/jpeg", "x-amz-acl": "public-read" },
      };

      const uploadFile = await self.api.http
        .put(urlToPostFile, file, optionsFile)
        .toPromise();

      const locationFile = urlToPostFile.toString().split("?", 1)[0];

      if (locationFile) {
        self.servidor.imagem = locationFile;
        self.editarServidor();
      }
      console.log(locationFile);
      self.spinner.hide();
    } catch (e) {
      self.spinner.hide();
    }
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  disableServidor() {
    const self = this;
    if (self.servidor.status && self.servidor.status == 1) {
      if (
        !self.servidor.nome ||
        !self.servidor.status ||
        !self.servidor.deficiente ||
        !self.servidor.escolaridade ||
        !self.servidor.nome_mae ||
        !self.servidor.nome_pai ||
        !self.servidor.naturalizado ||
        !self.servidor.nacionalidade ||
        !self.servidor.cidade_naturalidade ||
        !self.servidor.estado_civil ||
        !self.servidor.sexo ||
        !self.servidor.estado_naturalidade ||
        !self.servidor.data_nascimento ||
        !self.servidor.orgao
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (self.servidor.status && self.servidor.status == 2) {
      if (
        !self.servidor.nome ||
        !self.servidor.status ||
        !self.servidor.deficiente ||
        !self.servidor.escolaridade ||
        !self.servidor.nome_mae ||
        !self.servidor.nome_pai ||
        !self.servidor.naturalizado ||
        !self.servidor.nacionalidade ||
        !self.servidor.cidade_naturalidade ||
        //!self.servidor.orgao ||
        !self.servidor.estado_civil ||
        !self.servidor.sexo ||
        !self.servidor.estado_naturalidade ||
        !self.servidor.data_nascimento ||
        !self.servidor.orgao
      ) {
        return true;
      } else {
        return false;
      }
    } else if (self.servidor.status) {
      if (
        (self.servidor.status == 3 && !self.servidor.cpf_pensao) ||
        (self.servidor.status == 3 && !self.servidor.nome_pensao) ||
        //(self.servidor.status==3 && !self.servidor.orgao) ||
        !self.servidor.nome ||
        !self.servidor.status ||
        !self.servidor.deficiente ||
        !self.servidor.escolaridade ||
        !self.servidor.nome_mae ||
        !self.servidor.nome_pai ||
        !self.servidor.naturalizado ||
        !self.servidor.nacionalidade ||
        !self.servidor.cidade_naturalidade ||
        !self.servidor.estado_civil ||
        !self.servidor.sexo ||
        !self.servidor.estado_naturalidade ||
        !self.servidor.data_nascimento
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  async editarServidor() {
    const self = this;

    if (!self.servidor || !self.servidor.id) {
      return;
    }

    // Verificação adicional para status falecido e data de falecimento nula
    if (self.servidor.status === 5 && !self.servidor.data_falecimento) {
      await this.app.alert(
        "Erro",
        "Data de falecimento deve ser preenchida para servidores falecidos.",
        "error"
      );
      return;
    }

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (!self.servidor.imagem) self.servidor.imagem = "";
    if (!self.servidor.pendencias || self.servidor.pendencias == "null")
      self.servidor.pendencias = "";
    if (!self.servidor.observacoes || self.servidor.observacoes == "null")
      self.servidor.observacoes = "";

    this.servidor.data_nascimento = this.preparaData(
      this.servidor.data_nascimento
    );
    this.servidor.data_falecimento = this.preparaData(
      this.servidor.data_falecimento
    );
    let data_ingresso_servico_publico = moment(
      this.servidor.data_ingresso_servico_publico,
      "DDMMYYYY"
    ).toISOString();
    this.servidor.concessao_aposentadoria = this.preparaData(
      this.servidor.concessao_aposentadoria
    );

    if (this.servidor.valor_aposentadoria)
      this.servidor.valor_aposentadoria = this.servidor.valor_aposentadoria
        .toString()
        .replace(".", "")
        .replace(",", ".");
    if (this.servidor.valor_beneficio)
      this.servidor.valor_beneficio = this.servidor.valor_beneficio
        .toString()
        .replace(".", "")
        .replace(",", ".");

    this.servidor.data_falecimento_pensao = this.preparaData(
      this.servidor.data_falecimento_pensao
    );
    this.servidor.data_nascimento_pensao = this.preparaData(
      this.servidor.data_nascimento_pensao
    );
    this.servidor.agenda = this.statusAgenda.find(
      (item) => item.id === this.servidor.agenda
    )?.descricao;

    await this.apiCall
      .get(
        `servidor/editar?id=${self.servidor.id}&nome=${this.validateQuotes(
          self.servidor.nome
        )}&estado_civil=${self.servidor.estado_civil}&pendencias=${
          self.servidor.pendencias
        }&observacoes=${self.servidor.observacoes}&motivo_exoneracao=${
          self.servidor.motivo_exoneracao
        }&status=${self.servidor.status}&data_nascimento=${
          self.servidor.data_nascimento
        }&data_falecimento=${self.servidor.data_falecimento}&nacionalidade=${
          self.servidor.nacionalidade
        }&estado_naturalidade=${
          self.servidor.estado_naturalidade
        }&cidade_naturalidade=${
          self.servidor.cidade_naturalidade
        }&naturalizado=${self.servidor.naturalizado}&escolaridade=${
          self.servidor.escolaridade
        }&sexo=${self.servidor.sexo}&nome_mae=${this.validateQuotes(
          self.servidor.nome_mae
        )}&nome_pai=${this.validateQuotes(
          self.servidor.nome_pai
        )}&data_ingresso_servico_publico=${data_ingresso_servico_publico}&deficiente=${
          self.servidor.deficiente
        }&imagem=${self.servidor.imagem}&documento_ingresso_servico_publico=${
          self.servidor.documento_ingresso_servico_publico
        }&paridade_aposentadoria=${
          self.servidor.paridade_aposentadoria
        }&concessao_aposentadoria=${
          self.servidor.concessao_aposentadoria
        }&valor_aposentadoria=${
          self.servidor.valor_aposentadoria
        }&cargo_aposentadoria=${
          self.servidor.cargo_aposentadoria
        }&pis_aposentado=${self.servidor.pis_aposentado}&tipo_aposentadoria=${
          self.servidor.tipo_aposentadoria
        }&cpf_pensao=${self.servidor.cpf_pensao}&pis_pensao=${
          self.servidor.pis_pensao
        }&nome_pensao=${self.servidor.nome_pensao}&matricula_pensao=${
          self.servidor.matricula_pensao
        }&data_nascimento_pensao=${
          self.servidor.data_nascimento_pensao
        }&data_falecimento_pensao=${
          self.servidor.data_falecimento_pensao
        }&matricula_beneficiario=${
          self.servidor.matricula_beneficiario
        }&tipo_pensao=${self.servidor.tipo_pensao}&valor_beneficio=${
          self.servidor.valor_beneficio
        }&tipo_fundo=${self.servidor.tipo_fundo}&tipo_vinculo=${
          self.servidor.tipo_vinculo
        }&relacao_titular_pensao=${
          self.servidor.relacao_titular_pensao
        }&orgao=${self.servidor.orgao}&agenda=${
          self.servidor.agenda
        }&oldStatus=${self.oldStatus}`
      )
      .pipe(take(1))
      .toPromise();

    self.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      "Servidor atualizado com sucesso!",
      "success"
    );
    await self.ngOnInit();
  }
  navigateTo(path) {
    this.router.navigate([path], {
      queryParams: { id: this.idServidor, idCidade: this.idCidade },
    });
  }
  navigateToTempos(tipo) {
    this.router.navigate(["page/servidor/tempos"], {
      queryParams: { id: this.idServidor, idCidade: this.idCidade, tipo: tipo },
    });
  }
  async iniciarAtendimento() {
    const self = this;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    await this.apiCall
      .get(
        `servidor/atendimento/inicio?id_servidor=${
          self.servidor.id
        }&inicio_atendimento=${moment().format("YYYY-MM-DD HH:mm:ss")}`
      )
      .pipe(take(1))
      .toPromise();
    self.spinner.hide();
    await this.app.alert("Tudo certo!", "atendimento iniciado!", "success");
    await self.ngOnInit();
    self.spinner.hide();
  }
  async reiniciarAtendimento() {
    const self = this;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    await this.apiCall
      .get(`servidor/atendimento/reinicio?id_servidor=${self.servidor.id}`)
      .pipe(take(1))
      .toPromise();
    self.spinner.hide();
    await this.app.alert("Tudo certo!", "atendimento reiniciado!", "success");
    await self.ngOnInit();
    self.spinner.hide();
  }
  async finalizarAtendimento() {
    const self = this;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    await this.apiCall
      .get(
        `servidor/atendimento/fim?id_servidor=${
          self.servidor.id
        }&fim_atendimento=${moment().format("YYYY-MM-DD HH:mm:ss")}`
      )
      .pipe(take(1))
      .toPromise();
    self.spinner.hide();
    await this.app.alert("Tudo certo!", "atendimento finalizado!", "success");
    await self.ngOnInit();
    self.spinner.hide();
  }
  timer = null;

  beautifulDate(data) {
    return moment(data).format("HH[h]-mm[m]-ss[s do dia ]DD-MM-YYYY");
  }
  getYear(data) {
    return moment(data).format("YYYY");
  }
  preparaData(data) {
    if (data) {
      return moment(data, "DDMMYYYY").format("YYYY-MM-DD");
    } else {
      data = "";
    }
    return data;
  }
  getTotalDaysFromDateToToday(fromDate) {
    console.log("servidor.data_ingresso_servico_publico", fromDate);

    if (!moment(fromDate, "DD/MM/YYYY", true).isValid()) {
      console.error("Invalid date format. Expected format: DD/MM/YYYY");
      return 0;
    }

    const today = moment();
    const fromDateMoment = moment(fromDate, "DD/MM/YYYY");
    return today.diff(fromDateMoment, "days");
  }
  validateQuotes(str) {
    if (str) {
      return str.replace("'", "'");
    } else {
      return "";
    }
  }

  //Metodos novos

  goBack() {
    window.history.back();
  }

  toggleSection(section: string) {
    this.isVisible[section] = !this.isVisible[section];
  }

  togglePrincipalData() {
    this.isPrincipalDataVisible = !this.isPrincipalDataVisible;
  }

  toggleDependentsData() {
    this.isDependentsDataVisible = !this.isDependentsDataVisible;
  }

  toggleAttachmentsData() {
    this.isAttachmentsDataVisible = !this.isAttachmentsDataVisible;
  }

  async deleteAttachment(chave, id_arquivo) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este anexo?`,
        `Deletar`
      )
    ) {
      self.apiCall
        .get(`servidor/arquivo/deletar?chave=${chave}&id_arquivo=${id_arquivo}`)
        .subscribe(async (response) => {
          await self.app.alert(`Sucesso`, `Anexo deletado!`, `success`);
          await self.spinner.hide();
          await self.ngOnInit();
        });
    }
  }

  async downloadAttachment(chave, nome) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const url = await self.apiCall
      .get(`banco-dados/files/url`)
      .pipe(take(1))
      .toPromise();
    if (url) saveAs(url + chave, nome);
    self.spinner.hide();
  }

  beautifulDateSimple(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  async selecionaArquivo(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      if (file.size > 5000000) {
        event.target.value = null;
        await this.app.alert(
          `Atenção`,
          `o tamanho do arquivo não pode ser maior que 5mb!`,
          `error`
        );
      } else {
        this.anexo.arquivo = file;
      }
    }
  }

  async cadastrarAnexo(modal) {
    const self = this;
    console.log(self.anexo);
    const formData = new FormData();
    formData.append("file", self.anexo.arquivo);
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.nomeCidade;
    const nome = json.user.nome;

    const anexoEnviado = await self.apiCall
      .post(
        `servidor/arquivo/novo?id_servidor=${self.idServidor}&referencia=${self.anexo.referencia}&cidade=${cidade}&nome_servidor=${nome}`,
        formData
      )
      .pipe(take(1))
      .toPromise();
    await self.app.alert(`Sucesso`, `Anexo cadastrado!`, `success`);
    modal.close("Close click");
    await self.spinner.hide();
    await self.ngOnInit();
  }
  async deletarDependente(id) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este dependente?`,
        `Deletar`
      )
    ) {
      const deleteDependente = await self.apiCall
        .get(
          `servidor/dependente/deletar?id_dependente=${id}&id_servidor=${self.idServidor}`
        )
        .pipe(take(1))
        .toPromise();
      console.log(deleteDependente);
      await self.app.alert(`Sucesso`, `Dependente deletado!`, `success`);
      await self.spinner.hide();
      await self.ngOnInit();
    }
  }

  resetaDependente() {
    this.dependente = {
      id: null,
      id_servidor: null,
      nome: null,
      data_nascimento: null,
      estado_civil: null,
      sexo: null,
      estado_naturalidade: null,
      cidade_naturalidade: null,
      nacionalidade: null,
      naturalizado: null,
      escolaridade: null,
      nome_mae: null,
      nome_pai: null,
      deficiente: null,
      cpf: null,
      rg: null,
      data_emissao_rg: null,
      orgao_expedidor_rg: null,
      uf_rg: null,
      pis_pasep: null,
      numero_certidao: null,
      livro: null,
      folha: null,
      tipo_logradouro: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      estado: null,
      cidade: null,
      cep: null,
      telefone: null,
      celular: null,
      email: null,
      tipo_dependencia: null,
      motivo_inicio: null,
      motivo_fim: null,
      inicio_dependencia: null,
      fim_dependencia: null,
      fins_previdenciarios: null,
    };
  }

  async openModalDependent(content, dependente) {
    if (dependente) {
      this.dependente = { ...dependente };
      if (dependente.data_emissao_rg)
        this.dependente.data_emissao_rg = moment
          .utc(dependente.data_emissao_rg)
          .format("DDMMYYYY");
      if (dependente.data_nascimento)
        this.dependente.data_nascimento = moment
          .utc(dependente.data_nascimento)
          .format("DDMMYYYY");
      if (dependente.estado_naturalidade) {
        const cidades = await this.apiCall
          .get(
            "enderecos/estados/cidades?estado=" + dependente.estado_naturalidade
          )
          .pipe(take(1))
          .toPromise();
        this.cidadesNaturalidade = JSON.parse(JSON.stringify(cidades));
        if (dependente.cidade_naturalidade)
          this.dependente.cidade_naturalidade = +dependente.cidade_naturalidade;
      }
      if (dependente.estado) {
        const cidades = await this.apiCall
          .get("enderecos/estados/cidades?estado=" + dependente.estado)
          .pipe(take(1))
          .toPromise();
        this.cidades = JSON.parse(JSON.stringify(cidades));
        if (dependente.cidade) this.dependente.cidade = +dependente.cidade;
      }
    } else {
      this.resetaDependente();
      this.cidadesNaturalidade = [];
      this.cidades = [];
    }
    this.checkContatos = false;
    this.checkEndereco = false;
    const modal = this.modalService.open(content, {
      centered: true,
      size: "lg",
    });
  }

  async toggleEstadoDependente(naturalidade) {
    const self = this;
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (naturalidade && !this.dependente.estado_naturalidade) {
      return;
    }
    if (!naturalidade && !this.dependente.estado) {
      return;
    }

    if (naturalidade) {
      this.cidadesNaturalidade = [];
      this.dependente.cidade_naturalidade = null;
      const cidades = await this.apiCall
        .get(
          "enderecos/estados/cidades?estado=" +
            this.dependente.estado_naturalidade
        )
        .pipe(take(1))
        .toPromise();
      this.cidadesNaturalidade = JSON.parse(JSON.stringify(cidades));
    } else {
      this.cidades = [];
      this.dependente.cidade = null;
      const cidades = await this.apiCall
        .get("enderecos/estados/cidades?estado=" + this.dependente.estado)
        .pipe(take(1))
        .toPromise();
      this.cidades = JSON.parse(JSON.stringify(cidades));
      console.log("this.cidades", this.cidades);
    }
    await self.spinner.hide();
  }
  async copiaContatos($event) {
    const self = this;
    this.checkContatos = !this.checkContatos;
    if (this.checkContatos) {
      const contato = await self.apiCall
        .get("servidor/contatos?id_servidor=" + self.idServidor)
        .pipe(take(1))
        .toPromise();
      if (contato) {
        self.dependente.telefone = contato.telefone;
        self.dependente.celular = contato.celular;
        self.dependente.email = contato.email;
      }
    }
  }
  async copiaEndereco($event) {
    const self = this;
    this.checkEndereco = !this.checkEndereco;
    if (this.checkEndereco) {
      const endereco = await self.apiCall
        .get("servidor/endereco?id_servidor=" + self.idServidor)
        .pipe(take(1))
        .toPromise();

      if (endereco) {
        const cidades = await self.apiCall
          .get("enderecos/estados/cidades?estado=" + endereco.estado)
          .pipe(take(1))
          .toPromise();
        self.cidades = cidades;
        self.dependente.tipo_logradouro = endereco.tipo_logradouro;
        self.dependente.logradouro = endereco.logradouro;
        self.dependente.numero = endereco.numero;
        self.dependente.complemento = endereco.complemento;
        self.dependente.bairro = endereco.bairro;
        self.dependente.estado = endereco.estado;
        self.dependente.cidade = +endereco.cidade;
        self.dependente.cep = endereco.cep;
      }
    }
  }
  consultaCep(form = null) {
    const self = this;
    console.log(form);
    if (
      !self.dependente.cep &&
      !self.obito.cep_cartorio &&
      !self.representante.cep_representante &&
      !self.endereco.cep
    ) {
      return;
    }
    let cepToConsult = null;
    let targetObject = null;
    let logradouroField = "logradouro";
    let bairroField = "bairro";
    let estadoField = "estado";
    let cidadeField = "cidade";
    let tipoLogradouroField = "tipo_logradouro";

    if (form == "servidor") {
      cepToConsult = self.endereco.cep;
      targetObject = self.endereco;
    } else if (form == "dependente") {
      cepToConsult = self.dependente.cep;
      targetObject = self.dependente;
    } else if (form == "representante") {
      cepToConsult = self.representante.cep_representante;
      targetObject = self.representante;
      logradouroField = "logradouro_representante";
      bairroField = "bairro_representante";
      estadoField = "estado_representante";
      cidadeField = "cidade_representante";
      tipoLogradouroField = "tipo_logradouro_representante";
    } else if (form == "obito") {
      cepToConsult = self.obito.cep_cartorio;
      targetObject = self.obito;
      logradouroField = "logradouro_cartorio";
      bairroField = "bairro_cartorio";
      estadoField = "estado_cartorio";
      cidadeField = "cidade_cartorio";
      tipoLogradouroField = "tipo_logradouro_cartorio";
    }

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    cep(cepToConsult)
      .then(async (local) => {
        targetObject[logradouroField] = local.street;
        targetObject[bairroField] = local.neighborhood;
        targetObject[estadoField] = local.state;
        targetObject[cidadeField] = null;
        targetObject[tipoLogradouroField] = null;

        if (form == "dependente") {
          await self.toggleEstadoDependente(false);
        } else {
          await self.toggleEstadoCep(form);
        }

        const foundCity = self.cidades.find((c) =>
          c.nome.toLowerCase().includes(local.city.toLowerCase())
        );
        if (foundCity) targetObject[cidadeField] = foundCity.id;

        const foundTipoLogradouro = self.tiposLogradouro.find((t) =>
          t.descricao
            .toLowerCase()
            .includes(local.street.split(" ")[0].toLowerCase())
        );
        if (foundTipoLogradouro)
          targetObject[tipoLogradouroField] = foundTipoLogradouro.id;

        self.spinner.hide();
      })
      .catch((erro) => {
        console.error("Erro ao buscar o CEP:", erro);
        self.spinner.hide();
      });
  }

  toggleEditBasicInfos(revert = false) {
    if (revert == false) {
      this.clonedServidor = JSON.parse(JSON.stringify(this.servidor));
    }

    if (revert == true) {
      this.servidor = JSON.parse(JSON.stringify(this.clonedServidor));
    }
    this.isEditingBasicInfos = !this.isEditingBasicInfos;
  }

  toggleEditObservations(revert = false) {
    if (revert == false) {
      this.clonedServidor = JSON.parse(JSON.stringify(this.servidor));
    }

    if (revert == true) {
      this.servidor = JSON.parse(JSON.stringify(this.clonedServidor));
    }
    this.isEditingObservations = !this.isEditingObservations;
  }

  toggleEditPersonalData(revert = false) {
    if (revert == false) {
      this.clonedDocumentos = JSON.parse(JSON.stringify(this.documentos));
      this.clonedContato = JSON.parse(JSON.stringify(this.contato));
      this.clonedEndereco = JSON.parse(JSON.stringify(this.endereco));
    }

    if (revert == true) {
      this.documentos = JSON.parse(JSON.stringify(this.clonedDocumentos));
      this.contato = JSON.parse(JSON.stringify(this.clonedContato));
      this.endereco = JSON.parse(JSON.stringify(this.clonedEndereco));
    }
    this.isEditingPersonalData = !this.isEditingPersonalData;
  }

  toggleEditObito(revert = false) {
    if (revert == false) {
      this.clonedObito = JSON.parse(JSON.stringify(this.obito));
    }

    if (revert == true) {
      this.obito = JSON.parse(JSON.stringify(this.clonedObito));
    }

    this.isEditingObito = !this.isEditingObito;
  }

  toggleEditRepresentante(revert = false) {
    if (revert == false) {
      this.clonedRepresentante = JSON.parse(JSON.stringify(this.representante));
    }

    if (revert == true) {
      this.representante = JSON.parse(JSON.stringify(this.clonedRepresentante));
    }

    this.isEditingRepresentante = !this.isEditingRepresentante;
  }

  disableDocumentos() {
    const self = this;

    if (self.servidor && self.servidor.status && self.servidor.status == 1) {
      if (
        !self.documentos.cpf ||
        !self.documentos.nit ||
        !self.documentos.uf_rg ||
        !self.documentos.rg ||
        !self.documentos.orgao_expedidor_rg
      ) {
        return true;
      } else {
        return false;
      }
    } else if (self.servidor && self.servidor.status) {
      if (
        !self.documentos.cpf ||
        !self.documentos.nit ||
        !self.documentos.orgao_expedidor_rg ||
        !self.documentos.uf_rg ||
        !self.documentos.rg ||
        !self.documentos.data_emissao_rg
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async atualizarOuCadastrarContato() {
    const self = this;
    if (
      !self.contato ||
      !self.documentos ||
      !self.endereco ||
      !self.idServidor
    ) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (self.contato.id) {
      await self.apiCall
        .get(
          `servidor/contatos/editar?id=${self.contato.id}&telefone=${self.contato.telefone}&celular=${self.contato.celular}&email=${self.contato.email}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/contatos/novo?id_servidor=${self.idServidor}&telefone=${self.contato.telefone}&celular=${self.contato.celular}&email=${self.contato.email}`
        )
        .pipe(take(1))
        .toPromise();
    }

    self.documentos.data_emissao_rg = this.preparaData(
      self.documentos.data_emissao_rg
    );
    self.documentos.data_emissao_cpts = this.preparaData(
      self.documentos.data_emissao_cpts
    );

    if (self.documentos.id) {
      await self.apiCall
        .get(
          `servidor/documentos/editar?id=${self.documentos.id}&cpf=${self.documentos.cpf}&nit=${self.documentos.nit}&rg=${self.documentos.rg}&data_emissao_rg=${self.documentos.data_emissao_rg}&orgao_expedidor_rg=${self.documentos.orgao_expedidor_rg}&uf_rg=${self.documentos.uf_rg}&cpts=${self.documentos.cpts}&serie_cpts=${self.documentos.serie_cpts}&data_emissao_cpts=${self.documentos.data_emissao_cpts}&titulo_eleitor=${self.documentos.titulo_eleitor}&zona_titulo_eleitor=${self.documentos.zona_titulo_eleitor}&secao_titulo_eleitor=${self.documentos.secao_titulo_eleitor}&uf_titulo_eleitor=${self.documentos.uf_titulo_eleitor}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/documentos/novo?id_servidor=${self.idServidor}&cpf=${self.documentos.cpf}&nit=${self.documentos.nit}&rg=${self.documentos.rg}&data_emissao_rg=${self.documentos.data_emissao_rg}&orgao_expedidor_rg=${self.documentos.orgao_expedidor_rg}&uf_rg=${self.documentos.uf_rg}&cpts=${self.documentos.cpts}&serie_cpts=${self.documentos.serie_cpts}&data_emissao_cpts=${self.documentos.data_emissao_cpts}&titulo_eleitor=${self.documentos.titulo_eleitor}&zona_titulo_eleitor=${self.documentos.zona_titulo_eleitor}&secao_titulo_eleitor=${self.documentos.secao_titulo_eleitor}&uf_titulo_eleitor=${self.documentos.uf_titulo_eleitor}`
        )
        .pipe(take(1))
        .toPromise();
    }

    let endereco = { ...self.endereco };
    if (endereco.bairro) endereco.bairro = endereco.bairro.replace(/'/g, "''");
    if (endereco.logradouro)
      endereco.logradouro = endereco.logradouro.replace(/'/g, "''");
    if (endereco.complemento)
      endereco.complemento = endereco.complemento.replace(/'/g, "''");
    if (self.endereco.id) {
      await self.apiCall
        .get(
          `servidor/endereco/editar?id_endereco=${endereco.id}&tipo_logradouro=${endereco.tipo_logradouro}&logradouro=${endereco.logradouro}&numero=${endereco.numero}&complemento=${endereco.complemento}&bairro=${endereco.bairro}&cep=${endereco.cep}&estado=${endereco.estado}&cidade=${endereco.cidade}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/endereco/novo?id_servidor=${self.idServidor}&tipo_logradouro=${endereco.tipo_logradouro}&logradouro=${endereco.logradouro}&numero=${endereco.numero}&complemento=${endereco.complemento}&bairro=${endereco.bairro}&cep=${endereco.cep}&estado=${endereco.estado}&cidade=${endereco.cidade}`
        )
        .pipe(take(1))
        .toPromise();
    }
    await self.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      "Dados pessoais salvos com sucesso!",
      "success"
    );
    await self.ngOnInit();
  }

  async atualizarOuCadastrarObito() {
    const self = this;
    if (!self.obito || !self.idServidor) {
      return;
    }
    self.obito.data_emissao = this.preparaData(self.obito.data_emissao);
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (self.obito.id) {
      await self.apiCall
        .get(
          `servidor/obito/editar?id_obito=${self.obito.id}&numero_termo=${self.obito.numero_termo}&numero_folha=${self.obito.numero_folha}&numero_livro=${self.obito.numero_livro}&data_emissao=${self.obito.data_emissao}&nome_cartorio=${self.obito.nome_cartorio}&cnpj_cartorio=${self.obito.cnpj_cartorio}&tipo_logradouro_cartorio=${self.obito.tipo_logradouro_cartorio}&logradouro_cartorio=${self.obito.logradouro_cartorio}&numero_cartorio=${self.obito.numero_cartorio}&complemento_cartorio=${self.obito.complemento_cartorio}&bairro_cartorio=${self.obito.bairro_cartorio}&cep_cartorio=${self.obito.cep_cartorio}&estado_cartorio=${self.obito.estado_cartorio}&cidade_cartorio=${self.obito.cidade_cartorio}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/obito/novo?id_servidor=${self.idServidor}&numero_termo=${self.obito.numero_termo}&numero_folha=${self.obito.numero_folha}&numero_livro=${self.obito.numero_livro}&data_emissao=${self.obito.data_emissao}&nome_cartorio=${self.obito.nome_cartorio}&cnpj_cartorio=${self.obito.cnpj_cartorio}&tipo_logradouro_cartorio=${self.obito.tipo_logradouro_cartorio}&logradouro_cartorio=${self.obito.logradouro_cartorio}&numero_cartorio=${self.obito.numero_cartorio}&complemento_cartorio=${self.obito.complemento_cartorio}&bairro_cartorio=${self.obito.bairro_cartorio}&cep_cartorio=${self.obito.cep_cartorio}&estado_cartorio=${self.obito.estado_cartorio}&cidade_cartorio=${self.obito.cidade_cartorio}`
        )
        .pipe(take(1))
        .toPromise();
    }
    self.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      self.obito.id
        ? "Óbito atualizado com sucesso!"
        : "Óbito cadastrado com sucesso!",
      "success"
    );
    await self.ngOnInit();
  }

  async atualizarOuCadastrarRepresentante() {
    const self = this;
    if (!self.representante || !self.idServidor) {
      return;
    }
    self.representante.data_inicio = this.preparaData(
      self.representante.data_inicio
    );
    self.representante.data_fim = this.preparaData(self.representante.data_fim);
    self.representante.data_emissao_rg_representante = this.preparaData(
      self.representante.data_emissao_rg_representante
    );
    self.representante.data_nascimento_representante = this.preparaData(
      self.representante.data_nascimento_representante
    );
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (self.representante.id) {
      console.log("vai atualizar", self.representante);
      await self.apiCall
        .get(
          `servidor/representantelegal/editar?id_representante=${self.representante.id}&tipo_representatividade=${self.representante.tipo_representatividade}&data_inicio=${self.representante.data_inicio}&data_fim=${self.representante.data_fim}&nome_representante=${self.representante.nome_representante}&cpf_representante=${self.representante.cpf_representante}&rg_representante=${self.representante.rg_representante}&orgao_expedidor_rg_representante=${self.representante.orgao_expedidor_rg_representante}&data_emissao_rg_representante=${self.representante.data_emissao_rg_representante}&uf_rg_representante=${self.representante.uf_rg_representante}&data_nascimento_representante=${self.representante.data_nascimento_representante}&sexo_representante=${self.representante.sexo_representante}&tipo_logradouro_representante=${self.representante.tipo_logradouro_representante}&logradouro_representante=${self.representante.logradouro_representante}&numero_representante=${self.representante.numero_representante}&complemento_representante=${self.representante.complemento_representante}&bairro_representante=${self.representante.bairro_representante}&cep_representante=${self.representante.cep_representante}&estado_representante=${self.representante.estado_representante}&cidade_representante=${self.representante.cidade_representante}&telefone_representante=${self.representante.telefone_representante}&celular_representante=${self.representante.celular_representante}&email_representante=${self.representante.email_representante}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/representantelegal/novo?id_servidor=${self.idServidor}&tipo_representatividade=${self.representante.tipo_representatividade}&data_inicio=${self.representante.data_inicio}&data_fim=${self.representante.data_fim}&nome_representante=${self.representante.nome_representante}&cpf_representante=${self.representante.cpf_representante}&rg_representante=${self.representante.rg_representante}&orgao_expedidor_rg_representante=${self.representante.orgao_expedidor_rg_representante}&data_emissao_rg_representante=${self.representante.data_emissao_rg_representante}&uf_rg_representante=${self.representante.uf_rg_representante}&data_nascimento_representante=${self.representante.data_nascimento_representante}&sexo_representante=${self.representante.sexo_representante}&tipo_logradouro_representante=${self.representante.tipo_logradouro_representante}&logradouro_representante=${self.representante.logradouro_representante}&numero_representante=${self.representante.numero_representante}&complemento_representante=${self.representante.complemento_representante}&bairro_representante=${self.representante.bairro_representante}&cep_representante=${self.representante.cep_representante}&estado_representante=${self.representante.estado_representante}&cidade_representante=${self.representante.cidade_representante}&telefone_representante=${self.representante.telefone_representante}&celular_representante=${self.representante.celular_representante}&email_representante=${self.representante.email_representante}`
        )
        .pipe(take(1))
        .toPromise();
    }
    self.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      self.representante.id
        ? "Representante Legal atualizado com sucesso!"
        : "Representante Legal cadastrado com sucesso!",
      "success"
    );
    await self.ngOnInit();
  }

  closeModal(modal) {
    modal.close("Close click");
  }
  getStatus(status) {
    return this.statusServidor.find((item) => item.id === status)?.name;
  }

  comeBack() {
    window.history.back();
  }
  async atualizarOuCadastrarDependente(modal) {
    const self = this;
    if (!self.dependente || !self.idServidor) {
      return;
    }
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.dependente.data_nascimento = this.preparaData(
      this.dependente.data_nascimento
    );
    this.dependente.data_emissao_rg = this.preparaData(
      this.dependente.data_emissao_rg
    );
    this.dependente.inicio_dependencia = this.preparaData(
      this.dependente.inicio_dependencia
    );
    this.dependente.fim_dependencia = this.preparaData(
      this.dependente.fim_dependencia
    );
    this.dependente.bairro = this.dependente.bairro.replace(/['`]/g, " ");
    this.dependente.logradouro = this.dependente.logradouro.replace(
      /['`]/g,
      " "
    );
    this.dependente.complemento = this.dependente.complemento.replace(
      /['`]/g,
      " "
    );
    this.dependente.nome = this.dependente.nome.replace(/['`]/g, " ");
    this.dependente.nome_mae = this.dependente.nome_mae.replace(/['`]/g, " ");
    this.dependente.nome_pai = this.dependente.nome_pai.replace(/['`]/g, " ");
    if (self.dependente.id) {
      const retorno = await self.apiCall
        .get(
          `servidor/dependente/editar?id_dependente=${
            self.dependente.id
          }&nome=${this.validateQuotes(this.dependente.nome)}&estado_civil=${
            this.dependente.estado_civil
          }&data_nascimento=${this.dependente.data_nascimento}&nacionalidade=${
            this.dependente.nacionalidade
          }&estado_naturalidade=${
            this.dependente.estado_naturalidade
          }&cidade_naturalidade=${
            this.dependente.cidade_naturalidade
          }&naturalizado=${this.dependente.naturalizado}&escolaridade=${
            this.dependente.escolaridade
          }&sexo=${this.dependente.sexo}&nome_mae=${this.validateQuotes(
            this.dependente.nome_mae
          )}&nome_pai=${this.validateQuotes(
            this.dependente.nome_pai
          )}&deficiente=${this.dependente.deficiente}&cpf=${
            this.dependente.cpf
          }&rg=${this.dependente.rg}&data_emissao_rg=${
            this.dependente.data_emissao_rg
          }&orgao_expedidor_rg=${this.dependente.orgao_expedidor_rg}&uf_rg=${
            this.dependente.uf_rg
          }&pis_pasep=${this.dependente.pis_pasep}&numero_certidao=${
            this.dependente.numero_certidao
          }&livro=${this.dependente.livro}&folha=${
            this.dependente.folha
          }&tipo_logradouro=${this.dependente.tipo_logradouro}&logradouro=${
            this.dependente.logradouro
          }&numero=${this.dependente.numero}&complemento=${
            this.dependente.complemento
          }&bairro=${this.dependente.bairro}&cep=${
            this.dependente.cep
          }&estado=${this.dependente.estado}&cidade=${
            this.dependente.cidade
          }&telefone=${this.dependente.telefone}&celular=${
            this.dependente.celular
          }&email=${this.dependente.email}&tipo_dependencia=${
            this.dependente.tipo_dependencia
          }&motivo_inicio=${this.dependente.motivo_inicio}&motivo_fim=${
            this.dependente.motivo_fim
          }&inicio_dependencia=${
            this.dependente.inicio_dependencia
          }&fim_dependencia=${
            this.dependente.fim_dependencia
          }&fins_previdenciarios=${this.dependente.fins_previdenciarios}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/dependente/novo?id_servidor=${
            this.idServidor
          }&nome=${this.validateQuotes(this.dependente.nome)}&estado_civil=${
            this.dependente.estado_civil
          }&data_nascimento=${this.dependente.data_nascimento}&nacionalidade=${
            this.dependente.nacionalidade
          }&estado_naturalidade=${
            this.dependente.estado_naturalidade
          }&cidade_naturalidade=${
            this.dependente.cidade_naturalidade
          }&naturalizado=${this.dependente.naturalizado}&escolaridade=${
            this.dependente.escolaridade
          }&sexo=${this.dependente.sexo}&nome_mae=${this.validateQuotes(
            this.dependente.nome_mae
          )}&nome_pai=${this.validateQuotes(
            this.dependente.nome_pai
          )}&deficiente=${this.dependente.deficiente}&cpf=${
            this.dependente.cpf
          }&rg=${this.dependente.rg}&data_emissao_rg=${
            this.dependente.data_emissao_rg
          }&orgao_expedidor_rg=${this.dependente.orgao_expedidor_rg}&uf_rg=${
            this.dependente.uf_rg
          }&pis_pasep=${this.dependente.pis_pasep}&numero_certidao=${
            this.dependente.numero_certidao
          }&livro=${this.dependente.livro}&folha=${
            this.dependente.folha
          }&tipo_logradouro=${this.dependente.tipo_logradouro}&logradouro=${
            this.dependente.logradouro
          }&numero=${this.dependente.numero}&complemento=${
            this.dependente.complemento
          }&bairro=${this.dependente.bairro}&cep=${
            this.dependente.cep
          }&estado=${this.dependente.estado}&cidade=${
            this.dependente.cidade
          }&telefone=${this.dependente.telefone}&celular=${
            this.dependente.celular
          }&email=${this.dependente.email}&tipo_dependencia=${
            this.dependente.tipo_dependencia
          }&motivo_inicio=${this.dependente.motivo_inicio}&motivo_fim=${
            this.dependente.motivo_fim
          }&inicio_dependencia=${
            this.dependente.inicio_dependencia
          }&fim_dependencia=${
            this.dependente.fim_dependencia
          }&fins_previdenciarios=${this.dependente.fins_previdenciarios}`
        )
        .pipe(take(1))
        .toPromise();
    }
    self.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      self.dependente.id
        ? "Dependente atualizado com sucesso!"
        : "Dependente cadastrado com sucesso!",
      "success"
    );
    modal.close("Close click");
    await self.ngOnInit();
  }

  onTabChange(event){
    console.log("clicou")
    if(this.isEditingBasicInfos || this.isEditingObservations || this.isEditingPersonalData || this.isEditingObito || this.isEditingRepresentante){
      event.preventDefault();
      this.app.alert('Atenção', 'Você está editando um campo, por favor termine a edição ou cancele antes de mudar de aba', 'info');
    }
  }
}

/* documentos finalizado
1. Rota para buscar documentos - ok
2. Card para exibir documentos - ok
3. Funções de baixar e excluir doc - ok
4. Botão para add doc - ok
4. Modal para upload de doc - ok 

====================

dependentes

1. Rota para buscar dependentes
2. Card para exibir dependentes
3. Funções de adicionar, editar e excluir dependentes 

Forms do card inicial




*/
