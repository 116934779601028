<div class="card" style="border-radius: 10px">
  <div class="card-body">
    <div class="row text-left">
      <div class="col-sm-12">
        <div class="d-flex justify-content-between mb-0">
          <h1 class="text-bold-400" style="color: #4e7977">
            Servidores {{ nomeCidade }}
          </h1>
        </div>
      </div>
      <div class="col-sm-12">
        <p class="mb-2 mt-2" style="font-size: 20px">
          Visualize e gerencie todos os servidores públicos do seu município.
          Utilize a busca e os filtros para encontrar e atualizar informações de
          forma eficiente.
        </p>
      </div>
      <div class="col-sm-12 mt-2 mb-2">
        <div
          *ngIf="isAdmin"
          class="btn-group btn-group-lg d-none d-md-block float-right"
          role="group"
        >
          <button
            type="button"
            placement="bottom"
            ngbTooltip="Importações."
            class="btn btn-primary"
            routerLink="/page/importacoes"
            [queryParams]="{ cidade: 4144 }"
          >
            <i class="fa fa-upload"></i> Impotações
          </button>

          <button
            type="button"
            placement="bottom"
            ngbTooltip="Novo Servidor."
            class="btn btn-primary"
            (click)="openModalXl(cadastroServidor)"
          >
            <i class="fa fa-plus"></i> Adicionar Servidor
          </button>
        </div>
        <button
          *ngIf="!isAdmin"
          type="button"
          placement="bottom"
          ngbTooltip="Novo Servidor."
          class="btn btn-primary float-right"
          (click)="openModalXl(cadastroServidor)"
        >
          <i class="fa fa-plus"></i> Adicionar Servidor
        </button>
      </div>
    </div>

    <form #servForm="ngForm" novalidate>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="nome" style="font-weight: bold">Nome</label>
              <input
                type="text"
                id="nome"
                class="form-control"
                [(ngModel)]="filterName"
                name="nome"
                placeholder="Procure por nome"
                required
                aria-invalid="false"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="cpf" style="font-weight: bold">CPF</label>
              <input
                type="text"
                id="cpf"
                class="form-control"
                [(ngModel)]="filterCpf"
                name="cpf"
                placeholder="Digite um cpf"
                required
                aria-invalid="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="matricula" style="font-weight: bold">Matrícula</label>
              <input
                type="text"
                id="matricula"
                class="form-control"
                [(ngModel)]="filterMatricula"
                name="matricula"
                placeholder="Digíte uma matrícula"
                required
                aria-invalid="false"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="especificidade" style="font-weight: bold"
                >Especificidade</label
              >
              <ng-select
                [items]="estados"
                name="especificidade"
                (change)="toggleEstado()"
                bindLabel="especificidade"
                bindValue="especificidade"
                placeholder="Selecione"
                id="especificidade"
                [(ngModel)]="servidor.estado_naturalidade"
              ></ng-select>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <div class="controls">
              <label for="status" style="font-weight: bold">Status</label>
              <ng-select
                [items]="statusServidor"
                name="status"
                bindLabel="name"
                bindValue="id"
                placeholder="Selecione"
                id="status"
                [(ngModel)]="filterStatus"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="data_cadastro" style="font-weight: bold"
              >Data de cadastro</label
            >
            <div class="d-flex">
              <div class="input-group">
                <input
                  type="date"
                  id="data_cadastro"
                  class="form-control"
                  [(ngModel)]="filterCreatedAt.start"
                  name="data_cadastro"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
              <span class="mx-2 d-flex align-items-center">até</span>
              <div class="input-group">
                <input
                  type="date"
                  id="data_nascimento"
                  class="form-control"
                  [(ngModel)]="filterCreatedAt.end"
                  name="data_nascimento"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="data_nascimento" style="font-weight: bold"
              >Data de nascimento</label
            >
            <div class="d-flex">
              <div class="input-group">
                <input
                  type="date"
                  id="data_nascimento"
                  class="form-control"
                  [(ngModel)]="filterBirthDate.start"
                  name="data_nascimento"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
              <span class="mx-2 d-flex align-items-center">até</span>
              <div class="input-group">
                <input
                  type="date"
                  id="data_nascimento"
                  class="form-control"
                  [(ngModel)]="filterBirthDate.end"
                  name="data_nascimento"
                  placeholder="dd-mm-yyyy"
                  required
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="form-row d-flex justify-content-end align-items-center">
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="clearFilters()"
        >
          Limpar filtros
        </button>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-primary mb-2 mb-md-0"
          (click)="applyFilters()"
        >
          Pesquisar <i class="fa fa-search mr-1"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- LISTAGEM SERVIDORES -->
<div class="card" style="border-radius: 10px">
  <table class="table text-center table-striped" style="padding: 10px">
    <thead>
      <tr>
        <th (click)="sortTable('nome')">
          <div class="d-flex align-items-center justify-content-center">
            Nome
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'nome'" class="fa fa-caret-up"></i>
              <i *ngIf="sortColumn !== 'nome'" class="fa fa-caret-down"></i>
              <i
                *ngIf="sortColumn === 'nome'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th (click)="sortTable('cpf')">
          <div class="d-flex align-items-center justify-content-center">
            CPF
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'cpf'" class="fa fa-caret-up"></i>
              <i *ngIf="sortColumn !== 'cpf'" class="fa fa-caret-down"></i>
              <i
                *ngIf="sortColumn === 'cpf'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th (click)="sortTable('matricula')">
          <div class="d-flex align-items-center justify-content-center">
            Matrícula
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'matricula'" class="fa fa-caret-up"></i>
              <i
                *ngIf="sortColumn !== 'matricula'"
                class="fa fa-caret-down"
              ></i>
              <i
                *ngIf="sortColumn === 'matricula'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th (click)="sortTable('status')">
          <div class="d-flex align-items-center justify-content-center">
            Status
            <div class="sort-icons ml-1">
              <i *ngIf="sortColumn !== 'status'" class="fa fa-caret-up"></i>
              <i *ngIf="sortColumn !== 'status'" class="fa fa-caret-down"></i>
              <i
                *ngIf="sortColumn === 'status'"
                class="fa"
                [ngClass]="{
                  'fa-caret-up': sortDirection === 'asc',
                  'fa-caret-down': sortDirection === 'desc'
                }"
              ></i>
            </div>
          </div>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let servidor of servidoresFiltered
            | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
        "
      >
        <td class="pl-3 pr-3">
          <div
            class="d-flex align-items-center"
            style="padding-left: 10px; padding-right: 10px"
          >
            <img
              width="50"
              height="50"
              alt="datatable-avatar"
              class="square rounded-circle"
              [ngClass]="{ 'cursor-pointer': servidor && servidor.imagem }"
              (click)="
                servidor && servidor.imagem
                  ? viewMidia(servidor.imagem, contentImg)
                  : null
              "
              [src]="
                servidor && servidor.imagem
                  ? servidor.imagem
                  : 'assets/img/portrait/small/no-avatar.svg'
              "
              style="margin-right: 10px"
            />
            <div>{{ servidor.nome }}</div>
          </div>
        </td>
        <td class="pl-0 pr-0 text-center align-middle">
          {{ servidor.cpf | mask : "000.000.000-00" }}
        </td>
        <td class="pl-0 pr-0 text-center align-middle">
          {{
            servidor.status == 2 || servidor.status == 3
              ? servidor.matricula_beneficiario
              : servidor.matricula
          }}
        </td>
        <td class="pl-0 pr-0 text-center align-middle">
          {{ classificaStatus(servidor.status) }}
        </td>
        <td class="align-middle" style="padding: 0">
          <div class="d-flex flex-column gap-2">
            <div class="d-flex justify-content-start gap-2">
              <button
                type="button"
                class="btn btn-outline-primary btn-sm w-100 m-1"
                routerLink="/page/servidorv2"
                [queryParams]="{ id: servidor.id }"
              >
                <i class="fa fa-eye"></i> Visualizar
              </button>
              <button
                type="button"
                class="btn btn-outline-primary btn-sm w-100 m-1"
                (click)="duplicarServidor(servidor.id)"
              >
                <i class="fa fa-copy"></i> Duplicar
              </button>
            </div>
            <div class="d-flex justify-content-start gap-2">
              <button
                type="button"
                class="btn btn-outline-primary btn-sm w-100 m-1"
                (click)="getRelatorioServidor(relatorio, servidor.id)"
              >
                <i class="fa fa-file-text-o"></i> Relatório
              </button>
              <button
                type="button"
                class="btn btn-outline-danger btn-sm w-100 m-1"
                (click)="deletarServidor(servidor.id)"
              >
                <i class="fa fa-trash"></i> Excluir
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="form-row mt-2 d-flex justify-content-end" style="margin: 5px">
    <ngb-pagination
      class="ml-auto"
      style="margin-top: 10px; margin-right: 10px"
      *ngIf="servidoresFiltered.length > 0"
      [(page)]="page"
      [pageSize]="pageSize"
      [maxSize]="2"
      [rotate]="true"
      [boundaryLinks]="true"
      size="sm"
      [collectionSize]="servidoresFiltered.length"
    ></ngb-pagination>
  </div>
</div>
<div class="row" *ngIf="servidores.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum servidor encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #cadastroServidor let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Novo Servidor</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <p style="color: red">
                    <b
                      >Para servidores com DUAS matriculas, deve ser feito DOIS
                      cadastros.</b
                    >
                  </p>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="escolaridade"
                        >Status <b style="color: red">*</b></label
                      >
                      <ng-select
                        [items]="statusServidor"
                        name="status"
                        bindLabel="name"
                        bindValue="id"
                        placeholder="Status"
                        id="status"
                        [(ngModel)]="servidor.status"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Nome <b style="color: red">*</b></label>
                      <input
                        type="text"
                        id="nome"
                        class="form-control"
                        [(ngModel)]="servidor.nome"
                        name="nome"
                        placeholder="Nome Completo"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_nascimento">Nascimento</label>
                      <input
                        type="text"
                        id="data_nascimento"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="servidor.data_nascimento"
                        name="data_nascimento"
                        placeholder="Data Nascimento"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="sexo">Sexo</label>
                      <ng-select
                        [items]="sexos"
                        name="sexo"
                        bindLabel="name"
                        placeholder="Sexo"
                        bindValue="id"
                        id="sexo"
                        [(ngModel)]="servidor.sexo"
                      ></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Estado</label>
                      <ng-select
                        [items]="estados"
                        name="estado"
                        (change)="toggleEstado()"
                        bindLabel="estado"
                        bindValue="estado"
                        placeholder="Estado"
                        id="estado"
                        [(ngModel)]="servidor.estado_naturalidade"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Cidade</label>
                      <ng-select
                        [items]="cidades"
                        [disabled]="!cidades || cidades.length == 0"
                        name="cidade"
                        bindLabel="nome"
                        bindValue="id"
                        placeholder="Cidade"
                        id="cidade"
                        [(ngModel)]="servidor.cidade_naturalidade"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nacionalidade">Nacionalidade</label>
                      <input
                        type="text"
                        id="nacionalidade"
                        class="form-control"
                        [(ngModel)]="servidor.nacionalidade"
                        name="nacionalidade"
                        placeholder="Nacionalidade"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="naturalizado">Naturalizado</label>
                      <ng-select
                        [items]="naturalizados"
                        name="naturalizado"
                        bindLabel="name"
                        placeholder="Naturalizado"
                        bindValue="id"
                        id="naturalizado"
                        [(ngModel)]="servidor.naturalizado"
                      ></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="estado_civil">Estado Civil</label>
                      <ng-select
                        [items]="estadosCivis"
                        name="estado_civil"
                        bindLabel="descricao"
                        bindValue="id"
                        placeholder="Estado Civil"
                        id="estado_civil"
                        [(ngModel)]="servidor.estado_civil"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="escolaridade">Escolaridade</label>
                      <ng-select
                        [items]="escolaridades"
                        name="escolaridade"
                        bindLabel="descricao"
                        bindValue="id"
                        placeholder="Escolaridade"
                        id="escolaridade"
                        [(ngModel)]="servidor.escolaridade"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="mae">Nome Mãe</label>
                      <input
                        type="text"
                        id="mae"
                        class="form-control"
                        [(ngModel)]="servidor.nome_mae"
                        name="mae"
                        placeholder="Nome Mãe"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="pai">Nome Pai</label>
                      <input
                        type="text"
                        id="pai"
                        class="form-control"
                        [(ngModel)]="servidor.nome_pai"
                        name="pai"
                        placeholder="Nome Pai"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cpf">CPF</label>
                      <input
                        type="text"
                        id="cpf"
                        class="form-control"
                        [(ngModel)]="servidor.cpf"
                        mask="000.000.000-00"
                        name="cpf"
                        placeholder="CPF"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="col"
                  *ngIf="
                    servidor &&
                    servidor.status &&
                    servidor.status != 2 &&
                    servidor.status != 3
                  "
                >
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_ingresso">Data de admissão</label>
                      <input
                        type="text"
                        id="data_ingresso"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="servidor.data_ingresso_servico_publico"
                        name="data_ingresso"
                        placeholder="Data de Admissão"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col"
                  *ngIf="
                    servidor &&
                    servidor.status &&
                    servidor.status != 1 &&
                    servidor.status != 2 &&
                    servidor.status != 3
                  "
                >
                  <div class="form-group">
                    <div class="controls">
                      <label for="docingresso">Documento de Ingresso</label>
                      <input
                        type="text"
                        id="docingresso"
                        class="form-control"
                        [(ngModel)]="
                          servidor.documento_ingresso_servico_publico
                        "
                        name="docingresso"
                        placeholder="Documento de Ingresso"
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="naturalizado"
                        >Pessoa com deficiência - PCD</label
                      >
                      <ng-select
                        [items]="naturalizados"
                        name="deficiente"
                        bindLabel="name"
                        placeholder="Deficiente"
                        bindValue="id"
                        id="deficiente"
                        [(ngModel)]="servidor.deficiente"
                      ></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row"
                *ngIf="servidor && servidor.status && servidor.status == 2"
              >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="valoraposentadoria"
                        >Valor Atual da Aposentadoria</label
                      >
                      <input
                        type="text"
                        id="valoraposentadoria"
                        class="form-control"
                        prefix="R$"
                        mask="separator.2"
                        thousandSeparator="."
                        decimalMarker=","
                        [dropSpecialCharacters]="false"
                        [(ngModel)]="servidor.valor_aposentadoria"
                        name="valoraposentadoria"
                        placeholder="Valor Atual da Aposentadoria"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="naturalizado">Tipo de Aposentadoria</label>
                      <ng-select
                        [items]="tiposaposentadoria"
                        name="deficiente"
                        bindLabel="name"
                        groupBy="group"
                        placeholder="Tipo de Aposentadoria"
                        bindValue="value"
                        id="tiposaposentadoria"
                        [(ngModel)]="servidor.tipo_aposentadoria"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="valoraposentadoria"
                        >Cargo em que aposentou</label
                      >
                      <input
                        type="text"
                        id="cargo"
                        class="form-control"
                        [(ngModel)]="servidor.cargo_aposentadoria"
                        name="cargo"
                        placeholder="Cargo em que aposentou"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="pis_pasep">PIS do Aposentado</label>
                      <input
                        type="text"
                        id="pis_pasep"
                        class="form-control"
                        [(ngModel)]="servidor.pis_aposentado"
                        mask="0*"
                        name="pis_pasep"
                        placeholder="PIS do Aposentado"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row"
                *ngIf="servidor && servidor.status && servidor.status == 2"
              >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_ingresso"
                        >Data de Ingresso no Serviço Público</label
                      >
                      <input
                        type="text"
                        id="data_ingresso"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="servidor.data_ingresso_servico_publico"
                        name="data_ingresso"
                        placeholder="Data de Ingresso no Serviço Público"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_concessao"
                        >Data de Concessão do Benefício</label
                      >
                      <input
                        type="text"
                        id="data_concessao"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="servidor.concessao_aposentadoria"
                        name="data_concessao"
                        placeholder="Data de Concessão do Benefício"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="paridade">Paridade</label>
                      <ng-select
                        [items]="naturalizados"
                        name="paridade"
                        bindLabel="name"
                        placeholder="Paridade"
                        bindValue="id"
                        id="paridade"
                        [(ngModel)]="servidor.paridade_aposentadoria"
                      ></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row"
                *ngIf="servidor && servidor.status && servidor.status == 3"
              >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="valor_beneficio">Valor do Benefício</label>
                      <input
                        type="text"
                        id="valor_beneficio"
                        class="form-control"
                        prefix="R$"
                        mask="separator.2"
                        thousandSeparator="."
                        decimalMarker=","
                        [dropSpecialCharacters]="false"
                        [(ngModel)]="servidor.valor_beneficio"
                        name="valor_beneficio"
                        placeholder="Valor do Benefício"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tipo_pensao">Tipo de Pensão</label>
                      <ng-select
                        [items]="tipospensao"
                        name="tipo_pensao"
                        bindLabel="name"
                        placeholder="Tipo de Pensão"
                        bindValue="value"
                        id="tipo_pensao"
                        [(ngModel)]="servidor.tipo_pensao"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="matricula_beneficiario"
                        >Matrícula do Beneficiário</label
                      >
                      <input
                        type="text"
                        id="matricula_beneficiario"
                        class="form-control"
                        [(ngModel)]="servidor.matricula_beneficiario"
                        name="matricula_beneficiario"
                        placeholder="Matrícula do Beneficiário"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row"
                *ngIf="servidor && servidor.status && servidor.status == 3"
              >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_falecimento_pensao"
                        >Data de Falecimento do Instituidor da Pensão</label
                      >
                      <input
                        type="text"
                        id="data_falecimento_pensao"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="servidor.data_falecimento_pensao"
                        name="data_falecimento_pensao"
                        placeholder="Data de Falecimento do Instituidor da Pensão"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_nascimento_pensao"
                        >Data de Nascimento do Instituidor da Pensão</label
                      >
                      <input
                        type="text"
                        id="data_nascimento_pensao"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="servidor.data_nascimento_pensao"
                        name="data_nascimento_pensao"
                        placeholder="Data de Nascimento do Instituidor da Pensão"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row"
                *ngIf="servidor && servidor.status && servidor.status == 3"
              >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome_pensao">Nome do Instituidor</label>
                      <input
                        type="text"
                        id="nome_pensao"
                        class="form-control"
                        [(ngModel)]="servidor.nome_pensao"
                        name="nome_pensao"
                        placeholder="Nome do Instituidor"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="matricula_pensao"
                        >Matrícula do Instituidor</label
                      >
                      <input
                        type="text"
                        id="matricula_pensao"
                        class="form-control"
                        [(ngModel)]="servidor.matricula_pensao"
                        name="matricula_pensao"
                        placeholder="Matrícula do Instituidor"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="pis_pasep">PIS do Instituidor</label>
                      <input
                        type="text"
                        id="pis_pasep"
                        class="form-control"
                        [(ngModel)]="servidor.pis_pensao"
                        mask="0*"
                        name="pis_pasep"
                        placeholder="PIS do Instituidor"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cpf_pensao">CPF do Instituidor</label>
                      <input
                        type="text"
                        id="cpf_pensao"
                        class="form-control"
                        [(ngModel)]="servidor.cpf_pensao"
                        name="cpf_pensao"
                        placeholder="CPF do Instituidor"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="form-row"
                *ngIf="servidor && servidor.status && servidor.status == 4"
              >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="motivo_exoneracao">Motivo Exoneração</label>
                      <input
                        type="text"
                        id="motivo_exoneracao"
                        class="form-control"
                        [(ngModel)]="servidor.motivo_exoneracao"
                        name="motivo_exoneracao"
                        placeholder="Motivo Exoneração"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                    [disabled]="disableServidor()"
                    (click)="cadastrarServidor(modal)"
                  >
                    Cadastrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #contentImg>
  <IMG style="width: 100%; height: 100%" [src]="urlToShow" />
</ng-template>
<ng-template #relatorio let-modal>
  <div class="row mb-2" id="print-section">
    <div class="col">
      <div class="card">
        <div class="text-center">
          <div class="card-header">
            <h4 class="card-title">Prefeitura Municipal de {{ nomeCidade }}</h4>
            <p>
              <b>Matrícula: {{ servidor.matricula }}</b>
            </p>
          </div>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <img
                  [src]="
                    servidor && servidor.imagem
                      ? servidor.imagem
                      : 'assets/img/portrait/small/no-avatar.svg'
                  "
                  (click)="
                    servidor && servidor.imagem
                      ? viewMidia(servidor.imagem, contentImg)
                      : null
                  "
                  alt="profile-img"
                  class="rounded-circle mr-3"
                  style="
                    width: 150px;
                    height: 150px;
                    float: right;
                    object-fit: cover;
                    object-position: top;
                  "
                />
              </div>
              <!-- <div class="col">
              Matrícula: {{servidor.matricula}}
            </div> -->
            </div>
            <div class="row">
              <div class="col">Nome: {{ servidor.nome }}</div>
            </div>
            <div class="row">
              <div class="col">Telefone: {{ servidor.Telefone }}</div>
            </div>
            <div class="row">
              <div class="col">
                Data de Nascimento:
                {{ beautifulDate(servidor.data_nascimento) }}
              </div>
            </div>
            <div class="row">
              <div class="col">Naturalizado: {{ servidor.naturalizado }}</div>
            </div>
            <div class="row">
              <div class="col">Estado Civil: {{ servidor.estado_civil }}</div>
            </div>
            <div class="row">
              <div class="col">CPF: {{ servidor.cpf }}</div>
            </div>
            <div class="row">
              <div class="col">Identidade: {{ servidor.rg }}</div>
            </div>
            <div class="row">
              <div class="col">Escolaridade: {{ servidor.escolaridade }}</div>
            </div>
            <div class="row">
              <div class="col">PIS: {{ servidor.pis_pasep }}</div>
            </div>
            <div class="row">
              <div class="col">Status: {{ servidor.status }}</div>
            </div>
            <div class="row">
              <div class="col">Cargo Atual: {{ servidor.cargo }}</div>
            </div>
            <div class="row">
              <div class="col">
                <!-- Secretária:{{servidor.secretaria}} -->
              </div>
            </div>
            <div class="row">
              <div class="col">
                Data Admissão: {{ beautifulDate(servidor.data_ingresso) }}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <!-- Decreto de Nomeação:{{beautifulDate(servidor.da)}} -->
              </div>
            </div>
            <div class="row">
              <div class="col">Carga Horária: {{ servidor.carga_horaria }}</div>
            </div>
            <div class="row">
              <div class="col">
                Salário Base/Benefício: {{ servidor.salario }}
              </div>
            </div>
            <div
              class="row"
              *ngIf="servidor.status && servidor.status == 'Ativo'"
            >
              <div class="col">
                Provável Idade Aposentadoria: {{ servidor.idade_aposentadoria }}
              </div>
            </div>
            <div class="row text-left mt-3">
              <div class="col-sm-12">
                <div class="d-flex justify-content-between mb-0">
                  <h3 class="text-bold-300">Dependentes</h3>
                </div>
              </div>
            </div>
            <div class="row mb-2" *ngIf="dependentesToShow.length > 0">
              <div class="col">
                <div class="card-content">
                  <div class="card-body pr-0 pl-0">
                    <table class="table text-center m-0">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Cpf</th>
                          <th>Tipo</th>
                          <th>Idade</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let dependente of dependentesToShow">
                          <td>{{ dependente.nome }}</td>
                          <td>{{ dependente.cpf }}</td>
                          <td>{{ dependente.tipo_dependencia_label }}</td>
                          <td
                            [ngStyle]="{
                              color: 'black',
                              'font-weight': 'normal'
                            }"
                          >
                            {{ dependente.idade + " anos" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="dependentesToShow.length == 0">
              <div class="col">
                <div class="card-content">
                  <div class="card-body pl-0 pr-0">
                    <ul class="list-group">
                      <li class="list-group-item">
                        <div class="media">
                          <div class="media-body">
                            <h5 class="mt-0">Nenhum dependente cadastrado.</h5>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="tempoTotalCadastrado">
              <div class="col">
                <h6 class="text-bold-300">
                  {{ "Tempo total cadastrado (dias): " + tempoTotalCadastrado }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h6 class="text-bold-300">
                  {{
                    (tipo == "1"
                      ? "Tempo total cadastrado RPPS (dias): "
                      : "Tempo total cadastrado INSS (dias): ") +
                      tempoFiltradoCadastrado
                  }}
                </h6>
              </div>
            </div>

            <div
              class="row"
              *ngIf="
                servidor.inicio_atendimento != null &&
                servidor.fim_atendimento != null
              "
            >
              <div class="col">
                <b
                  >Você participou do censo previdenciário
                  {{ getYear(servidor.inicio_atendimento) }} do regime próprio
                  de previdência social do município de {{ nomeCidade }}</b
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
