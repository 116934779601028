<div class="d-flex align-items-center mb-3">
  <a class="btn-back"  routerLink="/page/servidorv2" [queryParams]="{ id: idServidor }">
    <i class="fa fa-arrow-left"></i> Voltar
  </a>
  <span class="ml-2">Servidores do Município > Servidor > Dados Funcionais</span>
</div>

<div class="card">
  <div class="card-body">
    <div class="row text-left mt-3">
      <div class="col-12">
        <h1 class="text-bold-400" style="color: #4e7977">
          Extrato Tempo de Contribuição
        </h1>
        <p class="mb-2 mt-2" style="font-size: 20px; color: #363636">
          Cadastre o Extrato de Tempo de Contribuição para servidores nos
          regimes do RPPS ou INSS.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="card card-tenpo-contribuicao">
          <div class="card-header">
            <h4 class="card-tempo-title">RPPS</h4>
          </div>
          <div class="card-content mt-0">
            <div class="card-body">
              <h3 class="card-tempo-title mb-1">{{ tempoTotalRPPS }}</h3>
              <p class="card-tempo-desc">Dias Cadastrados</p>

              <h3 class="card-tempo-title mb-1">
                {{ tempoTotalRPPSAverbado }}
              </h3>
              <p class="card-tempo-desc">Dias Averbados</p>
            </div>
          </div>
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-outline-primary float-right mt-0"
              (click)="openModalXl(cadastroTempo, null, 0)"
            >
              Cadastrar CTC (RPPS)
            </button>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card card-tenpo-contribuicao">
          <div class="card-header">
            <h4 class="card-tempo-title">INSS</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <h3 class="card-tempo-title mb-1">{{ tempoTotalINSS }}</h3>
              <p class="card-tempo-desc">Dias Cadastrados</p>

              <h3 class="card-tempo-title mb-1">
                {{ tempoTotalINSSAverbado }}
              </h3>
              <p class="card-tempo-desc">Dias Averbados</p>
            </div>
          </div>
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-outline-primary float-right mt-0"
              (click)="openModalXl(cadastroTempo, null, 1)"
            >
              Cadastrar CTC (INSS)
            </button>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card card-tenpo-contribuicao">
          <div class="card-header">
            <h4 class="card-tempo-title">Dias Totais</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <h3 class="card-tempo-title mb-1">{{ tempoTotalCadastrado }}</h3>
              <p class="card-tempo-desc">Dias Cadastrados</p>

              <h3 class="card-tempo-title mb-1">{{ tempoTotalAverbado }}</h3>
              <p class="card-tempo-desc">Dias Averbados</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <div class="card" style="border: 1px solid #342e4914">
          <div class="card-body pr-0 pl-0 pt-0" style="border-radius: 10px">
            <table class="table text-center table-striped">
              <thead>
                <tr>
                  <th (click)="sortTable('cnpj_cpf_empregador')">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      CPF/CNPJ
                      <div class="sort-icons ml-1">
                        <i
                          *ngIf="sortColumn !== 'cnpj_cpf_empregador'"
                          class="fa fa-caret-up"
                        ></i>
                        <i
                          *ngIf="sortColumn !== 'cnpj_cpf_empregador'"
                          class="fa fa-caret-down"
                        ></i>
                        <i
                          *ngIf="sortColumn === 'cnpj_cpf_empregador'"
                          class="fa"
                          [ngClass]="{
                            'fa-caret-up': sortDirection === 'asc',
                            'fa-caret-down': sortDirection === 'desc'
                          }"
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th (click)="sortTable('nome_empregrador')">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Empregador
                      <div class="sort-icons ml-1">
                        <i
                          *ngIf="sortColumn !== 'nome_empregrador'"
                          class="fa fa-caret-up"
                        ></i>
                        <i
                          *ngIf="sortColumn !== 'nome_empregrador'"
                          class="fa fa-caret-down"
                        ></i>
                        <i
                          *ngIf="sortColumn === 'nome_empregrador'"
                          class="fa"
                          [ngClass]="{
                            'fa-caret-up': sortDirection === 'asc',
                            'fa-caret-down': sortDirection === 'desc'
                          }"
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th (click)="sortTable('data_entrada')">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Data Inicial
                      <div class="sort-icons ml-1">
                        <i
                          *ngIf="sortColumn !== 'data_entrada'"
                          class="fa fa-caret-up"
                        ></i>
                        <i
                          *ngIf="sortColumn !== 'data_entrada'"
                          class="fa fa-caret-down"
                        ></i>
                        <i
                          *ngIf="sortColumn === 'data_entrada'"
                          class="fa"
                          [ngClass]="{
                            'fa-caret-up': sortDirection === 'asc',
                            'fa-caret-down': sortDirection === 'desc'
                          }"
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th (click)="sortTable('data_fim')">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Data Final
                      <div class="sort-icons ml-1">
                        <i
                          *ngIf="sortColumn !== 'data_fim'"
                          class="fa fa-caret-up"
                        ></i>
                        <i
                          *ngIf="sortColumn !== 'data_fim'"
                          class="fa fa-caret-down"
                        ></i>
                        <i
                          *ngIf="sortColumn === 'data_fim'"
                          class="fa"
                          [ngClass]="{
                            'fa-caret-up': sortDirection === 'asc',
                            'fa-caret-down': sortDirection === 'desc'
                          }"
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th (click)="sortTable('tempo_bruto')">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Tempo Bruto (dias)
                      <div class="sort-icons ml-1">
                        <i
                          *ngIf="sortColumn !== 'tempo_bruto'"
                          class="fa fa-caret-up"
                        ></i>
                        <i
                          *ngIf="sortColumn !== 'tempo_bruto'"
                          class="fa fa-caret-down"
                        ></i>
                        <i
                          *ngIf="sortColumn === 'tempo_bruto'"
                          class="fa"
                          [ngClass]="{
                            'fa-caret-up': sortDirection === 'asc',
                            'fa-caret-down': sortDirection === 'desc'
                          }"
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th (click)="sortTable('rpps_rgps')">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Tipo
                      <div class="sort-icons ml-1">
                        <i
                          *ngIf="sortColumn !== 'rpps_rgps'"
                          class="fa fa-caret-up"
                        ></i>
                        <i
                          *ngIf="sortColumn !== 'rpps_rgps'"
                          class="fa fa-caret-down"
                        ></i>
                        <i
                          *ngIf="sortColumn === 'rpps_rgps'"
                          class="fa"
                          [ngClass]="{
                            'fa-caret-up': sortDirection === 'asc',
                            'fa-caret-down': sortDirection === 'desc'
                          }"
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th (click)="sortTable('averbado')">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      Averbação
                      <div class="sort-icons ml-1">
                        <i
                          *ngIf="sortColumn !== 'averbado'"
                          class="fa fa-caret-up"
                        ></i>
                        <i
                          *ngIf="sortColumn !== 'averbado'"
                          class="fa fa-caret-down"
                        ></i>
                        <i
                          *ngIf="sortColumn === 'averbado'"
                          class="fa"
                          [ngClass]="{
                            'fa-caret-up': sortDirection === 'asc',
                            'fa-caret-down': sortDirection === 'desc'
                          }"
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let t of tempos
                      | slice
                        : (page - 1) * pageSize
                        : (page - 1) * pageSize + pageSize
                  "
                >
                  <td class="pl-0 pr-0">{{ t.cnpj_cpf_empregador }}</td>
                  <td class="pl-0 pr-0">{{ t.nome_empregrador }}</td>
                  <td class="pl-0 pr-0">
                    {{ getBeautifulDate(t.data_entrada) }}
                  </td>
                  <td class="pl-0 pr-0">{{ getBeautifulDate(t.data_fim) }}</td>
                  <td class="pl-0 pr-0">{{ t.tempo_bruto }}</td>
                  <td class="pl-0 pr-0">
                    {{
                      t.rpps_rgps != null
                        ? t.rpps_rgps == 0
                          ? "RPPS"
                          : "INSS"
                        : ""
                    }}
                  </td>
                  <td class="pl-0 pr-0">
                    {{ t.averbado == 1 ? "Sim" : "Não" }}
                  </td>
                  <td class="col-2">
                    <button
                      type="button"
                      placement="top"
                      ngbTooltip="Deletar Tempo."
                      class="btn btn-outline-danger btn-icon mr-2"
                      (click)="deletarTempo(t.id)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      placement="top"
                      ngbTooltip="Visualizar Tempo."
                      class="btn btn-outline-success btn-icon"
                      (click)="openModalXl(cadastroTempo, t)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-row d-none" style="margin: 5px">
            <div class="d-flex justify-content-center">
              <ngb-pagination
                style="margin-top: 10px"
                *ngIf="tempos.length > 0"
                [(page)]="page"
                [pageSize]="pageSize"
                [maxSize]="12"
                [collectionSize]="tempos.length"
              ></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="tempos.length == 0">
      <div class="col">
        <div class="card-content">
          <div class="card-body pl-0 pr-0">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0">Nenhum tempo de serviço cadastrado.</h5>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row d-none">
      <div class="col">
        <button
          type="button"
          class="btn btn-outline-primary"
          routerLink="/page/servidor"
          [queryParams]="{ id: idServidor }"
        >
          Voltar
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #edicaoCertidao let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Certidão</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #certidaoForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="numero_certidaoa">Certidão</label>
                      <input
                        type="text"
                        id="numero_certidaoa"
                        class="form-control"
                        [(ngModel)]="certidaoEmEdicao.numero_certidao"
                        name="numero_certidaoa"
                        placeholder="Certidão"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_certidaoa">Data</label>
                      <input
                        type="text"
                        id="data_certidaoa"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="certidaoEmEdicao.data_certidao"
                        name="data_certidaoa"
                        placeholder="Data"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                    (click)="atualizarCertidao(modal)"
                    [disabled]="
                      !certidaoEmEdicao.id ||
                      !certidaoEmEdicao.numero_certidao ||
                      !certidaoEmEdicao.data_certidao
                    "
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cadastroTempo let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ tempo && tempo.id ? "Editar" : "Novo" }} Tempo de Contribuição
          {{ tempo && tempo.rpps_rgps ? "INSS" : "RPPS" }}
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="cancelTempo(modal)">x</button>
      </div>
      <div class="modal-body">
        <form #servForm="ngForm" novalidate>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <div class="controls">
                  <label for="cnpj_cpf_empregador">CNPJ/CPF Empregador</label>
                  <input
                    type="text"
                    id="cnpj_cpf_empregador"
                    class="form-control"
                    [(ngModel)]="tempo.cnpj_cpf_empregador"
                    name="cnpj_cpf_empregador"
                    placeholder="CNPJ/CPF"
                    required
                    aria-invalid="false"
                    mask="000.000.000-00||00.000.000/0000-00"
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="controls">
                  <label for="nome_empregrador">Nome Empregador</label>
                  <input
                    type="text"
                    id="nome_empregrador"
                    class="form-control"
                    [(ngModel)]="tempo.nome_empregrador"
                    name="nome_empregrador"
                    placeholder="Nome"
                    required
                    aria-invalid="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-3">
              <div class="form-group">
                <div class="controls">
                  <label for="data_entrada">Data Início</label>
                  <input
                    type="text"
                    id="data_entrada"
                    class="form-control"
                    mask="00/00/0000"
                    [(ngModel)]="tempo.data_entrada"
                    (change)="calculaTempoBruto()"
                    name="data_entrada"
                    placeholder="Data Início"
                    required
                    aria-invalid="false"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <div class="controls">
                  <label for="data_fim">Data Fim</label>
                  <input
                    type="text"
                    id="data_fim"
                    class="form-control"
                    mask="00/00/0000"
                    [(ngModel)]="tempo.data_fim"
                    (change)="calculaTempoBruto()"
                    name="data_fim"
                    placeholder="Data Fim"
                    required
                    aria-invalid="false"
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <div class="controls">
                  <label for="naturalizado">Averbação</label>
                  <ng-select
                    [items]="averbado"
                    name="averbado"
                    bindLabel="name"
                    placeholder="Averbado"
                    bindValue="id"
                    id="averbado"
                    [(ngModel)]="tempo.averbado"
                  ></ng-select>
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="form-group">
                <div class="controls">
                  <label for="tempo_bruto">Tempo Bruto (dias)</label>
                  <input
                    type="text"
                    id="tempo_bruto"
                    class="form-control"
                    [(ngModel)]="tempo.tempo_bruto"
                    name="tempo_bruto"
                    placeholder="Tempo Bruto (dias)"
                    required
                    aria-invalid="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isTempoCerto()" class="form-row">
            <p style="color: red">
              <b>A data de início deve ser anterior à data de fim.</b>
            </p>
          </div>
          <div class="form-row">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-primary mb-md-0 float-left"
                (click)="cancelTempo(modal)"               
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary mb-md-0 float-right"
                (click)="atualizarOuCadastrar(modal)"
                [disabled]="
                  !tempo.cnpj_cpf_empregador ||
                  !tempo.nome_empregrador ||
                  !tempo.data_entrada ||
                  !tempo.data_fim ||
                  !isTempoCerto()
                "
              >
                {{ tempo.id ? "Atualizar" : "Cadastrar" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
