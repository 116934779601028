<div class="row text-left mt-3">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300">Movimentos Funcionais</h1>
      <button  type="button" class="btn btn-outline-primary"   (click)="openModalXl(cadastroMovimento, null, null, null)" >Novo Movimento</button>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <form >
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="data_ingresso_servico">Data de Ingresso no Serviço Público</label>
                  <input type="text" id="data_ingresso_servico" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_ingresso_servico_publico" name="data_ingresso_servico" placeholder="Data de Ingresso no Serviço Público"
                         required aria-invalid="false">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="data_ingresso_carteira">Data de Ingresso Primeiro Emprego Carteira Assinada</label>
                  <input type="text" id="data_ingresso_carteira" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_ingresso_carteira" name="data_ingresso_carteira" placeholder="Data de Ingresso Primeiro Emprego Carteira Assinada"
                         required aria-invalid="false">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="emprego_carteira">Primeiro Emprego Carteira Assinada</label>
                  <input type="text" id="emprego_carteira" class="form-control" [(ngModel)]="servidor.emprego_carteira" name="emprego_carteira" placeholder="Primeiro Emprego Carteira Assinada"
                         required aria-invalid="false">
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <div class="controls">
                  <label for="data_ingresso_ente">Data de Ingresso no Ente (RPPS)</label>
                  <input type="text" id="data_ingresso_ente" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_ingresso_ente" name="data_ingresso_ente" placeholder="Data de Ingresso no Ente (RPPS)"
                         required aria-invalid="false">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col" >
              <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                      (click)="atualizarServidor()"
                      >Atualizar</button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
<div class="row mb-2"  *ngIf="movimentosFuncionais.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Matrícula</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let mov of movimentosFuncionais | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{mov.matricula}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroMovimento, mov, null, null)">Visualizar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="movimentosFuncionais.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="12"
                          [collectionSize]="movimentosFuncionais.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="movimentosFuncionais.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pl-0 pr-0">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum movimento cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row text-left mt-3">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h2 class="text-bold-300">Movimentação Mês a Mês</h2>
      <div class="btn-group d-none d-md-block" role="group">
        <button  type="button" class="btn btn-outline-primary"   (click)="openModalXl(cadastroSalario, null, null, null)" >Nova movimentação</button>
        <button  type="button" class="btn btn-outline-primary"   (click)="importar()" >Importar</button>
        <button  type="button" class="btn btn-outline-primary"   (click)="exportar()" >Exportar</button>
        <button  type="button" class="btn btn-outline-primary"   (click)="download('modelo_movimentacao.xlsx', 'modelo_movimentacao.xlsx')" >Baixar Modelo</button>
      </div>
    </div>
  </div>
</div>
<div class="row mb-2"  *ngIf="movimentosMensais.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Salário</th>
            <th>Data</th>
            <th>Observações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let mov of movimentosMensais | slice: (pageS-1) * pageSizeS : (pageS-1) * pageSizeS + pageSizeS">
            <td>{{mov.valor | currency:'BRL':true}}</td>
            <td>{{getBeautifulDate(mov.data)}}</td>
            <td>{{mov.observacoes}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroSalario, null, null, mov)">Editar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="movimentosMensais.length>0"
                          [(page)]="pageS"
                          [pageSize]="pageSizeS"  [maxSize]="5"
                          [collectionSize]="movimentosMensais.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="movimentosMensais.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pl-0 pr-0">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhuma movimentação cadastrada.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row text-left mt-3">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h2 class="text-bold-300">Cargos Anteriores</h2>
      <button  type="button" class="btn btn-outline-primary"   (click)="openModalXl(cadastroCargo, null, null, null)" >Novo Cargo</button>
    </div>
  </div>
</div>
<div class="row mb-2"  *ngIf="cargosAnteriores.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Cargo</th>
            <th>Salário</th>
            <th>Inicio</th>
            <th>Fim</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let mov of cargosAnteriores | slice: (pageC-1) * pageSizeC : (pageC-1) * pageSizeC + pageSizeC">
            <td>{{mov.cargo}}</td>
            <td>{{mov.salario | currency:'BRL':true}}</td>
            <td>{{getBeautifulDate(mov.inicio)}}</td>
            <td>{{getBeautifulDate(mov.fim)}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroCargo, null, mov, null)">Editar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="cargosAnteriores.length>0"
                          [(page)]="pageC"
                          [pageSize]="pageSizeC"  [maxSize]="12"
                          [collectionSize]="cargosAnteriores.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="cargosAnteriores.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pl-0 pr-0">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum cargo cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="form-row">
  <div class="col" >
    <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidorv2" [queryParams]="{id: idServidor}" >Voltar</button>
  </div>
</div>

<ng-template #cadastroSalario let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Movimento Mensal</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="salario">Salário</label>
                      <input type="text" id="salario" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker="," [dropSpecialCharacters]="false"  [(ngModel)]="salario.valor" name="salario" placeholder="Salário"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="datasalario">Data (MM/AAAA)</label>
                      <input type="text" id="datasalario" class="form-control" mask="00/0000" [(ngModel)]="salario.data" name="datasalario" placeholder="Data Salário (MM/AAAA)"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="observacoes">Observações</label>
                        <textarea   id="observacoes" class="form-control" [(ngModel)]="salario.observacoes" name="observacoes" placeholder="Observações"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col" >
                  <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrarSalario(modal)"
                          [disabled]="disableSalario()">{{salario.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #cadastroCargo let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Cargo Anterior</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cargo">Cargo</label>
                      <input type="text" id="cargo" class="form-control" [(ngModel)]="cargo.cargo" name="cargo" placeholder="Cargo"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="salario">Salário</label>
                      <input type="text" id="salario" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker="," [dropSpecialCharacters]="false"  [(ngModel)]="cargo.salario" name="salario" placeholder="Salário"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="datainicio">Data Inicio</label>
                      <input type="text" id="datainicio" class="form-control" mask="00/00/0000" [(ngModel)]="cargo.inicio" name="datainicio" placeholder="Data Início"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="datafim">Data Fim</label>
                      <input type="text" id="datafim" class="form-control" mask="00/00/0000" [(ngModel)]="cargo.fim" name="datafim" placeholder="Data Fim"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col" >
                  <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrarCargo(modal)"
                          [disabled]="disableCargo()">{{salario.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #cadastroMovimento let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Movimento Funcional</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <h4 class="text-center">Dados do Vínculo</h4>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tipo_vinculo">Tipo Vínculo</label>
                      <input type="text" id="tipo_vinculo" class="form-control" disabled="true" [(ngModel)]="movimento.tipo_vinculo" name="tipo_vinculo" placeholder="Tipo Vínculo"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="matricula">Matrícula <b style="color: red;">*</b></label>
                      <input type="text" id="matricula" class="form-control" [(ngModel)]="movimento.matricula" name="matricula" placeholder="Matrícula"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="decreto_nomeacao">Decreto Nomeação (Nº/Ano)  <b style="color: red;">*</b></label>
                      <input type="text" id="decreto_nomeacao" class="form-control" [(ngModel)]="movimento.decreto_nomeacao" mask="0*/0000" name="decreto_nomeacao" placeholder="Decreto Nomeação (Nº/Ano)"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_nomeacao">Data Nomeação  <b style="color: red;">*</b></label>
                      <input type="text" id="data_nomeacao" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.data_nomeacao" name="data_nomeacao" placeholder="Data Nomeação"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_exercicio">Data Exercício</label>
                      <input type="text" id="data_exercicio" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.data_exercicio" name="data_exercicio" placeholder="Data Exercício"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div> -->
              </div>
             <!--  <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="classificacao_concurso">Classificação Concurso</label>
                      <input type="text" id="classificacao_concurso" class="form-control" [(ngModel)]="movimento.classificacao_concurso" name="classificacao_concurso" placeholder="Classificação Concurso"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="edital_concurso">Edital Concurso</label>
                      <input type="text" id="edital_concurso" class="form-control" [(ngModel)]="movimento.edital_concurso" name="edital_concurso" placeholder="Edital Concurso"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_posse">Data Posse</label>
                      <input type="text" id="data_posse" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.data_posse" name="data_posse" placeholder="Data Posse"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="ente_origem">Ente de Origem</label>
                      <input type="text" id="ente_origem" class="form-control" disabled="true" [(ngModel)]="movimento.ente_origem" name="ente_origem" placeholder="Ente de Origem"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="acumulacoes">Acumulação de Cargo</label>
                      <ng-select [appendTo]="'body'" [items]="acumulacoes"   name="acumulacoes" bindLabel="name" bindValue="id"  placeholder="Acumulação de Cargo" id="acumulacoes" [(ngModel)]="movimento.cargo_acumulacao"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="movimento.cargo_acumulacao!=null && movimento.cargo_acumulacao!='1'">
                  <div class="form-group">
                    <div class="controls">
                      <label for="compatibilidade_horario">Compatibilidade de Horário</label>
                      <ng-select [appendTo]="'body'" [items]="naturalizados"   name="compatibilidade_horario" bindLabel="name" bindValue="id"  placeholder="Compatibilidade de Horário" id="compatibilidade_horario" [(ngModel)]="movimento.compatibilidade_horario"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="detalhes_acumulacao">Detalhes Acumulação</label>
                      <input type="text" id="detalhes_acumulacao" class="form-control" [(ngModel)]="movimento.detalhes_acumulacao" name="detalhes_acumulacao" placeholder="Detalhes Acumulação"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="regime_previdenciario">Regime Previdenciário</label>
                      <ng-select [appendTo]="'body'" [items]="regimes"   name="regime_previdenciario"  placeholder="Acumulação de Cargo" id="regime_previdenciario" [(ngModel)]="movimento.regime_previdenciario"></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="text-center">Dados da Movimentação</h4>
              <div class="form-row">
                <!-- <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="base_legal">Base Legal (Nº/Ano)</label>
                      <input type="text" id="base_legal" class="form-control" [(ngModel)]="movimento.base_legal" mask="0*/0000" name="base_legal" placeholder="Base Legal (Nº/Ano)"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_movimentacao">Data Movimentação</label>
                      <input type="text" id="data_movimentacao" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.data_movimentacao" name="data_movimentacao" placeholder="Data Movimentação"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div> -->
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="situacao_funcional">Situação Funcional  <b style="color: red;">*</b></label>
                      <ng-select [appendTo]="'body'" [items]="situacoes"   name="situacao_funcional" bindLabel="name" bindValue="id"  placeholder="Situação Funcional" id="situacao_funcional" [(ngModel)]="movimento.situacao_funcional"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="orgao">Orgão</label>
                      <ng-select [appendTo]="'body'" [items]="orgaos" (change)="onChangeOrgao($event)"   name="orgao" bindLabel="descricao" bindValue="id"  placeholder="Orgão" id="orgao" [(ngModel)]="movimento.orgao"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="poder_orgao">Poder Orgão</label>
                      <input type="text" id="poder_orgao" class="form-control" disabled="true" [(ngModel)]="movimento.poder_orgao" name="poder_orgao" placeholder="Poder Orgão"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="carreira">Carreira  <b style="color: red;">*</b></label>
                      <ng-select [appendTo]="'body'" [items]="carreiras"   name="carreira" bindLabel="name" bindValue="id"  placeholder="Carreira" id="carreira" [(ngModel)]="movimento.carreira"></ng-select>

                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="ingresso_carreira">Ingresso na Carreira  <b style="color: red;">*</b></label>
                      <input type="text" id="ingresso_carreira" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.ingresso_carreira" name="ingresso_carreira" placeholder="Ingresso na Carreira"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cargo">Cargo Atual <b style="color: red;">*</b></label>
                      <input type="text" id="cargo" class="form-control" [(ngModel)]="movimento.cargo"  name="cargo" placeholder="Cargo Atual"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="ingresso_cargo">Ingresso no Cargo Atual <b style="color: red;">*</b></label>
                      <input type="text" id="ingresso_cargo" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.ingresso_cargo" name="ingresso_cargo" placeholder="Ingresso no Cargo Atual" required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="carga_horaria">Carga Horária  <b style="color: red;">*</b></label>
                      <ng-select [appendTo]="'body'" [items]="cargashora"   name="carga_horaria"  placeholder="Carga Horária" id="carga_horaria" [(ngModel)]="movimento.carga_horaria"></ng-select>

                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="local_trabalho">Local de Trabalho  <b style="color: red;">*</b></label>
                      <input type="text" id="local_trabalho" class="form-control"  [(ngModel)]="movimento.local_trabalho" name="local_trabalho" placeholder="Local de Trabalho"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
               <!--  <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="salario_base">Salário Base</label>
                      <input type="text" id="salario_base" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker="," (change)="onChangeSalario()" [dropSpecialCharacters]="false"  [(ngModel)]="movimento.salario_base" name="salario_base" placeholder="Salário Base"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nivel_classe">Nível + Classe</label>
                      <input type="text" id="nivel_classe" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker="," (change)="onChangeSalario()"  [dropSpecialCharacters]="false"  [(ngModel)]="movimento.nivel_classe" name="nivel_classe" placeholder="Nível + Classe"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div> -->
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="total">Salário Total Contribuição Previdência</label>
                      <input type="text" id="total" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker="," [(ngModel)]="movimento.total" [dropSpecialCharacters]="false"  name="total" placeholder="Total"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row"*ngIf="movimento.situacao_funcional!=null && movimento.situacao_funcional=='3'">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_saida">Data Saída</label>
                      <input type="text" id="data_saida" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.data_saida" name="data_saida" placeholder="Data Saída"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tipo_exoneracao">Tipo Exoneração</label>
                      <ng-select [appendTo]="'body'" [items]="exoneracoes"   name="tipo_exoneracao"  placeholder="Tipo Exoneração" id="tipo_exoneracao" [(ngModel)]="movimento.tipo_exoneracao"></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="abono_permanencia">Abono de Permanência  <b style="color: red;">*</b></label>
                      <ng-select [appendTo]="'body'" [items]="naturalizados"   name="abono_permanencia" bindLabel="name" bindValue="id"  placeholder="Abono de Permanência" id="abono_permanencia" [(ngModel)]="movimento.abono_permanencia"></ng-select>

                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="servidor && servidor.status==1">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tipo_fundo">Tipo de Fundo  <b style="color: red;">*</b></label>
                      <ng-select [appendTo]="'body'" [items]="tipoFundo"  name="tipo_fundo" bindLabel="name" placeholder="Tipo de Fundo" id="tipo_fundo" [(ngModel)]="movimento.tipo_fundo"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="movimento.abono_permanencia!=null && movimento.abono_permanencia=='1'">
                  <div class="form-group">
                    <div class="controls">
                      <label for="competencia_inicial">Competência Inicial</label>
                      <input type="text" id="competencia_inicial" class="form-control" mask="00/00/0000" [(ngModel)]="movimento.competencia_inicial" name="competencia_inicial" placeholder="Competência Inicial"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="observacoes">Observações</label>
                      <textarea   id="observacoes" class="form-control" [(ngModel)]="movimento.observacoes" name="observacoes" placeholder="Observações"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col" >
                  <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrar(modal)"
                          [disabled]="disableMovimento()">{{movimento.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>


<ngx-spinner></ngx-spinner>


