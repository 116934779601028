import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as moment from "moment";
import { saveAs } from "file-saver";
import Swal from 'sweetalert2';
import { ExcelService } from 'app/helpers/excel.service';

import * as chartsData from '../shared/data/chartSample';

/*
LISTA DE TAREFAS:
TODO - Formatar campos do form e implementar sistema de editar e salvar
TODO - Deixar a primeira matrícula padrão selecionada
TODO - Implementar a estrutura de gráfico e tabelas
TODO - implementar gráfico
TODO - implementar Métodos de adicionar salário ou importar atrelado a matrícula
TODO - implementar Métodos de adicionar cargo anterior atrelado a matrícula
TODO - Método no ngbTab anterior para carregar os dados do movimento selecionado
*/

@Component({
  selector: "app-dados-funcionais",
  templateUrl: "./dados-funcionais.component.html",
  styleUrls: ["./dados-funcionais.component.scss"],
})
export class DadosFuncionaisComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1;
  pageSize = 10;
  pageS = 1;
  pageSizeS = 5;
  pageC = 1;
  pageSizeC = 10;
  idServidor;
  movimentosFuncionais = [];
  cargosAnteriores = [];
  movimentosMensais = [];
  regimes = ["RPPS (Próprio Ente)", "RGPS", "RPPS (Outro Ente)"];
  cargashora = ["20 H", "25 H", "30 H", "40 H"];
  exoneracoes = [
    "Exoneração",
    "Demissão",
    "Rescisão",
    "Termo Final",
    "Devolução ao Órgão de Origem",
    "Falecimento",
    "Cassação",
    "Exoneração - A pedido",
    "Exoneração - Abandono de cargo",
    "Exoneração - Não Tomou Posse",
    "Exoneração - Não Entrou em Exercício",
    "Demissão por Justa Causa",
    "Termino de mandato",
    "Aposentadoria",
  ];

  naturalizados = [
    {
      id: 1,
      name: "Sim",
    },
    {
      id: 2,
      name: "Não",
    },
  ];
  acumulacoes = [
    {
      id: 1,
      name: "Não",
    },
    {
      id: 2,
      name: "No Mesmo Município",
    },
    {
      id: 3,
      name: "Em Outro Município",
    },
    {
      id: 4,
      name: "Na Esfera Estadual",
    },
    {
      id: 5,
      name: "Na Esfera Federal",
    },
  ];
  situacoes = [
    {
      id: 1,
      name: "Em Exercício",
    },
    {
      id: 2,
      name: "Em Auxilio (a)",
    },
    // {
    //   id: 3,
    //   name: "Aposentado (a)",
    // },
    // {
    //   id: 4,
    //   name: "Pensionista",
    // },
    // {
    //   id: 5,
    //   name: "Recluso (a)",
    // },
    {
      id: 6,
      name: "Outros",
    },
  ];

  carreirasDefault = [
    {
      id: 1,
      name: "Aposentadoria",
    },
    {
      id: 2,
      name: "Pensão",
    },
    {
      id: 3,
      name: "Professor",
    },
    {
      id: 4,
      name: "Auxílio Doença",
    },
    {
      id: 5,
      name: "Outros",
    },
  ];
  carreiras = [
    {
      id: 1,
      name: "Aposentadoria",
    },
    {
      id: 2,
      name: "Pensão",
    },
    {
      id: 3,
      name: "Professor",
    },
    {
      id: 4,
      name: "Auxílio Doença",
    },
    {
      id: 5,
      name: "Outros",
    },
  ];
  carreirasAtivo = [
    {
      id: 6,
      name: "Professor",
    },
    {
      id: 7,
      name: "Especial (Agentes Nocivos)",
    },
    {
      id: 8,
      name: "Outros Cargos",
    },
    {
      id: 21,
      name: "Militares",
    }
  ];
  carreirasAposentado = [
    { name: 'Aposentadoria por Idade', id: 9},
    { name: 'Aposentadoria por Tempo de Contribuição', id: 10},
    { name: 'Aposentadoria Compulsória', id: 11},
    { name: 'Aposentadoria por Invalidez', id: 12},
    { name: 'Aposentadoria como Professor', id: 13},
    { name: 'Aposentadoria Especial - atividade de risco (Art. 40, § 4o, inc. II, CF)', id: 14},
    { name: 'Aposentadoria Especial - atividade prejudiciais à saúde ou integridade física (Art. 40, § 4o, inc. III, CF)', id: 15},
    { name: 'Militares Inativos - Reserva Remunerada', id: 16},
    { name: 'Militares Inativos - Reforma', id: 17}
  ];
  carreirasPensionista = [
    {
      id: 18,
      name: "Vitalícia",
    },
    {
      id: 19,
      name: "Temporária",
    }
  ];
  carreirasExonerado = [
    {
      id: 20,
      name: "Exonerado",
    }
  ];
  tipoFundo = [
    "Fundo em Capitalização",
    "Fundo em Repartição",
    "Mantidos pelo Tesouro"
  ];
  servidor = null
  estados = [];
  orgaos = [];
  cidades = [];
  cidade = null;

  cargo = {
    id: null,
    id_servidor: null,
    cargo: null,
    salario: null,
    inicio: null,
    fim: null,
  };
  salario = {
    id: null,
    id_servidor: null,
    valor: null,
    data: null,
    observacoes: null,
  };
  movimento = {
    id: null,
    id_servidor: null,
    tipo_vinculo: "Concursado",
    matricula: null,
    decreto_nomeacao: null,
    data_nomeacao: null,
    data_exercicio: null,
    classificacao_concurso: null,
    edital_concurso: null,
    data_posse: null,
    ente_origem: null,
    cargo_acumulacao: null,
    compatibilidade_horario: null,
    detalhes_acumulacao: null,
    regime_previdenciario: null,
    resolucao_registro: null,
    lei_autorizativa: null,
    base_legal: null,
    data_movimentacao: null,
    situacao_funcional: null,
    orgao: null,
    poder_orgao: null,
    carreira: null,
    ingresso_carreira: null,
    cargo: null,
    ingresso_cargo: null,
    carga_horaria: null,
    data_saida: null,
    tipo_exoneracao: null,
    local_trabalho: null,
    salario_base: null,
    nivel_classe: null,
    total: null,
    abono_permanencia: null,
    competencia_inicial: null,
    observacoes: null,
    tipo_fundo: null
  };
  isEditingMatricula: boolean = false;
  clonedMatricula: any = null;

  public lineChartData = chartsData.lineChartData;
  public lineChartLabels = chartsData.lineChartLabels;
  public lineChartOptions = chartsData.lineChartOptions;
  public lineChartColors = chartsData.lineChartColors;
  public lineChartLegend = chartsData.lineChartLegend;
  public lineChartType = chartsData.lineChartType;

  constructor(
    private router: Router,
    private excelService:ExcelService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {}
  async ngOnInit() {
    const self = this;
    self.route.queryParams.subscribe((parametros) => {
      if (parametros["id"]) {
        self.idServidor = parametros["id"];
      }
    });
    self.estados = [];
    self.movimentosFuncionais = [];
    self.orgaos = [];
    self.cidades = [];
    self.resetaMovimento();
    const dadosUsuario = this.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;
    this.cidade = cidade
    const nomeCidade = json.user.nomeCidade;
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const estados = await self.apiCall
      .get("enderecos/estados")
      .pipe(take(1))
      .toPromise();
    self.estados = JSON.parse(JSON.stringify(estados));
    const orgaos = await self.apiCall
      .get("enderecos/estados/cidades/orgaos/todos/ativo?cidade=" + cidade)
      .pipe(take(1))
      .toPromise();
    if (orgaos) self.orgaos = JSON.parse(JSON.stringify(orgaos));
    const movimentos = await self.apiCall
      .get("servidor/movimentos/todos?id_servidor=" + self.idServidor)
      .pipe(take(1))
      .toPromise();
    if (movimentos)
      self.movimentosFuncionais = JSON.parse(JSON.stringify(movimentos));
    const cargos = await self.apiCall
      .get(
        "servidor/movimentos/cargosanteriores/recuperar?id_servidor=" +
          self.idServidor
      )
      .pipe(take(1))
      .toPromise();
    if (cargos) self.cargosAnteriores = JSON.parse(JSON.stringify(cargos));
    const movimentacoesmensais = await self.apiCall
      .get(
        "servidor/movimentos/movimentacoesmensais/recuperar?id_servidor=" +
          self.idServidor
      )
      .pipe(take(1))
      .toPromise();
    if (movimentacoesmensais)
      self.movimentosMensais = JSON.parse(JSON.stringify(movimentacoesmensais));

      

    const servidor = await self.apiCall.get(`servidor?id=${self.idServidor}&id_cidade=${cidade}`).pipe(take(1)).toPromise()
    self.servidor = JSON.parse(JSON.stringify(servidor));
    if(self.servidor.status && self.servidor.status==1){
      self.carreiras = [...self.carreirasAtivo]
    } else if(self.servidor.status && self.servidor.status==2){
      self.carreiras = [...self.carreirasAposentado]
    } else if(self.servidor.status && self.servidor.status==3){
      self.carreiras = [...self.carreirasPensionista]
    } else if(self.servidor.status && self.servidor.status==4){
      self.carreiras = [...self.carreirasExonerado]
    }

    console.log("Servidor", self.servidor)

    self.servidor.data_ingresso_servico_publico = moment.utc(self.servidor.data_ingresso_servico_publico).format("DDMMYYYY");;
    self.servidor.data_ingresso_carteira = moment.utc(self.servidor.data_ingresso_carteira).format("DDMMYYYY");
    self.servidor.data_ingresso_ente = moment.utc(self.servidor.data_ingresso_ente).format("DDMMYYYY");

    if(this.movimentosFuncionais.length > 0){
    this.movimento = { ...this.movimentosFuncionais[0] }; // Clona o objeto para evitar mutações diretas
    }
    await self.spinner.hide();
  }
  async download(chave, nome){
    const url = await this.apiCall.get('banco-dados/files/url').pipe(take(1)).toPromise()
    if(url) saveAs(url+chave, nome);
  }
  getBeautifulDate(date) {
    return moment(date).format("MM/YYYY");
  }
  disableMovimento() {
    if (
      !this.movimento.matricula ||
      !this.movimento.decreto_nomeacao ||
      !this.movimento.data_nomeacao ||
      // || !this.movimento.data_exercicio
      // || !this.movimento.data_posse
      // || !this.movimento.cargo_acumulacao
      // || !this.movimento.regime_previdenciario
      // || !this.movimento.base_legal
      // || !this.movimento.data_movimentacao
      !this.movimento.situacao_funcional ||
      !this.movimento.carreira ||
      !this.movimento.ingresso_carreira ||
      !this.movimento.cargo ||
      !this.movimento.ingresso_cargo ||
      !this.movimento.carga_horaria ||
      !this.movimento.local_trabalho ||
      !this.movimento.abono_permanencia
    ) {
      return true;
    } else {
      return false;
    }
  }
  onChangeOrgao($event) {
    this.movimento.poder_orgao = this.orgaos.find(
      (x) => x.id == this.movimento.orgao
    ).poder;
  }
  async exportar(){
    let listaFormatada = []
    for(let s of this.movimentosMensais){
      listaFormatada.push({
         'Salário' : s.valor,
         'Data' : moment(s.data).format('MM/YYYY'),
      })
    }

    this.excelService.exportAsExcelFile(JSON.parse(JSON.stringify(listaFormatada)), 'movimentos_mensais');
  }

  async importar(){
    const self = this
    const { value: file } = await Swal.fire({
      title: 'Selecione a planilha para importar',
      input: 'file',
      inputAttributes: {
        'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        'aria-label': 'Selecione a planilha'
      },
    })

    if(!file){
      await this.app.alert('Atenção!',  'Você deve informar uma planilha!', 'error')
      return
    }

    if(!(file as any).name.endsWith('xlsx')){
      await this.app.alert('Atenção!',  'Você deve informar planilhas no formato .xlsx!', 'error')
      return
    }
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file as any);
    fileReader.onload = async (e) => {
      const formData = new FormData();
      formData.append('file', file as any);
      try{
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });

        await this.apiCall.post('servidor/arquivo/servidores/salario?servidor='+self.idServidor, formData).pipe(take(1)).toPromise()
        self.spinner.hide()
        await this.app.alert(
          "Tudo certo!",
            "Movimento Mensal importado com sucesso!",
          "success"
        );
        self.ngOnInit()
      }catch(e){
        self.ngOnInit()
      }
    }

  }

  openModalXl(content, movimento, cargo, salario) {
    this.resetaMovimento();
    this.resetaCargo();
    this.resetaSalario();
    if (movimento) {
      this.movimento = movimento;
      this.movimento.cargo_acumulacao = +this.movimento.cargo_acumulacao;
      this.movimento.orgao = +this.movimento.orgao;
      this.movimento.situacao_funcional = +this.movimento.situacao_funcional;
      this.movimento.carreira = +this.movimento.carreira;
      if (this.movimento.salario_base && this.movimento.salario_base.toFixed(2))
        this.movimento.salario_base = this.movimento.salario_base
          .toFixed(2)
          .toString()
          .replace(".", ",");
      if (this.movimento.nivel_classe && this.movimento.nivel_classe.toFixed(2))
        this.movimento.nivel_classe = this.movimento.nivel_classe
          .toFixed(2)
          .toString()
          .replace(".", ",");
      if (this.movimento.total && this.movimento.total.toFixed(2))
        this.movimento.total = this.movimento.total
          .toFixed(2)
          .toString()
          .replace(".", ",");
    }
    if (cargo) {
      this.cargo = cargo;
      if (this.cargo.inicio)
        this.cargo.inicio = moment(this.cargo.inicio).format("DDMMYYYY");
      if (this.cargo.fim)
        this.cargo.fim = moment(this.cargo.fim).format("DDMMYYYY");
      if (this.cargo.salario && this.cargo.salario.toFixed(2))
        this.cargo.salario = this.cargo.salario
          .toFixed(2)
          .toString()
          .replace(".", ",");
    }
    if (salario) {
      this.salario = salario;
      if (this.salario.data)
        this.salario.data = moment(this.salario.data).format("MMYYYY");
      if (this.salario.valor && this.salario.valor.toFixed(2))
        this.salario.valor = this.salario.valor
          .toFixed(2)
          .toString()
          .replace(".", ",");
    }
    const modal = this.modalService.open(content, {
      centered: true,
      size: "xl",
    });
  }

  resetaCargo() {
    this.cargo = {
      id: null,
      id_servidor: null,
      cargo: null,
      salario: null,
      inicio: null,
      fim: null,
    };
  }
  resetaSalario() {
    this.salario = {
      id: null,
      id_servidor: null,
      valor: null,
      data: null,
      observacoes: null,
    };
  }
  disableSalario() {
    if (!this.salario.valor || !this.salario.data) {
      return true;
    } else {
      return false;
    }
  }
  disableCargo() {
    if (
      !this.cargo.cargo ||
      !this.cargo.inicio ||
      !this.cargo.salario ||
      !this.cargo.fim
    ) {
      return true;
    } else {
      return false;
    }
  }
  resetaMovimento() {
    this.movimento = {
      id: null,
      id_servidor: null,
      tipo_vinculo: "Concursado",
      matricula: null,
      decreto_nomeacao: null,
      data_nomeacao: null,
      data_exercicio: null,
      classificacao_concurso: null,
      edital_concurso: null,
      data_posse: null,
      ente_origem: null,
      cargo_acumulacao: null,
      compatibilidade_horario: null,
      detalhes_acumulacao: null,
      regime_previdenciario: null,
      resolucao_registro: null,
      lei_autorizativa: null,
      base_legal: null,
      data_movimentacao: null,
      situacao_funcional: null,
      orgao: null,
      poder_orgao: null,
      carreira: null,
      ingresso_carreira: null,
      cargo: null,
      ingresso_cargo: null,
      carga_horaria: null,
      data_saida: null,
      tipo_exoneracao: null,
      local_trabalho: null,
      salario_base: null,
      nivel_classe: null,
      total: null,
      abono_permanencia: null,
      competencia_inicial: null,
      observacoes: null,
      tipo_fundo: null
    };
    const dadosUsuario = this.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;
    const nomeCidade = json.user.nomeCidade;
    this.movimento.ente_origem = "Prefeitura Municipal de " + nomeCidade;
  }
  onChangeSalario() {
    let salario = this.movimento.salario_base;
    let extras = this.movimento.nivel_classe;
    this.movimento.total = 0;
    if (salario) {
      salario = +salario.toString().replace(".", "").replace(",", ".");
      this.movimento.total = this.movimento.total + salario;
    }
    if (extras) {
      extras = +extras.toString().replace(".", "").replace(",", ".");
      this.movimento.total = this.movimento.total + extras;
    }
    if (this.movimento.total)
      this.movimento.total = this.movimento.total
        .toFixed(2)
        .toString()
        .replace(".", ",");
  }

  async atualizarServidor() {
    const self = this;

    if (!self.idServidor) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const data_ingresso_servico_publico = this.preparaData(
      self.servidor.data_ingresso_servico_publico
    );
    const data_ingresso_carteira = this.preparaData(
      self.servidor.data_ingresso_carteira
    );
    const data_ingresso_ente = this.preparaData(
      self.servidor.data_ingresso_ente
    );
    await self.apiCall.get(`servidor/movimentos/servidor?id_servidor=${self.idServidor}&data_ingresso_servico_publico=${data_ingresso_servico_publico}&data_ingresso_carteira=${data_ingresso_carteira}&data_ingresso_ente=${data_ingresso_ente}&emprego_carteira=${self.servidor.emprego_carteira}`).pipe(take(1)).toPromise();
    await self.spinner.hide();
    self.ngOnInit();
  }
  async atualizarOuCadastrarSalario(modal) {
    const self = this;
    if (!self.salario || !self.idServidor) {
      return;
    }
    let salario = { ...self.salario };
    salario.data = moment(salario.data, "MMYYYY").add(1, 'day').format("YYYY-MM-DD");
    salario.valor = salario.valor.toString().replace(".", "").replace(",", ".");
    salario.observacoes = salario.observacoes ? salario.observacoes : ''
    if (!self.salario.id) {
      await self.apiCall
        .get(
          `servidor/movimentos/movimentacoesmensais/inserir?id_servidor=${self.idServidor}&valor=${salario.valor}&data=${salario.data}&observacoes=${salario.observacoes}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/movimentos/movimentacoesmensais/atualizar?id=${self.salario.id}&valor=${salario.valor}&data=${salario.data}&observacoes=${salario.observacoes}`
        )
        .pipe(take(1))
        .toPromise();
    }
    await this.app.alert(
      "Tudo certo!",
      self.salario.id
        ? "Movimento Mensal atualizado com sucesso!"
        : "Movimento Mensal cadastrado com sucesso!",
      "success"
    );
    modal.close("Close click");
    self.ngOnInit();
  }
  async atualizarOuCadastrarCargo(modal) {
    const self = this;
    if (!self.cargo || !self.idServidor) {
      return;
    }
    let cargo = { ...self.cargo };
    cargo.inicio = this.preparaData(cargo.inicio);
    cargo.fim = this.preparaData(cargo.fim);
    cargo.salario = cargo.salario.toString().replace(".", "").replace(",", ".");
    if (!self.cargo.id) {
      await self.apiCall
        .get(
          `servidor/movimentos/cargosanteriores/inserir?id_servidor=${self.idServidor}&valor=${this.cargo.cargo}&salario=${cargo.salario}&inicio=${cargo.inicio}&fim=${cargo.fim}&cargo=${cargo.cargo}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/movimentos/cargosanteriores/atualizar?id=${self.cargo.id}&valor=${this.cargo.cargo}&salario=${cargo.salario}&inicio=${cargo.inicio}&fim=${cargo.fim}&cargo=${cargo.cargo}`
        )
        .pipe(take(1))
        .toPromise();
    }
    await this.app.alert(
      "Tudo certo!",
      self.cargo.id
        ? "Cargo Anterior atualizado com sucesso!"
        : "Cargo Anterior cadastrado com sucesso!",
      "success"
    );
    modal.close("Close click");
    self.ngOnInit();
  }
  async atualizarOuCadastrar(modal) {
    const self = this;
    if (!self.movimento || !self.idServidor) {
      return;
    }
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    console.log("movimento", this.movimento)
    this.movimento.data_nomeacao = this.preparaData(
      this.movimento.data_nomeacao
    );
    this.movimento.data_exercicio = this.preparaData(
      this.movimento.data_exercicio
    );
    this.movimento.data_posse = this.preparaData(this.movimento.data_posse);
    this.movimento.data_movimentacao = this.preparaData(
      this.movimento.data_movimentacao
    );
    this.movimento.data_saida = this.preparaData(this.movimento.data_saida);
    this.movimento.competencia_inicial = this.preparaData(
      this.movimento.competencia_inicial
    );
    this.movimento.ingresso_carreira = this.preparaData(
      this.movimento.ingresso_carreira
    );
    this.movimento.ingresso_cargo = this.preparaData(
      this.movimento.ingresso_cargo
    );
    let salario = this.movimento.salario_base;
    let nivel_classe = this.movimento.nivel_classe;
    let total = this.movimento.total;
    if (salario)
      salario = salario.toString().replace(".", "").replace(",", ".");
    if (nivel_classe)
      nivel_classe = nivel_classe.toString().replace(".", "").replace(",", ".");
    if (total) total = total.toString().replace(".", "").replace(",", ".");
    if (self.movimento.id) {
      await self.apiCall
        .get(
          `servidor/movimentos/editar?id_movimento=${
            self.movimento.id
          }&tipo_vinculo=${this.movimento.tipo_vinculo}&matricula=${
            this.movimento.matricula
          }&decreto_nomeacao=${this.movimento.decreto_nomeacao}&data_nomeacao=${
            this.movimento.data_nomeacao
          }&data_exercicio=${
            this.movimento.data_exercicio
          }&classificacao_concurso=${
            this.movimento.classificacao_concurso
          }&edital_concurso=${this.movimento.edital_concurso}&data_posse=${
            this.movimento.data_posse
          }&ente_origem=${this.movimento.ente_origem}&cargo_acumulacao=${
            this.movimento.cargo_acumulacao
          }&detalhes_acumulacao=${
            this.movimento.detalhes_acumulacao
          }&compatibilidade_horario=${
            this.movimento.compatibilidade_horario
          }&regime_previdenciario=${
            this.movimento.regime_previdenciario
          }&resolucao_registro=${
            this.movimento.resolucao_registro
          }&lei_autorizativa=${this.movimento.lei_autorizativa}&base_legal=${
            this.movimento.base_legal
          }&data_movimentacao=${
            this.movimento.data_movimentacao
          }&situacao_funcional=${this.movimento.situacao_funcional}&orgao=${
            this.movimento.orgao
          }&poder_orgao=${this.movimento.poder_orgao}&carreira=${
            this.movimento.carreira
          }&ingresso_carreira=${this.movimento.ingresso_carreira}&cargo=${
            this.movimento.cargo
          }&ingresso_cargo=${this.movimento.ingresso_cargo}&carga_horaria=${
            this.movimento.carga_horaria
          }&data_saida=${this.movimento.data_saida}&tipo_exoneracao=${
            this.movimento.tipo_exoneracao
          }&local_trabalho=${
            this.movimento.local_trabalho
          }&salario_base=${salario}&nivel_classe=${nivel_classe}&total=${total}&periodo_rgps=${""}&abono_permanencia=${
            this.movimento.abono_permanencia
          }&competencia_inicial=${
            this.movimento.competencia_inicial
          }&inicio_rgps=${""}&fim_rgps=${""}&observacoes=${
            this.movimento.observacoes
          }&tipo_fundo=${this.movimento.tipo_fundo}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/movimentos/novo?id_servidor=${
            self.idServidor
          }&tipo_vinculo=${this.movimento.tipo_vinculo}&matricula=${
            this.movimento.matricula
          }&decreto_nomeacao=${this.movimento.decreto_nomeacao}&data_nomeacao=${
            this.movimento.data_nomeacao
          }&data_exercicio=${
            this.movimento.data_exercicio
          }&classificacao_concurso=${
            this.movimento.classificacao_concurso
          }&edital_concurso=${this.movimento.edital_concurso}&data_posse=${
            this.movimento.data_posse
          }&ente_origem=${this.movimento.ente_origem}&cargo_acumulacao=${
            this.movimento.cargo_acumulacao
          }&detalhes_acumulacao=${
            this.movimento.detalhes_acumulacao
          }&compatibilidade_horario=${
            this.movimento.compatibilidade_horario
          }&regime_previdenciario=${
            this.movimento.regime_previdenciario
          }&resolucao_registro=${
            this.movimento.resolucao_registro
          }&lei_autorizativa=${this.movimento.lei_autorizativa}&base_legal=${
            this.movimento.base_legal
          }&data_movimentacao=${
            this.movimento.data_movimentacao
          }&situacao_funcional=${this.movimento.situacao_funcional}&orgao=${
            this.movimento.orgao
          }&poder_orgao=${this.movimento.poder_orgao}&carreira=${
            this.movimento.carreira
          }&ingresso_carreira=${this.movimento.ingresso_carreira}&cargo=${
            this.movimento.cargo
          }&ingresso_cargo=${this.movimento.ingresso_cargo}&carga_horaria=${
            this.movimento.carga_horaria
          }&data_saida=${this.movimento.data_saida}&tipo_exoneracao=${
            this.movimento.tipo_exoneracao
          }&local_trabalho=${
            this.movimento.local_trabalho
          }&salario_base=${salario}&nivel_classe=${nivel_classe}&total=${total}&periodo_rgps=${""}&abono_permanencia=${
            this.movimento.abono_permanencia
          }&competencia_inicial=${
            this.movimento.competencia_inicial
          }&inicio_rgps=${""}&fim_rgps=${""}&observacoes=${
            this.movimento.observacoes
          }&tipo_fundo=${this.movimento.tipo_fundo}`
        )
        .pipe(take(1))
        .toPromise();
    }
    self.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      self.movimento.id
        ? "Movimentos atualizados com sucesso!"
        : "Movimentos cadastrados com sucesso!",
      "success"
    );
    modal.close("Close click");
    await self.ngOnInit();
  }

  preparaData(data) {
    if (data) {
      return moment(data, "DDMMYYYY").format("YYYY-MM-DD");
    } else {
      data = "";
    }
    return data;
  }

  onTabChange(event: any) {
    if(this.isEditingMatricula){
      this.app.alert('Atenção!', 'Você está editando uma matrícula, finalize a edição antes de mudar de aba!', 'error')
    }else{
      this.movimento = { ...this.movimentosFuncionais[event.nextId] }; // Clona o objeto para evitar mutações diretas
    }
  }

  toggleEditMatricula(revert = false) {
    if (revert == false) {
      this.clonedMatricula = JSON.parse(JSON.stringify(this.movimento));
    }

    if (revert == true) {
      this.movimento = JSON.parse(JSON.stringify(this.clonedMatricula));
    }
    this.isEditingMatricula = !this.isEditingMatricula;
  }

  chartHovered(event){
    console.log('hover')
    return
  }
  chartClicked(event){
    console.log('click')
    return
  }
}
