import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import Me from 'app/helpers/me';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-permissoes',
  templateUrl: './permissoes.component.html',
  styleUrls: ['./permissoes.component.scss']
})
export class PermissoesComponent implements OnInit {

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private http: HttpClient,
    private api: Api
  ) { }
  apiCall = this.api.new().silent();
  page = 1;
  pageSize = 10;
  nomeCliente;
  sortColumn: string = '';
  sortDirection: string = 'asc'; 
  cidadesFiltered = [];
  cidades = [];
  allSelected = false;
  options = {
    simulador: false,
    sapiem: false
  };

  async ngOnInit() {
    this.spinner.show();
    try {
      this.cidades = await this.apiCall.get("permissoes/getCidades").pipe(take(1)).toPromise();
      console.log("Dados recebidos da API:", this.cidades);
      this.cidadesFiltered = [...this.cidades]; 
    } catch (error) {
      console.error("Erro ao buscar dados da API:", error);
    } finally {
      this.spinner.hide(); 
    }
  }
  applyFilters() {
    this.cidadesFiltered = this.cidades.filter(cidade => {
      const nomeFiltrado = this.nomeCliente?.trim()
        ? cidade.municipio.toLowerCase().includes(this.nomeCliente.toLowerCase())
        : true; 
  
      const filtroSimulador = this.options.simulador ? cidade.simulador === 1 : true;
      const filtroSapiem = this.options.sapiem ? cidade.sapiem === 1 : true;
  
      return nomeFiltrado && filtroSimulador && filtroSapiem;
    });
  
    this.page = 1;
  }
  clearFilters() {
    this.options = {
      simulador: false,
      sapiem: false
    };
    this.cidadesFiltered = [...this.cidades]; 
    this.page = 1;
  }


  updateAllSelected() {
    this.allSelected = this.options.simulador && this.options.sapiem;
  }
  
  toggleAll(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked; // Obtém o estado correto do checkbox
  
    this.allSelected = isChecked;
    this.options.simulador = isChecked;
    this.options.sapiem = isChecked;
  }
  gotoDetails(obj: any) {
    const serializedData = JSON.stringify(obj);
    console.log("Dados serializados:", obj);
    this.router.navigate(['/page/permissoes-detalhes'], { queryParams: { data: serializedData } });
  }


  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
  
    this.cidadesFiltered.sort((a, b) => {
      let valueA, valueB;
  
      if (column === 'matricula') {
        valueA = a.status == 2 || a.status == 3 ? a.matricula_beneficiario : a.matricula;
        valueB = b.status == 2 || b.status == 3 ? b.matricula_beneficiario : b.matricula;
      } else if (column === 'status') {
        // valueA = this.classificaStatus(a.status);
        // valueB = this.classificaStatus(b.status);
      } else {
        valueA = a[column]?.toString().toLowerCase() || '';
        valueB = b[column]?.toString().toLowerCase() || '';
      }
  
      if (this.sortDirection === 'asc') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });
  }

}
